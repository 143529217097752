

import AppDatepicker from "@/libs/ui/lib/forms/select/AppDatepicker.vue";
import {Options, Vue} from "vue-class-component";
import {Prop, Emit} from "vue-property-decorator";
import {FilterDatePickerData} from "@/libs/features/.storybook/interfaces";
import {SelectItemEmit} from "@/libs/ui/.storybook/interfaces";

@Options({
  components: {AppDatepicker},
  emits: ['on-selected-values']
})
export default class FilterDate extends Vue {

  @Prop({required: true})
  filterData!: FilterDatePickerData;

  @Prop({default: []})
  classList: string[] = [];

  @Emit()
  onSelectedValues(val:SelectItemEmit) {}

  onChangeSelected(val:SelectItemEmit) {
    this.onSelectedValues(val);
  }
}
