<template>
  <div class="footer-social-icons flex flex-wrap lg:flex-row items-center justify-between pt-8 md:pt-12 relative">
    <div class="w-5/12 sm:w-auto flex flex-nowrap items-center space-x-2 md:space-y-0 md:space-x-4">
      <Logo
        v-for="menuItem in filterMenu('LOGO')"
        :key="menuItem.EntityID"
        :anchor-class="'logo'"
        :img-class="'footer-main-logo-image '"
        :menu-item="menuItem"
      />
    </div>
    <ul
      v-if="moduleInfo.length > 0"
      class="flex w-full sm:w-auto justify-center space-x-3 order-3 sm:order-2 py-5 lg:py-0"
    >
      <template
        v-for="socialIconItem in moduleInfo"
        :key="socialIconItem.EntityID"
      >
        <li
          v-if="socialIconItem.SefUrl || socialIconItem.CustomUrl"
        >
          <VirtualLink
            :href="socialIconItem.CustomUrl ? socialIconItem.CustomUrl : socialIconItem.SefUrl"
            class-list="social bg-htz-steel hover:bg-htz-red rounded-full flex w-10 h-10 justify-center items-center text-40"
            :target="socialIconItem.Target ? '_' + $filters.lowercase(socialIconItem.Target) : ''"
            :title="socialIconItem.CustomUrl ? socialIconItem.CustomUrl : socialIconItem.SefUrl"
            v-html="socialIconItem.Name"
          />
        </li>
      </template>
    </ul>
    <div
      class="flex flex-col md:flex-row w-7/12 sm:w-auto order-2 sm:order-3 w-auto relative md:static"
    >
      <span class="flex items-center justify-end pr-3 lg:pr-5">
        <i class="icon-X_footer-grey-ic"><span class="path1" /><span class="path2" /></i>
        <i class="icon icon-pin-ico text-htz-steel text-32" />
        <AppDropDown
          v-if="eVisitorList.length"
          :select-list="eVisitorList"
          :placeholder="$t('loc_dropdownlist_choose')"
          :selected-item="selectedEVisitor"
          :property="entityEVisitorCountry"
          :class-list="['footer-title-dropdown btn-small-down pt-0 pb-0 text-htz-darkgrey normal-case']"
          :select-all="true"

          @onChangeSelected="onEVisitorChange"
        />
      </span>
      <span class="flex justify-end">
        <SelectItemLang
          class="footer-lang-dropdown text-htz-steel lg:text-htz-darkgrey"
        />
      </span>
    </div>
  </div>
</template>

<script>
import SelectItemLang from "@/components/includes/selects/SelectItemLang.vue";
import Logo from "../Logo";
import { menuMixin } from "@/mixins";
import AppDropDown from "@/libs/ui/lib/forms/select/AppDropDown";

export default {
  components: {
    SelectItemLang,
    Logo,
    AppDropDown
  },
  mixins: [menuMixin],
  props: {
    moduleInfo: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      entityEVisitorCountry: 'eVisitorCountry',
    }
  },
  computed: {
    authState() {
      return this.$store.state.LoggedInUserModule
    },
    selectedEVisitor() {
      return this.authState.userCountry ? {
        value: +this.authState.userCountry,
        property: this.entityEVisitorCountry
      }: null
    },
    eVisitorList() {
      return (Object.values(this.$store.state?.entitiesStore?.[this.entityEVisitorCountry]?.entities ?? {})).map(o => ({
        value: o.EntityID,
        name: o.Title,
        property: this.entityEVisitorCountry
      })) ?? []
    },
  },
  mounted() {
    let footer = document.getElementsByClassName("footer-container")[0];
    let footerOffsetTop = window.pageYOffset + footer.getBoundingClientRect().top;
    let flag = true;
    if(window.scrollY + 300 >= footerOffsetTop){
      this.getEVisitorCountry();
      flag = false;
    }
    window.onscroll = (ev) => {
      if ((window.scrollY + 300 >= footerOffsetTop) && flag) {
        this.getEVisitorCountry();
        flag = false;
      }
    };
  },
  methods: {
    getEVisitorCountry() {
      this.$store.dispatch('initDropDownStore', {entityName: this.entityEVisitorCountry, fields: ['Title'], relatedFields: {Place: 'Place.Title'}, sort: [{Field: 'Title'}]})
    },
    onEVisitorChange(val) {
      this.eVisitorId = this.$store.state.LoggedInUserModule.userCountry = val?.value?.toString() ?? ''
    },
  }
};
</script>
<style lang="scss">

</style>
