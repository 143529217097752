import {Vue} from "vue-class-component";
import axiosInstance from "@/services/http-common";
import {differenceInMinutes} from "date-fns";


export class RxEntityMixin extends Vue {

  needLoadData(property: {PropertyID:number,PropertyName:string,RelatedTypeID: number,GroupName :string}, propertyName: string): boolean {

    return !!property &&
      (
        !!property.RelatedTypeID &&
        (
          !this.$store.state.entitiesStore[property.PropertyName] ||
          differenceInMinutes(new Date(), this.$store.state.entitiesStore[property.PropertyName].timeLoaded) > 5
        )
      )
      ||
      !this.$store.state.entitiesStore[property.PropertyName]?.propertyNames.includes(propertyName)

  }

  loadAll(
    property: {PropertyID:number,PropertyName:string,RelatedTypeID: number,GroupName :string,},
    propertyByEntity: string,
    propertyName: string
  ) {
    let tempStore = this.$store.state.entitiesStore[property.PropertyName]
    if (!tempStore) {
      this.$store.state.entitiesStore[property.PropertyName] = {loaded: false, propertyNames: [propertyName],  loading: true, entities: {},timeLoaded: new Date(), ids: []};
      tempStore = this.$store.state.entitiesStore[property.PropertyName]
    } else {
      this.$store.state.entitiesStore[property.PropertyName].propertyNames.push(propertyName)
    }

    axiosInstance.post<{EntityID: string, Title: string}[]>(
      '/api/entities/simple',
      {
        LanguageID: this.$store.state.PageData.LanguageId,
        EntityTypeID: property.RelatedTypeID,
        AddLinkedEntities: false,
        PropertyNames: "EntityID,"+ propertyByEntity
      }
    ).then(res => {
      /*res.data.forEach((tag: {EntityID: string, [key: string]: string}, i) => {
        addOneToTable('entities', tag, +tag.EntityID)
      });*/
      tempStore.loaded = true;
      tempStore.loading = false;
      tempStore.timeLoaded = new Date();
      res.data.forEach(o => {
        if(tempStore.ids.includes(o.EntityID)) {
          tempStore.entities[o.EntityID] = {
            ...tempStore.entities[o.EntityID],
            ...o,
          }
        } else {
          tempStore.ids.push(o.EntityID);
          tempStore.entities[o.EntityID] = {
            ...o
          }
        }
      })


/*        entities: {...res.data.reduce((o, n) => ({...o,[n.EntityID]: n}), {})},
        timeLoaded: new Date(),
        ids: [...res.data.map(o => o.EntityID)]*/


      //TODO on virtual router make timeout to set loaded false for refresh data
      this.$store.state.entitiesStoreUpdated++;
    })
  }
}
