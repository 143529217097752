<template>
  <Menu>
    <MenuButton ref="close" class="w-auto hamburger-menu-btn relative z-50">
      <div
        class="hamburger-btn lg:hidden"
        @click="toggleMobileNav"
      >
        <div class="hamburger" />
      </div>
    </MenuButton>

    <transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-300 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="nav-dropdown-container mobile-nav-dropdown h-full overflow-y-scroll container max-w-screen-2xl w-full absolute lg:z-20 top-0 right-0 left-0 mt-16 origin-center bg-white lg:border border-r-0 lg:border-gray-200 lg:divide-y lg:divide-gray-100 lg:shadow-lg outline-none"
      >
        <div class="nav-dropdown">
          <div class="nav-dropdown-container flex justify-center max-w-screen-lg mx-auto">
            <div class="w-full max-w-screen-md flex flex-wrap mb-24 md:mb-0">
              <ul
                v-if="gotMenuData.length > 0"
                class="nav-list-main more-mb"
              >
                <li
                  v-for="menuItem in filterMenu('HEADER_MENU')"
                  :key="menuItem.EntityID"
                >
                  <MenuContainer
                    v-if="menuItem.Children.length || menuItem.SefUrl || menuItem.CustomUrl"
                    :module-info="menuItem"
                  />
                </li>
              </ul>

              <div class="mobile-nav-icons lg:hidden shadow-lg px-4">
                <UserAccessibilityDropdown />
                <MenuIcon
                  v-for="menuItem in filterMenu('MENU_ICON')"
                  :key="menuItem.EntityID"
                  :anchor-class="''"
                  :menu-item="menuItem"
                  @click="closeMenu(); toggleMobileNav(); "
                />
                <!-- <i class="icon icon-cart-ico text-htz-steel hover:text-htz-red text-40" /> -->
                <UserAccountDropdown />
              </div>
            </div>
          </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import UserAccountDropdown from "@/components/includes/dropdowns/UserAccountDropdown";
import UserAccessibilityDropdown from "@/components/includes/dropdowns/UserAccessibilityDropdown";
import MenuContainer from "@/components/includes/MenuContainer";
import { menuMixin } from "@/mixins";
import MenuIcon from "../MenuIcon.vue";


export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    UserAccountDropdown,
    UserAccessibilityDropdown,
    MenuContainer,
    MenuIcon
},
  mixins: [menuMixin],
  data() {
    return {
      loggedIn: false,
    };
  },
  computed: {
    closeMenuState() {
      return this.$store.state.closeMenu
    }
  },
  mounted() {
      window.addEventListener("click", this.checkClick);
  },
  watch: {
    closeMenuState() {
      this.closeMenu();
      this.toggleMobileNav()
    }
  },
  methods: {
    closeMenu() {
      if(this.$refs.close) {
        this.$refs.close.$el.click()
      }
    },
    toggleMobileNav() {
        document.getElementsByClassName("hamburger-btn")[0].classList.toggle("open");
        document.body.classList.toggle("body-lock-scroll");
    },
     checkClick(e) {
        let mobileDropdown = document.getElementsByClassName('mobile-nav-dropdown')[0];
        let hamburgetBtn = document.getElementsByClassName('hamburger-menu-btn')[0];

        if(mobileDropdown && hamburgetBtn){
          if(!mobileDropdown.contains(e.target) && !hamburgetBtn.contains(e.target)){
            this.toggleMobileNav()
          }
        }

    },

  }
};
</script>

<style lang="scss" scoped>
button:focus{outline:none;}
</style>
