<template>
  <div
    v-if="loaded"
    :class="moduleInfo.Module.CssClass"
  >
    <div
      class="container max-w-screen-md lg:max-w-screen-xl mx-auto my-8 mg:my-12"
    >
      <h2 class="headline-3a text-htz-red pb-0 mb-6 md:mb-10 text-center lg:text-left">
        {{ tt(moduleInfo.Module.ModuleProperties.ModuleTitle) }}
      </h2>

      <!-- if dekstop -->
      <div
        v-if="display === 'desktop'"
        class="grid gap-x-5 gap-y-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        <div
          v-for="data of moduleData"
          :key="data.EntityID"
        >    
          <Icon
            v-if="data.Icon"
            :icon-name="data.Icon"
            :icon-class="'block mb-4 icon '+data.Icon+' mb-2 md:min-h-20 text-60 md:text-72' "
            :span-class="'text-60 md:text-72'"
          /> 
          <i
            v-else
            class="block sm:min-h-16 md:min-h-20 mb-2"
          />
          <h3 class="card-subtitle mb-2">
            {{ data[propertyTitle] }}
          </h3>
          <p
            class="icons-text p-sm"
            v-html="data[propertyText]"
          />

          <template v-if="data[propertyLink]">
            <a
              v-if="isDocument(data[propertyLink])"
              :href="data[propertyLink]"
              download
              class="btn-small pl-0 cursor-pointer docDwnl"
            >
              <!-- jezični izraz: {{ $t("loc_icons_learn_more") }}<br> -->
              {{ getLinkName(data) }}
            </a>
            
            <VirtualLink
              v-else
              :href="data[propertyLink]"
              class-list="btn-small pl-0 cursor-pointer virLink"
            >
              <!-- jezični izraz: {{ $t("loc_icons_learn_more") }}<br> -->
              {{ getLinkName(data) }}
            </VirtualLink>
          </template>
        </div>
      </div>

      <!-- if mobile -->
      <div v-else>
        <swiper
          ref="iconsSwiper"
          :loop="true"
          :centered-slides="true"
          :slides-per-view="1"
          :auto-height="true"
          :pagination="{ clickable: true }"
          class="swiper-container-icons"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="data in moduleData"
            :key="data.EntityID"
            class="text-center pb-10 px-8"
          >
            <Icon
              v-if="data.Icon"
              :icon-name="data.Icon"
              :icon-class="'icon '+data.Icon+' block mb-4 text-center text-60 md:text-72'"
              :span-class="'text-60 md:text-72'"
            /> 

            <h3 class="card-subtitle mb-3 text-center text-32">
              {{ data[propertyTitle] }}
            </h3>
            <p
              class="p-sm text-center"
              v-html="data[propertyText]"
            />

            <template v-if="data[propertyLink]">
              <a
                v-if="isDocument(data[propertyLink])"
                :href="data[propertyLink]"
                download
                class="btn-small pl-0 cursor-pointer text-center"
              >
                <!-- jezični izraz: {{ $t("loc_icons_learn_more") }}<br> -->
                {{ getLinkName(data) }}
              </a>
            
              <VirtualLink
                v-else
                :href="data[propertyLink]"
                class-list="btn-small pl-0 cursor-pointer text-center"
              >
                <!-- jezični izraz: {{ $t("loc_icons_learn_more") }}<br> -->
                {{ getLinkName(data) }}
              </VirtualLink>
            </template>
          </swiper-slide>


          <div class="flex flex-row items-center lg:space-x-4">
            <div class="swiper-pagination swiper-pagination-icons" />
          </div>
        </swiper>
      </div>

      <!-- <div class="border-b border-htz-lightgrey mt-6 md:mt-12" /> -->
    </div>
    <div class="module-border container max-w-screen-2xl mx-auto  mt-6 md:mt-12" />
  </div>
</template>

<script>
// import Swiper core and required components
import SwiperCore, { Pagination } from "swiper";
import Icon from "@/components/includes/Icon";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperListOptions } from "@/services/swiperOptions";

import {selfDataRequestMixin} from "@/mixins";

import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

// install Swiper components
SwiperCore.use([Pagination]);

export default {
  name: "IconsCTAV2",
  components: {
    Icon,
    Swiper,
    SwiperSlide,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      propertyTitle: (JSON.parse(this.moduleInfo.Module.ModuleProperties?.ModulePropertyTitle))?.PropertyName,
      propertyText: (JSON.parse(this.moduleInfo.Module.ModuleProperties?.ModulePropertyText))?.PropertyName,
      propertyLink: (JSON.parse(this.moduleInfo.Module.ModuleProperties?.ModulePropertyLink))?.PropertyName,
      swiper: {},
      swiperListOptions,
      display: "mobile",
      flag: false,
    };
  },
  mounted() {
    if (window.innerWidth > 640) {
        this.display = "desktop";
    }
    window.addEventListener("resize", this.windowWidth);
    this.onSwiper(this.swiper);
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    windowWidth() {
      if (window.innerWidth > 640) {
        this.display = "desktop";
      } else {
        this.display = "mobile";
        if(this.flag === false){
          this.flag = true;
        }
      }
    },
    isDocument(link){
      const regex = /(.*\.pdf$)|(.*\.txt$)|(.*\.doc$)|(.*\.docx$)|(.*\.ppt$)|(.*\.pptx$)|(.*\.xxl$)|(.*\.xlsx$)/g;
      return link.match(regex) ? true : false
    },
    getLinkName(data){
      if(this.hasPropertyValue("ModuleLinkName", this.moduleInfo.Module.ModuleProperties, data)){
        return this.getPropertyValue(this.$t,"ModuleLinkName", this.moduleInfo.Module.ModuleProperties, data)
      }
      else{
        return this.$t("loc_icons_learn_more");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icons-text{
    max-height: 245px;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
