<template>
  <div :class="moduleInfo.Module.CssClass">
    <div
      v-if="loaded"
      class="cards-container cards-square my-16 2xl:px-0"
    >
      <div
        v-if="
          hasPropertyValue(
            'ModuleTitle',
            moduleInfo.Module.ModuleProperties,
            moduleData
          ) ||
            hasPropertyValue(
              'ModuleIntroTitle',
              moduleInfo.Module.ModuleProperties,
              moduleData
            )
        "
        class="container max-w-screen-xl mx-auto"
      >
        <h2 class="card-headline mt-8 mb-4">
          <span
            v-if="
              hasPropertyValue(
                'ModuleIntroTitle',
                moduleInfo.Module.ModuleProperties,
                moduleData
              )
            "
            class="-mt-8"
          >{{
            getPropertyValue(  $t,
              "ModuleIntroTitle",
              moduleInfo.Module.ModuleProperties,
              moduleData
            )
          }}</span>
          {{
            getPropertyValue(  $t,
              "ModuleTitle",
              moduleInfo.Module.ModuleProperties,
              moduleData
            )
          }}
        </h2>
      </div>
      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleHasSlider === 'True' && moduleData.length > minNumbOfDataToBeSlider"
        class="swiper-card-wrapper 2xl:max-w-screen-2xl"
      >
        <div class="swiper-card-container container 2xl:px-0 relative">
          <swiper
            v-if="moduleData.length > 0"
            :breakpoints="swiperOptions.breakpoints"
            :loop="swiperOptions.loop"
            :loop-slides="swiperOptions.loopedSlides"
            :centered-slides="swiperOptions.centeredSlides"
            :space-between="swiperOptions.spaceBetween"
            :watch-slides-visibility="swiperOptions.watchSlidesVisibility"
            :navigation="swiperOptions.navigation"
            :initial-slide="swiperOptions.initialSlide"
            :pagination="{ clickable: true, dynamicBullets: true }"
            :auto-height="swiperOptions.autoHeight"
            :speed="800"
            :autoplay="{
              'delay': swiperDelay,
              'disableOnInteraction': false
            }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <template
              v-for="card in moduleData"
              :key="card.EntityID"
            >
              <!--               <template v-if="hasPropertyValue('EndDate', moduleProperties, card) && !checkIfDateIsValid(card.EndDate)" />
 -->
                <!-- :style="{marginRight: swiperOptions.spaceBetween + 'px'}" -->

              <swiper-slide
                class="card card-square inline-flex flex-col py-8"
              >
                <card-item
                  :entity-type="moduleInfo.Module.Binding.SourceName"
                  :entity-id="moduleInfo.Module.Binding.SourceEntityTypeID"
                  :module-data="card"
                  :module-properties="moduleInfo.Module.ModuleProperties"
                  module-type="cardSquare"
                />
              </swiper-slide>
            </template>

            <div class="swiper-button swiper-button-next square-arrow-next" />
          </swiper>
          <div class="swiper-button swiper-button-prev square-arrow-prev" />
        </div>
      </div>

      <!-- NO SWIPER -->
      <div
        v-else
        class="container max-w-screen-xl mx-auto"
      >
        <div
          class="card-grid-container grid gap-3 lg:gap-5 grid-cols-2 lg:grid-cols-4 mt-8"
          :class="{'card-flex-container': moduleData.length < 4}"
        >
          <template
            v-for="card in moduleData"
            :key="card.EntityID"
          >
            <!--             <template v-if="hasPropertyValue('EndDate', moduleProperties, card) && !checkIfDateIsValid(card.EndDate)" />
 -->
            <div
              class="card card-square inline-flex flex-col"
            >
              <card-item
                :entity-id="moduleInfo.Module.Binding.SourceEntityTypeID"
                :entity-type="moduleInfo.Module.Binding.SourceName"
                :module-data="card"
                :module-properties="moduleInfo.Module.ModuleProperties"
                module-type="cardSquare"
              />
            </div>
          </template>
        </div>
      </div>

      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleLinkName"
        class="container max-w-screen-xl mx-auto text-center md:text-right"
      >
        <VirtualLink
          :href="moduleInfo.Module.ModuleProperties.ModuleLinkUrl"
          class-list="btn-small inline-flex t mt-2"
        >
          {{ tt(moduleInfo.Module.ModuleProperties.ModuleLinkName )}}
        </VirtualLink>
      </div>

      <div class="module-border container max-w-screen-2xl mx-auto mt-16 mb-4" />
    </div>
  </div>
</template>

<script>
import CardItem from "@/components/includes/CardItem";
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Autoplay  } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperOptions } from "@/services/swiperOptions";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import {selfDataRequestMixin} from "@/mixins";
//import {dateFromString} from "@/services/date-utilis";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay ]);



export default {
  name: "Square",
  components: {
    CardItem,
    Swiper,
    SwiperSlide,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swiperOptions: {...swiperOptions},
      swiper: {},
      swiperDelay: this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed?.length > 0 ? this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed : this.$store.state.Params.find(o => o.Key === 'SliderSpeed')?.Value || 8000,
      showAdminTool: false,
      minNumbOfDataToBeSlider: 4,
      galleryType: 'cardListSquare'
    };
  },
  mounted() {
    this.windowWidth();
    window.addEventListener("resize", this.windowWidth);
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.fadeSlide(swiper);
    },
    onSlideChange(swiper) {
      this.fadeSlide(swiper);
    },
    windowWidth() {
      if (window.innerWidth >= 992) {
        this.minNumbOfDataToBeSlider = 4;
      } else if (window.innerWidth >= 768) {
        this.minNumbOfDataToBeSlider = 3;
      } else if (window.innerWidth >= 580) {
        this.minNumbOfDataToBeSlider = 2;
      } else { this.minNumbOfDataToBeSlider = 1; }
    },
    fadeSlide() {
      setTimeout(() => {
        const lastVisibleSlide = this.swiper.visibleSlides.slice(-1)[0];
        this.swiper.slides.map((slide) =>
          slide.classList.remove("faded-slide")
        );
        lastVisibleSlide.classList.add("faded-slide");
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.card-square{
  .card-img{
    max-height: 273px;
    min-height: 210px;
  }
}
.swiper-button-prev {
  opacity: 0;
}

.swiper-card-container:hover {
  .swiper-button-next,
  .swiper-button-prev {
    opacity: 1;
  }
}

.swiper-button-next.square-arrow-next,
.swiper-button-prev.square-arrow-prev {
  @apply text-htz-red hidden;
  opacity: 0;
  transition: all 0.3s;
  padding: 80px 40px;
  top: 140px;
  &:after {
    @apply text-5xl;
  }
  @screen md{
    @apply block;
    }
  @screen lg{
    top: 110px;
  }
  @screen 2xl {
    right: 11%;
  }
  &:hover {
    opacity: 1;

    @screen 2xl {
      right: 10.5%;
    }
  }
}
.cards-square{
  .swiper-button-prev.square-arrow-prev{
    @screen 2xl{
      left: -80px;
    }
    @screen 3xl{
      left: -100px;
    }
  }
  .swiper-pagination-bullets-dynamic{
    bottom: 0 !important;
    @screen md{
      @apply hidden;
    }
      .swiper-pagination-bullet{
          width: 11px;
          height: 11px;
          margin: 0 9px;
          border: solid 2px #9a9a9a;
          background-color: #fff;
          transform: scale(1);

      }
      .swiper-pagination-bullet-active-main{
          width: 11px;
          height: 11px;
          margin: 0 9px;
          border: solid 2px #9a9a9a;
          background-color: #9a9a9a;
      }
  }
}
</style>
