
import {Vue} from "vue-class-component";
import {Action} from "vuex-class";
import {Prop} from "vue-property-decorator";
import {RelatedInterface} from "@/interfaces";
import {getImagetoShare} from "@/components/utils/share-utils";

export default class Favorite extends Vue{
  @Prop({required: false})
  entity: any;
  @Prop({required: false, default: false})
  inline: boolean = false;
  @Prop({required: false, default: ''})
  entityType: string = '';
  isHover = false;
  showOverlay = false;

  @Action('addToFavorite') addToFavorite(payload: {favorite: RelatedInterface, entityType: string}):  void {}
  @Action('removeToFavorite') removeToFavorite(payload: {favorite: RelatedInterface, entityType: string}): void {}

  get isFavorite(): boolean {
    return this.$store.state.favorites.some(o => o.EntityID.toString() === this.entity?.EntityID?.toString());
  }
  get isEntityValid() {
    return this.entity && this.entity.EntityID && (this.entity.Title || this.entity.Seo?.PageTitle || this.entity.SEO?.PageTitle) && (this.entity.SEO || this.entity.SefUrl)
      && (this.entity.SefUrl || this.entity.ExternalLink || this.entity.Seo?.SefUrl || this.entity.SEO?.SefUrl);
  }

  prepareImageFromHeader() {
    if(typeof getImagetoShare() !== 'string') return;

    const path = (getImagetoShare() as string).toString().split('cmsmedia')

    if(path[0].includes('thumbnail.aspx?filename=/')) {
      path[0] = path[0].replace('thumbnail.aspx?filename=/','');
    }

    this.entity.Media = [{
          "MediaPath": path.length > 1 ? 'cmsmedia' + path[1] : path[0],
          "MediaDomain": path.length > 1 ? path[0] : '',
          "Layouts": [],
          "MediaLocation": "",
          "MediaDescription": "",
          "MediaAltText": "",
          "Author": {
            "EntityID": ''
          },
      }]
    if(!this.entity.SefUrl) {
      this.entity.SefUrl = window.location.pathname
    }
  }

  checkPhotoAddToFavorite(payload: {favorite: RelatedInterface, entityType: string}) {
   if(!this.entity.Media && this.inline) {
      this.prepareImageFromHeader();
    }
    this.addToFavorite({favorite: this.entity, entityType: this.entityType})
  }
  toggleMe() {
    if (this.entity) {
      if (this.isFavorite) {
        if(this.inline) {
          this.isHover= false;
          this.removeToFavorite({favorite: this.entity, entityType: this.entityType})
        }
        this.showOverlay = true;
      } else {
        //const entityToAdd = this.$store.state.entitiesStore['ModuleData']?.entities[this.entity.EntityID.toString()] ?? this.entity;
        this.checkPhotoAddToFavorite({favorite: this.entity, entityType: this.entityType})
      }
    }
  }
  removeMe() {
    if (this.entity) {
      if (this.showOverlay) {
        this.removeToFavorite({favorite: this.entity, entityType: this.entityType})
        this.showOverlay = false;
      }
    }
  }
  cancelMe() {
    this.showOverlay = false;
  }
  getImagetoShare(){
    return getImagetoShare()
  }
}
