<template>
  <div class="nav-dropdown maps-region">
    <div class="nav-dropdown-container flex flex-col md:flex-row max-w-screen-lg mx-auto md:space-x-16 lg:space-x-0">
      <div class="w-full max-w-screen-md flex flex-wrap md:mb-0">

        <div
          v-for="(menuItem, index) in moduleInfo.Children"
          :key="menuItem.EntityID"
          class="w-full md:w-1/2 pb-4 lg:pb-8 lg:pr-4 xl:w-fixed-285"
        >
          <!-- Zamjenjena klasa space-y-2 sa w-1/2 pb-8 (za više od 2 kolone da stavke idu u drugi red)-->
          <NavMenuItemOption :menu-item="menuItem" />
          <div
            v-if="index == (moduleInfo.Children.length - 1) && (moduleInfo.SefUrl || moduleInfo.CustomUrl)"
            class="pt-6"
          />
        </div>

        <MenuItem
          :as="VirtualLink" v-slot="{ active }"
          :close-menu-on-click="true"
          :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl"
          :target="moduleInfo.Target"
          class-list="btn-red-border"
        >
          <span :class="active ? 'text-htz-red' : 'text-htz-darkgrey'">
            {{ $t("loc_explore_more") }}
          </span>
        </MenuItem>
      </div>

      <RegionMap
        css-class="relative xl:max-w-420"
        map-position="Navigation"
      />
    </div>
  </div>
</template>

<script>
import NavMenuItemOption from "@/components/includes/dropdowns/NavMenuItemOption";
import {regionList} from "@/components/utils/regionList";
import VirtualLink from "@/components/includes/router/VirtualLink";
import RegionMap from "@/components/includes/region/RegionMap";
import {MenuItem} from "@headlessui/vue";

export default {
  components: {
    RegionMap,
    NavMenuItemOption,
    MenuItem
  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      VirtualLink,
      loggedIn: false,
      regionsPaths: regionList,
      showTooltip: false,
      hoveredRegion: '',
      mousePosition: {
        top: 0,
        left: 0
      }
    };
  },

};
</script>

<style lang="scss" scoped>
button:focus{outline:none;}
.btn-red-border{
  max-height: 60px;
  &:hover{
    color: #fff;
    span{
      color: #fff;
    }
  }
}


</style>

