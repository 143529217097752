import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDropDown = _resolveComponent("AppDropDown")

  return (_openBlock(), _createBlock("div", { class: _ctx.classList }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.filterDataToShow, (one) => {
      return (_openBlock(), _createBlock("div", {
        key: one,
        class: ["", [one?.type ? 'filter w-full' : '']]
      }, [
        one
          ? (_openBlock(), _createBlock(_component_AppDropDown, {
              key: 0,
              "select-list": one.data.map(o => ({name: o.Caption, value: o.EntityID, RelatedFields: o.RelatedFields})),
              property: one.property,
              "lookup-source": one.lookupSource,
              type: one.type,
              "relation-list": one.relationList,
              "select-all": _ctx.selectAll,
              "multiple-select": _ctx.multipleSelect,
              placeholder:  one.emptyValue,
              "class-list": _ctx.dropdownClassList,
              "show-title": one.showTitle,
              title: one.title,
              "selected-values": _ctx.selectedValues,
              "selected-item": one.selectedValue,
              onOnChangeSelected: _ctx.onChangeSelected
            }, null, 8, ["select-list", "property", "lookup-source", "type", "relation-list", "select-all", "multiple-select", "placeholder", "class-list", "show-title", "title", "selected-values", "selected-item", "onOnChangeSelected"]))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ], 2))
}