
import {Emit, Prop, Watch} from "vue-property-decorator";
import {FilterDataInputInput} from "@/libs/features/.storybook/interfaces";
import AppInput from "@/libs/ui/lib/forms/Input/AppInput.vue"
import {Options, Vue} from "vue-class-component";
import {SelectItemEmit} from "@/libs/ui/.storybook/interfaces";

@Options({
  components: {
    AppInput,
  },
  emits: ['on-selected-values']
})
export default class FilterInput extends Vue {

  val = ''
  @Prop({required: true})
  filterData!: FilterDataInputInput;

  @Emit()
  onSelectedValues(val:SelectItemEmit) {}

  @Watch('val')
  onValChanged(val:string) {
    this.onSelectedValues({
      property: this.filterData.property,
      value: val,
      type: this.filterData.type
    })
  }
/*  onInputChange(val: string) {
    console.log(val);
    this.onSelectedValues({
      property: this.filterData.property,
      value: val,
      type: this.filterData.type
    })
  }*/
}
