
import AppCheckBox from '@/libs/ui/lib/forms/checkbox/AppCheckBox.vue'
import {Options, Vue} from "vue-class-component";
import {Emit, Prop} from "vue-property-decorator";
import {FilterType, SelectItemEmit} from "@/libs/ui/.storybook/interfaces";
import {FilterDataDropDownInput} from "@/libs/features/.storybook/interfaces";

// Import Swiper Vue.js components
// @ts-ignore
import { Swiper, SwiperSlide } from "swiper/vue";
//import { swiperListOptions } from "@/services/swiperOptions";

@Options({
  components: { AppCheckBox, Swiper, SwiperSlide },
  emits: ['on-selected-values']
})
export default class FilterCheckBox extends Vue {

  @Prop({default: true, required: false})
  allOption: boolean = true;

  type: FilterType = 'checkboxlist';
  isActive = true;
  swiper: Swiper = {};
  //swiperListOptions= {...swiperListOptions};
  display = "desktop";
  flag = false;
  selectedIds: string[] = [];
  elementsToShowInNav = [];
  selectedAll = false
  selectedValues: SelectItemEmit[] = [];

  @Prop({default: []})
  filterData: FilterDataDropDownInput[] = [];

  @Emit()
  //eslint-disable-next-line no-unused-vars
  onSelectedValues(val:SelectItemEmit[]) {}


  entityIncluded(val: string) : boolean {
    return this.selectedIds.includes(val)
  }
  get isAllSelected(): boolean {
    return this.selectedIds.length === 0
  }
  emitAll(value: boolean, property: string, entityId: string) {
    if (value) {
      this.selectedIds = [];
      this.emitValues(property, entityId)
    }
  }
  emitSelected(value: boolean, property: string, entityId: string ) {
    if(value) {
      this.selectedIds.push(entityId)
    } else {
      this.selectedIds = this.selectedIds.filter(o => o !== entityId)
    }
    this.emitValues(property, entityId)
  }
  emitValues(property: string, entityId: string) {
    if (this.selectedValues.some(o => o.property === property)) {
      this.selectedValues = this.selectedValues.map(o =>
        ({...o, value: this.selectedIds.join(","), type: 'checkboxlist'}))
    } else {
      this.selectedValues.push({value: entityId, property, type: this.type})
    }
    this.selectedValues = this.selectedValues.filter(o => o.value)
    this.onSelectedValues(this.selectedValues)
  }
  mounted() {
    this.selectedIds = this.filterData[0]?.selectedValue?.value ? (this.filterData[0]?.selectedValue?.value ?? '').toString().split(',') : [];
    this.selectedValues.push({value: this.selectedIds.join(","), property: this.filterData[0]?.property, type: this.type})
    this.windowWidth();
    window.addEventListener("resize", this.windowWidth);
  }
  unmounted(){
    window.removeEventListener("resize", this.windowWidth);

  }
  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
  }
  windowWidth() {
    if (window.innerWidth >= 768) {
      this.display = "desktop";
    } else {
      this.display = "mobile";
      if(this.flag === false){
        this.flag = true;
      }
    }
  }
}
