

import {Prop} from "vue-property-decorator";
import {Vue} from "vue-class-component";

export default class AppDate extends Vue{
  @Prop({required: true})
  date!: string

  get dateToUse(): string | undefined {
    return this.date ? this.getDateFromString?.toLocaleDateString(this.$store.state.LanguageId) : undefined
  }
  get getDateFromString(): Date | undefined {
    try {
      return new Date(this.date)
    } catch (e) {
      return undefined
    }
  }
  get isValidDate() {
    return typeof this.dateToUse === 'string'
  }
}
