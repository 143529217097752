<template>
  <div
    v-if="moduleInfo.Children.length > 0"
    class="nav-dropdown"
  >
    <div class="nav-dropdown-container lg:flex max-w-screen-lg mx-auto mb-16 md:mb-0">
      <div class="w-full max-w-screen-md grid grid-cols-2 gap-x-6 gap-y-8 lg:gap-0 lg:flex flex-wrap ">
        <div
          v-for="menuItem in moduleInfo.Children.slice(0, -1)"
          :key="menuItem.EntityID"
          class="w-full lg:w-1/2 lg:pb-8"
        >
          <NavMenuItemOption :menu-item="menuItem" />
        </div>
        <div
          v-if="moduleInfo.SefUrl || moduleInfo.CustomUrl"
          class="w-full pt-2"
        >
          <MenuItem
            :as="VirtualLink"
            v-slot="{ active }"
            :close-menu-on-click="true"
            :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl"
            :target="moduleInfo.Target"
            class-list="btn-red-border"
          >
            <span :class="active ? 'text-htz-red' : 'text-htz-darkgrey'">
              {{ $t("loc_explore_more") }}
            </span>
          </MenuItem>
        </div>
      </div>
      <div
        v-for="menuItem in moduleInfo.Children.slice(-1)"
        :key="menuItem.EntityID"
        class="w-full lg:max-w-250 pt-4 lg:pt-0"
      >
        <NavMenuItemOption :menu-item="menuItem" />
      </div>
    </div>
  </div>
</template>

<script>
import NavMenuItemOption from "@/components/includes/dropdowns/NavMenuItemOption";
import {MenuItem} from "@headlessui/vue";
import VirtualLink from "@/components/includes/router/VirtualLink";

export default {
  components: {
    NavMenuItemOption,
    MenuItem
  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      VirtualLink,
      loggedIn: false,
    };
  },
};
</script>

<style lang="scss" scoped>
button:focus{outline:none;}
</style>
