import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")

  return (!_ctx.externalUrl && _ctx.aTarget !== '_blank')
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: ["internal-link", _ctx.classList],
        to: _ctx.url,
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.navigate()), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["class", "to"]))
    : (_openBlock(), _createBlock("a", {
        key: 1,
        class: ["external-link", _ctx.classList],
        target: _ctx.externalLink && !_ctx.externalUrl ? '_self' : '_blank',
        href: _ctx.url
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10, ["target", "href"]))
}