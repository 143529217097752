
<template
  v-if="menuItem && gotMenuGallery"
>
  <VirtualLink
    v-if="menuItem.SefUrl || menuItem.CustomUrl"
    :href="menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl"
    :class-list="anchorClass"
    :target="menuItem.Target ? '_' + $filters.lowercase(menuItem.Target) : ''"
  >
    <img
      v-if="gotMenuGallery.meta && gotMenuGallery.lg"
      :src="gotMenuGallery.lg.path"
      :class="imgClass"
      loading="lazy"
      decode="async"
      :alt="gotMenuGallery.meta.MediaAltText"
    >
  </VirtualLink>
  <img
    v-else-if="gotMenuGallery.meta && gotMenuGallery.lg"
    :src="gotMenuGallery.lg.path"
    :class="anchorClass + ' ' + imgClass"
    loading="lazy"
    decode="async"
    :alt="gotMenuGallery.meta.MediaAltText"
  >
</template>

<script>
import { menuMediaMixin } from "@/mixins";

export default {
  mixins: [menuMediaMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
    anchorClass: {
      type: String,
      default: () => (''),
    },
    imgClass: {
      type: String,
      default: () => (''),
    },
    mediaType: {
      type: String,
      default: () => ('default'),
    },
    hideLogo: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    gotMenuGallery(){
      const mediaGalleries = this.getMediaItemPath(this.menuItem, this.mediaType).MediaGalleryToShow.length > 0 ? this.getMediaItemPath(this.menuItem, this.mediaType).MediaGalleryToShow : [];
      //const mediaGalleries = this.getMediaItemPath(this.menuItem, this.mediaType).Media.length > 0 ? this.getMediaItemPath(this.menuItem, this.mediaType).Media : [];
      let mediaGallery = mediaGalleries[0];
      /*if(mediaGalleries.length > 0 && mediaGalleries[0].meta.AvailableForLanguageArr.length == 0){
        mediaGallery = mediaGalleries[0];
      } else {
        mediaGallery = mediaGalleries.find(obj => obj.meta.AvailableForLanguageArr.includes(this.$filters.uppercase(this.$store.state.PageData.LanguageId)));
      }*/
      return mediaGallery ? mediaGallery : {};
    }
  },
};
</script>
<style lang="scss" scoped>
.sticker-img{
  @apply transition-all duration-300 ease-in-out;
  min-width: 60px;
  max-height: 55px;
  @screen lg{
      max-height: 70px;
  }
  @screen xl{
      max-height: 82px;
  }
  .nav-fixed-container &{
    @screen lg{
        max-height: 60px;
    }
  }
}
</style>
