import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormaPersonaChild = _resolveComponent("FormaPersonaChild")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("a", {
      href: "javascript:void(0);",
      class: ["form-personalize-button btn-blue-border-up w-full md:w-auto uppercase opacity-100 transition-all duration-300 ease-in-out", {'hidden': _ctx.isActive === true }],
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleForm && _ctx.toggleForm(...args)))
    }, _toDisplayString(_ctx.$filters.getPropertyValue( _ctx.$t,'ButtonNameInHeader',_ctx.moduleProperties,_ctx.moduleData)), 3),
    _createVNode("div", {
      class: [{'active': _ctx.isActive === true }, "form-personalize-box bg-htz-blue px-8 py-10 absolute z-10 left-0 bottom-0 top-auto md:mx-4 w-full md:w-auto md:max-w-360"]
    }, [
      _createVNode("span", {
        class: "arrow-box",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleForm && _ctx.toggleForm(...args)))
      }),
      _createVNode(_component_FormaPersonaChild, {
        "module-info": _ctx.moduleInfo,
        "dropdown-class-list": _ctx.dropdownClassList
      }, null, 8, ["module-info", "dropdown-class-list"])
    ], 2)
  ]))
}