<template>
  <div
    ref="shareBtnContainer"
    class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-16 share-btn-container"
    :class="moduleClass"
    @click="showTooltip"
  >
    <div
      v-if="getSocialButtonsHtml"
      v-tooltip.bottom-start="{
        content: getSocialButtonsHtml,
        trigger: 'manual',
        show: isOpen,
        classes: 'tooltip-content tooltip-social',
      }"

      class="flex items-center cursor-pointer relative"
    >
      <i class="icon icon-share-ico text-htz-red text-32 pr-3" />
      <span class="uppercase text-htz-darkgrey font-livory-bold hover:text-htz-red text-13">
        {{ $t("loc_share") }}
      </span>
    </div>
  </div>
</template>
<script>

import "@/css/modules/_tooltip.scss";
import {getImagetoShare} from "@/components/utils/share-utils";

export default {
  name: "ShareBtn",
  components: {
  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
     moduleLocation: {
      type: Object,
      default: () => ({}),
    },
    moduleClass: {
      type: String,
      default: () => ('breadcrumbs text-htz-red'),
    },
  },
  data() {
    return {
      isOpen: false,
      display: "desktop",
      currentUrl: window.location.href,
      //SHARE FB in new tab: ${this.getSocialServices().includes("facebook") ? `<li><a class="social" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=` + encodeURIComponent(window.location.href) + `"><i class="icon icon-facebook"></i></a></li>` : ""}
    };
  },
  computed: {
    getSocialButtonsHtml() {
      //TODO: zamjeniti ikonice za whatsapp, viber, email, telegram ako se naprave
      return this.showTooltipContent() ?
        [
          `
          <ul class="ul-tooltip space-x-3 ` + this.moduleLocation + `">
              ${this.getSocialServices().includes("facebook") ? `<li><a class="social" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=` + encodeURIComponent(this.currentUrl) +`&display=popup"> <i class="icon icon-facebook absolute z-0"></i></a></li>` : ""}
              ${this.getSocialServices().includes("messenger") && this.display === 'mobile' ? `<li><a class="social" target="_blank" href="fb-messenger://share/?link=` + encodeURIComponent(this.currentUrl) + `"><img src="/dist/img/messenger.svg" class="max-w-6 h-auto"/></a></li>` : ""}
              ${this.getSocialServices().includes("whatsapp") ? `<li><a class="social" target="_blank" href="https://api.whatsapp.com/send?text=%20` + encodeURIComponent(this.currentUrl) +`"><i class="icon icon-whatsapp text-24"></i></a></li>` : ""}
              ${this.getSocialServices().includes("viber") ? `<li><a class="social" target="_blank" href="viber://forward?text=` + encodeURIComponent(this.currentUrl) + `"><img src="/dist/img/viber.svg" class="max-w-6 h-auto"/></a></li>` : ""}
              ${this.getSocialServices().includes("twitter") ? `<li><a class="social" target="_blank" href="https://twitter.com/intent/tweet?url=` + this.currentUrl + `"><i class="icon icon-X_widget-ic"></i></a></li>` : ""}
              ${this.getSocialServices().includes("linkedin") ? `<li><a class="social" target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=` + encodeURIComponent(this.currentUrl) + `?latest"><i class="icon icon-linked-in"></i></a></li>` : ""}
              ${this.getSocialServices().includes("email") ? `<li><a class="social" target="_blank" href="mailto:?subject=`+ document.title +`&body=` + encodeURIComponent(this.currentUrl) + `"><i class="icon icon-mail2 text-24"></i></a></li>` : ""}
              ${this.getSocialServices().includes("telegram") ? `<li><a class="social" target="_blank" href="https://telegram.me/share/url?url=` + encodeURIComponent(this.currentUrl) + `"><i class="icon icon-telegram text-24"></i></a></li>` : ""}
              ${this.getSocialServices().includes("pinterest") ? `<li><a class="social" target="_blank" href="https://pinterest.com/pin/create/button/?url=` + this.getImagetoShare() + `&media=&description=Share%20button"><i class="icon icon-pinterest"></i></a></li>` : ""}
          </ul>`
        ] : [];
    }
  },
  watch: {
    '$route' () {
      this.currentUrl = window.location.href;
    }
  },
  mounted() {
    this.windowWidth();
    window.addEventListener('click', this.handleCloseTooltip);
  },
  unmounted() {
    window.removeEventListener('click', this.handleCloseTooltip, 100);
  },
  methods: {
    getSocialServices(){
      let socialShareServices = this.$store.state.Params.find(o => o.Key === 'SocialShareServices')?.Value.replace(/\s/g, '');
      var socialShareServicesArray = socialShareServices?.split(',');
      return socialShareServicesArray;
    },
    getImagetoShare(){
      return getImagetoShare()
    },
    showTooltipContent(){
        if(this.moduleLocation === "loc-footer" && this.getSocialServices()){
          return true;
        }
        else if(this.moduleLocation === "loc-title" && this.moduleInfo.Module.ModuleProperties.ModuleHideShareButton !== 'True' && this.getSocialServices()){
            return true;
        }
        else return false;
    },
    showTooltip() {
      this.isOpen = true;
    },
    handleCloseTooltip(e){
      if(this.isOpen){
        let tooltip = document.querySelectorAll(".tooltip.tooltip-content.tooltip-social")[0];
        let tooltip2 = document.querySelectorAll(".tooltip.tooltip-content.tooltip-social")[1];
        let tooltipBtn = document.querySelectorAll(".share-btn-container")[0];
        let tooltipBtn2 = document.querySelectorAll(".share-btn-container")[1];

        if(tooltip){
          var isClickInside = tooltip.contains(e.target) || tooltip2?.contains(e.target);
          var isClickTooltipBtn = tooltipBtn.contains(e.target) || tooltipBtn2?.contains(e.target);

          if ((isClickInside || isClickTooltipBtn)) {
            this.isOpen = true;
          }
          else if (!isClickInside && !isClickTooltipBtn) {
            this.isOpen = false;
          }
        }
      }
    },    
    windowWidth() {
      if (window.innerWidth >= 768) {
        this.display = "desktop";
      } else {
        this.display = "mobile";
      }
    },
  },

};
</script>
