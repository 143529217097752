import {ActionContext} from "vuex";
import {AppState, ReviewData} from "@/interfaces";
import { checkReviewData} from "@/store/store.utils";
import {LoginStateEnum} from "@/interfaces/login/login-state-enum";
import axiosInstance from "@/services/http-common";
import {firebaseAuthProvider} from "@/services/firebase";

const COMMENT_NAME = 'Comments'

export const reviewMutations = {
  setReviewErrorMessage(state: AppState, message: string) {
    state.review.errorMessage = message
  },
  setReviewInfoMessage(state: AppState, message: string) {
    state.review.infoMessage = message
  },
  setReviewTitleValue(state: AppState, title: string) {
    state.review.title = title
  },
  setReviewDescriptionValue(state: AppState, description: string) {
    state.review.description = description
  },
  setReviewRatingValue(state: AppState, rating: number) {
    state.review.rating = rating
  },
  setReviewTempRatingValue(state: AppState, rating: number) {
    state.review.tempRating = rating
  },
  onReviewModalValue(state: AppState, val: boolean) {
    state.review.reviewModalIsOpen = val
  },
  onSubmitEnableValue(state: AppState, val: boolean) {
    state.review.submitEnabled = val
  },
  onCompleteValue(state: AppState, val: boolean) {
    state.review.complete = val
  },

}

export const reviewActions = {
  async sendReviewRequest({commit, dispatch, state}: ActionContext<AppState, AppState>, review: ReviewData) {
      commit('setReviewInfoMessage', 'loc_review_start')
      //const entityId = state.entityTypes.find(o => o.EntityName === COMMENT_NAME)!.EntityTypeID

      axiosInstance.post('/api/webuser/comment', {

        UserUid: state.LoggedInUserModule?.uid,
        Token: await firebaseAuthProvider.currentUser?.getIdToken(),
        TitleJezicnoNeovisan:review.TitleJezicnoNeovisan.value,
        Comment: review.Comment.value,
        Rating: review.Rating.value,
        CommentDate: new Date().toISOString(),
        ParentId: state.PageData.PageId
      })
/*      crudCreateNew(
        buildCrudEntity(entityId, COMMENT_NAME, review, 'TODO',state.PageData.PageId, false),
        state
      )*/
        .then(response => {
          dispatch('setReviewComplete').catch(console.error);
        }).catch(e => {
        commit('setReviewInfoMessage', '')
        commit('setReviewErrorMessage', 'loc_review_error_save')
      });
  },
  setReviewComplete({commit, dispatch, state}: ActionContext<AppState, AppState>) {
    commit('setReviewInfoMessage', '')
    commit('onCompleteValue', true)
  },
  reviewStartSend({commit, dispatch, state}: ActionContext<AppState, AppState>) {
    console.log({review: state.review});
    commit('setReviewErrorMessage', '')
    commit('setReviewInfoMessage', '')
    const data: ReviewData = {
      TitleJezicnoNeovisan: {value: state.review.title, language: 'svi'},
      Comment: {value: state.review.description, language: 'svi'},
      Rating: {value: (state.review.rating as number), language: 'svi'},
      CommentUser: {value: (state.LoggedInUserModule!.editUserId as string), language: 'svi'},
      CommentDate: {value: new Date().toISOString(), language: 'svi'}
    }
    if (checkReviewData(data)) {
      dispatch('sendReviewRequest', data).catch(console.error);
      commit('onSubmitEnableValue', false)
    } else {
      commit('setReviewInfoMessage', '')
      commit('setReviewErrorMessage', 'loc_review_not_valid_data')
    }
  },
  clearReviewInfoMessage({commit}: ActionContext<AppState, AppState>) {
    commit('setReviewInfoMessage', '')
  },
  clearReviewErrorMessage({commit}: ActionContext<AppState, AppState>) {
    commit('setReviewErrorMessage', '')
  },
  setReviewTitle({commit}: ActionContext<AppState, AppState>, title: string) {
    commit('setReviewTitleValue', title)
  },
  setReviewDescription({commit}: ActionContext<AppState, AppState>, description: string) {
    console.log({description});
    commit('setReviewDescriptionValue', description)
  },
  setReviewRating({commit}: ActionContext<AppState, AppState>, rating: number) {
    commit('setReviewRatingValue', rating)
  },
  setReviewTempRating({commit}: ActionContext<AppState, AppState>, rating: number) {
    commit('setReviewTempRatingValue', rating)
  },
  onReviewModalOpen({commit}: ActionContext<AppState, AppState>) {
    commit('onSubmitEnableValue', true)
    commit('onReviewModalValue', true)
  },
  onReviewModalClose({commit}: ActionContext<AppState, AppState>) {
    commit('setReviewTitleValue', '')
    commit('setReviewDescriptionValue', '')
    commit('setReviewErrorMessage', '')
    commit('setReviewInfoMessage', '')
    commit('setReviewRatingValue', 0)
    commit('onReviewModalValue', false)
    commit('onCompleteValue', false)
  },
}

export const reviewGetters = {
  reviewInfoMessage(state: AppState) {
    return state.review.infoMessage
  },
  reviewTitle(state: AppState) {
    return state.review.title
  },
  reviewDescription(state: AppState) {
    return state.review.description
  },
  reviewErrorMessage(state: AppState) {
    return state.review.errorMessage
  },
  reviewModalIsOpen(state: AppState) {
    return state.review.reviewModalIsOpen
  },
  reviewTempRating(state: AppState) {
    return state.review.tempRating
  },
  reviewRating(state: AppState) {
    return state.review.rating
  },
  reviewSubmitEnabled(state: AppState) {
    return state.review.submitEnabled
  },
  reviewCompleted(state: AppState) {
    return state.review.complete
  },
}

