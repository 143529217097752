
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {SeoInterface} from "@/interfaces";
import {appStoreNavigate} from "@/router/router.service";

@Options({
  emits: ['onClick']
})
export default class VirtualLink extends Vue {

  @Prop({required: false, default: undefined})
  href?: string;

  @Prop({required: false, default: undefined})
  seo?: SeoInterface;

  @Prop({required: false, default: ''})
  classList?: string;

  @Prop({required: false, default: false})
  closeMenuOnClick?: boolean;

  @Prop({required: false, default: false})
  externalLink?: boolean;

  @Prop({required: false, default: undefined})
  target?: string;

  onCloseMenu() {
    this.$store.state.closeMenu++
  }
  get externalUrl(): boolean {
    //documents extensions array
    const documentsExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv', 'zip', 'rar', '7z', 'tar', 'gz', 'tgz', 'bz2', 'xz', 'mp3', 'mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm', 'ogg', 'ogv', 'm4a', 'm4v', 'wav', 'wma', 'aac', 'flac', 'aiff', 'aif', 'm3u', 'm3u8', 'ts', 'm2ts', 'mts', 'swf', 'exe', 'apk', 'dmg', 'iso', 'img', 'vhd', 'vmdk', 'ova', 'ovf', 'vdi', 'vfd', 'vud', 'vsv', 'vtx', 'vbox', 'vbox-extpack', 'ova', 'ovf', 'vdi', 'vfd', 'vud', 'vsv', 'vtx', 'vbox', 'vbox-extpack', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'svg', 'svgz', 'ico', 'webp', 'eps', 'ai', 'ps', 'psd', 'psb', 'sketch', 'indd', 'idml', 'dwg', 'dxf', 'dwf', 'fbx', 'obj', 'stl', '3ds', '3dm', 'max', 'dae', 'blend', 'aep', 'aet', 'prproj', 'prtl', 'ppj', 'aepx', 'aetx', 'pproj', 'psq', 'aaf', 'xml', 'json', 'csv', 'tsv', 'yaml', 'yml', 'log', 'ini', 'cfg'];

    const regex = new RegExp(`((http:|https:)?(//)(?!${window.location.host})[\\w\\.\\/\\-=?#]+)`, 'g')
    const isExternalDomain = regex.test(this.url);
    const isDocument = documentsExtensions.includes(this.url.split('.').pop()?.toLowerCase() ?? '');
    return isExternalDomain || isDocument;
  }
  get url(): string {
    return this.seo?.SefUrl ?? this.href ?? ''
  }
  get aTarget():string {
    return this.target === 'BLANK' ? '_blank' : '_self'
  }
  navigate() {
    if (this.url) {
      appStoreNavigate(this.url)
    }
    if (this.closeMenuOnClick) {
      this.onCloseMenu();
      this.$emit('onClick')
    }
  }
  /* checkIfWww(){
    if(this.url?.startsWith('www')){
      return "https://";
    }
    return '';
  } */
}
