
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";


@Options({
  emits: ['input', 'on-change']
})
export default class AppCheckBox extends Vue {

  @Prop({required: false})
  checkedData: any | undefined

  @Prop({default: ''})
  label: string = ''

  @Prop({required: false})
  value: boolean | undefined

  @Prop({default: []})
  classList: string[] = [];

  updateValue (value: boolean) {
    this.$emit('on-change', value)
  }
}
