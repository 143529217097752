<template>
  <div :class="moduleInfo.Module.CssClass">
    <div
      v-if="loaded"
      class="timeline container max-w-screen-xl mx-auto my-8 lg:my-16"
    >
      <h2
        v-if="hasPropertyValue('ModuleTitle', moduleInfo.Module.ModuleProperties, moduleData)"
        class="headline-3a text-htz-red pb-4 text-left"
      >
        {{ getPropertyValue($t, "ModuleTitle", moduleInfo.Module.ModuleProperties, moduleData) }}
      </h2>

      <div class="max-w-screen-lg mx-auto">
        <!-- on desktop show numbers in a line -->
        <div
          v-if="display === 'desktop'"
          class="timeline-numbers-container flex justify-between items-center min-h-24 mb-4 lg:my-10"
        >
          <div
            v-for="(data, index) in moduleData"
            :key="data.EntityID"
            class="timeline-number"
            :class="{ active: isActive && index === 0}"
          >
            <span
              :data-numb="'tm-numb-' + index"
              @click="hasActiveTimeline"
            >
              {{ index + 1 }}
            </span>
          </div>
        </div>

        <!-- on mobile show numbers as a slider -->
        <swiper
          v-else-if="display === 'mobile'"
          ref="timelineSwiper"
          :loop="false"
          :centered-slides="false"
          :slides-per-view="3.2"
          :auto-resize="false"
          class="mb-6 mt-2"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="(data, index) in moduleData"
            :key="data.EntityID"
          >
            <div
              class="timeline-number"
              :class="{ active: isActive && index === 0}"
            >
              <span
                :data-numb="'tm-numb-' + index"
                @click="hasActiveTimeline"
              >
                {{ index + 1 }}
              </span>
            </div>
          </swiper-slide>
        </swiper>

        <div class="mb-5">
          <div
            v-for="(data, index) in moduleData"
            :id="'tm-numb-' + index"
            :key="data.EntityID"
            class="timeline-content w-full lg:w-7/12"
            :class="{ active: isActive && index === 0}"
          >
            <h4 class="headline-4 pb-5">
              {{ data.Title }}
            </h4>
            <p
              v-if="data.Text"
              class="p-base"
              v-html="data.Text"
            />
            <p
              v-else-if="data.ShortDescription"
              class="p-base"
              v-html="data.ShortDescription"
            />
          </div>
        </div>
      </div>
      <div class="border-b border-htz-lightgrey mt-6 md:mt-12" />
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperListOptions } from "@/services/swiperOptions";

import "swiper/swiper.scss";

import {selfDataRequestMixin} from "@/mixins";

export default {
  name: "Timeline",
   components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
        isActive: true,
        swiper: {},
        swiperListOptions: {...swiperListOptions},
        display: "desktop",
        flag: false,

    };
  },
  mounted() {
    this.windowWidth();
    window.addEventListener("resize", this.windowWidth);
  },
  methods: {
      hasActiveTimeline(e){
        this.isActive = false;
        let allNumbers = document.getElementsByClassName("timeline-number");
        let allContent = document.getElementsByClassName("timeline-content");
        let dataNumb = e.target.getAttribute('data-numb');

        [].forEach.call(allNumbers, function(el) {
            el.classList.remove("active");
        });
        e.target.parentNode.classList.add("active");

        [].forEach.call(allContent, function(el) {
            el.classList.remove("active");
        });
        console.log(dataNumb);
        document.getElementById(dataNumb).classList.add("active");


      },
      onSwiper(swiper) {
        this.swiper = swiper;
      },
      windowWidth() {
        console.log("window width")
      if (window.innerWidth > 640) {
        this.display = "desktop";
      } else {
        this.display = "mobile";
        if(this.flag === false){
          this.flag = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>
