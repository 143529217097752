import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "container max-w-screen-xl font-livory text-htz-red text-24 lg:text-32 py-8"
}
const _hoisted_2 = { class: "modules-container filter-module" }
const _hoisted_3 = {
  key: 0,
  class: "module-border container max-w-screen-2xl mx-auto mt-16 mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModuleLink = _resolveComponent("ModuleLink")
  const _component_Filter = _resolveComponent("Filter")
  const _component_LoadMore = _resolveComponent("LoadMore")
  const _component_MapInline = _resolveComponent("MapInline")

  return (_ctx.isBindingNotApplicable || _ctx.isModuleDataNotEmpty || _ctx.filterActive || _ctx.isReviewModule)
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        id: _ctx.moduleInfo.Module.EntityID,
        class: "scroll-margin-default"
      }, [
        (_ctx.$store.state.AdminMode)
          ? (_openBlock(), _createBlock(_component_ModuleLink, {
              key: 0,
              "module-properties": _ctx.moduleInfo.Module
            }, null, 8, ["module-properties"]))
          : _createCommentVNode("", true),
        (_ctx.moduleInfo.Module.ModuleFilter?.length > 0 && _ctx.isFilterEnabled(_ctx.moduleInfo))
          ? (_openBlock(), _createBlock(_component_Filter, {
              key: 1,
              class: "container max-w-screen-xl",
              "module-filter": _ctx.moduleInfo.Module.ModuleFilter,
              "module-info": _ctx.moduleInfo,
              "has-prop-position-on-map": _ctx.hasPositionOnMap,
              onOnFilterChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.makeFilteredRequest($event, _ctx.moduleInfo))),
              onOnShowMap: _ctx.onShowMap
            }, null, 8, ["module-filter", "module-info", "has-prop-position-on-map", "onOnShowMap"]))
          : _createCommentVNode("", true),
        (!_ctx.isModuleDataNotEmpty && _ctx.filterActive && !_ctx.formaPersona)
          ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("loc_filterdata_noresult")), 1))
          : _createCommentVNode("", true),
        _createVNode("div", {
          class: {
          'filter-modules-container': _ctx.moduleInfo.Module.ModuleFilter?.length > 0,
          'map-active': _ctx.showMap,
          'filter-modules-container-less-than-four': _ctx.moduleInfo.Module.ModuleFilter?.length < 4}
        }, [
          _createVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default"),
            _createVNode(_component_LoadMore, { "module-info": _ctx.moduleInfo }, null, 8, ["module-info"]),
            (_ctx.moduleInfo.Module.ModuleProperties.ModuleHasSeparator === 'True')
              ? (_openBlock(), _createBlock("div", _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          (_ctx.showMap && _ctx.moduleInfoTemp)
            ? (_openBlock(), _createBlock(_component_MapInline, {
                key: 0,
                "module-info": _ctx.moduleInfoTemp
              }, null, 8, ["module-info"]))
            : _createCommentVNode("", true)
        ], 2)
      ], 8, ["id"]))
    : _createCommentVNode("", true)
}