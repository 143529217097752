import { ModuleApiInterface } from "@/interfaces"
import { MenuItems } from "@headlessui/vue"

export interface Menu {
  IDObject: number;
  ParentID: number;
  Order: number;
  InternalLabel: string;
  Name: string;
  Page: any;
  MenuType?: string;
  ItemsToShow?: string;
  CustomUrl: any;
  HtmlObject: any;
  Items: any;
  HyperLink: any;
}
/*export interface MenuArray {
  IDObject?: number;
  EntityID?: number;
  ParentID?: number;
  Order?: number;
  InternalLabel?: string;
  Name?: string;
  Page?: any;
  MenuType?: string;
  ItemsToShow?: string;
  CustomUr?: any;
  HtmlObject?: any;
  Items?: any;
  HyperLink?: any;
  Children: MenuArray[];
  MultimediaGalleryApiRequest: ModuleApiInterface[];
  MediaGallery: any[];
  MediaGalleryToShow: any[];
  MediaPath: String;
  CheckMediaGalleryCounter: number;
  Target?: String;
}*/

export interface MenuArray {
  EntityID?: number;
  MenuType?: string;
  ItemsToShow?: string;
  Items: MenuItemsArray[];
  Children: MenuItemsArray[];
}

export interface MenuItemsArray {
  ItemsToShow?: string;
  EntityID?: number;
  Order?: number;
  Name?: string;
  CustomUrl?: string;
  ParentID?: number;
  MenuHtml?: string;
  MenuItemOption?: string;
  MenuItemType?: string;
  Target?: string;
  Page?: string;
  SefUrl?: string;
  Media?: any[];
  Children: MenuItemsArray[];
  MediaGalleryToShow?: any[];
}

export interface MediaGallery {
  MediaItems: MediaItems[];
}

export interface MediaItems {
  Author?: string;
  AuthorName?: string;
  AuthorSurname?: string;
  AvailableForLanguage?: string;
  Caption?: string;
  EntityID?: number;
  ExternalMediaPath?: string;
  Layouts?: [];
  MediaAltText?: string;
  MediaCategory?: string;
  MediaDescription?: string;
  MediaExtension?: string;
  MediaFileName?: string;
  MediaLocation?: string;
  MediaPath?: string;
  MediaSize?: string;
  MediaSource?: string;
  MediaType?: string;
  MediaTypeValue?: string;
  Order?: number;
  ParentID?: number;
  RelativeCenterX?: string;
  RelativeCenterY?: string;
  VideoPath?: string;
}

export interface NewMenuValue {
  IDObject: number;
  EntityID: number;
  ParentID: number;
  Order?: number;
  InternalLabel?: string;
  Name?: string;
  Page?: any;
  MenuType?: string;
  ItemsToShow?: string;
  CustomUrl?: any;
  HtmlObject?: any;
  Items?: any;
  HyperLink?: any;
  Children: NewMenu;
}
export interface NewMenu {
  [key: string]: NewMenuValue;
}

export interface MenuMediaData {
  data: MenuArray[];
  success: boolean;
}

export const sortInArray = (array: MenuItemsArray[], parent: MenuItemsArray | MenuArray) => {
  if (array.length > 0) {
    const tempArray: MenuItemsArray[] = []
    array.forEach(one => {
      if (one.ParentID === parent.EntityID) {
        parent.Children.push(one)
        //TODO sort for every item?
        parent.Children.sort((a, b) => (a.Order || 0) - (b.Order || 0))
      } else {
        tempArray.push(one)
      }
    })
    parent.Children.forEach(c => {
      sortInArray(tempArray,c)
    })
  }
}
export const sortObject = (listInt: NewMenu, parent: NewMenuValue) => {
  if (Object.keys(listInt).length > 0) {
    const tempList: NewMenu = {};
    Object.keys(listInt).forEach((m, i) => {
      const obj = listInt[m];
      if (obj.ParentID.toString() === parent.EntityID.toString()) {
        parent.Children[obj.EntityID.toString()] = {
          ...obj,
          Children: {},
        };
      } else {
        tempList[m] = obj;
      }
    });
    Object.keys(parent.Children).forEach((r) => {
      sortObject(tempList, parent.Children[r]);
    });
  }
}
