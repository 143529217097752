

import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {EntityType, PreparedImage, RelatedInterface} from "@/interfaces";
import JsonLd from "./json-ld.vue";
import {dateFromString} from "@/services/date-utilis";

@Options({
  components: {
    JsonLd
  }
})
export default class JsonLdManager extends Vue {

  @Prop({required: true})
  entityId!: number | string;

  @Prop({required: true})
  entity!: RelatedInterface;

  @Prop({required: false})
  schemeType!: string;

  @Prop({required: false})
  schemeData: Object = {}

  @Prop({required: false})
  pageInfo!: any;


  @Prop({required: false})
  thumbnail!: string;

  jsonToPass: {[key: string]: any} = {}

  organizationFlag: Object =  false

  get listEntities(): EntityType[] {
    return this.$store.state.entityTypes
  }
  mounted() {
    if(!this.entityId && this.schemeType){
      this.setJsonBySchemeType()
    }
    else if(this.entityId){
      this.setJsonByEntityType()
    }


  }

  setJsonByEntityType() {
  
    if (!this.listEntities.some(o => o.EntityTypeID === +this.entityId)) {
      return
    }

    switch (!this.schemeType && this.listEntities.find(o => o.EntityTypeID === +this.entityId)!.EntityName) {
      case 'Event': {
        //console.log(this.entity.Place[0]);
        if(this.entity){
          this.jsonToPass = {
            "@context": "https://schema.org",
            "@type": "Event",
            "eventStatus": "https://schema.org/EventScheduled",
            "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
            "name": this.entity.Title,
            "startDate": this.formatToISODate(this.entity.StartDate as string),
            "endDate": this.formatToISODate(this.entity.EndDate as string),
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": this.entity.Rating ? this.entity.Rating : 5,
              "bestRating": "5",
              "ratingCount": "100" //TODO koju vrijednost tu poslati?
            },
            "location": {
              "@type": "Place",
              "name": this.getPlace(this.entity?.Place as any),//this.entity?.Place[0]?.Title,
              "address": this.entity?.Address,
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": this.getCoords(this.entity.PositionOnMap as string).lat,
                "longitude": this.getCoords(this.entity.PositionOnMap as string).lng,
              },
              //TODO vezani entiteti?
              /*"name": "Snickerpark Stadium",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "100 West Snickerpark Dr",
                "addressLocality": this.entity.Place,
                "postalCode": "19019",
                "addressRegion": this.entity.Region,
                "addressCountry": "US"
              } */
            },
            "image": this.getImage(this.entity?.MediaGallery as any),
            //"image": [ this.entity.MediaGallery[0]?.lg.path],
            "description": this.entity.ShortDescription,
            "organizer": {
              "@type": "Organization",
              "name": this.entity.Caption,
              "url": this.entity.Link
            }
          };
        }
        break;
      }
      case 'Page': {
        /* console.log("PAGE");
        console.log(this.entity); */
        //TBD
        break;
      }
      case 'Comments': {
        /* console.log("COMMENTS");
        console.log(this.entity); */
            this.jsonToPass = {
              "@context": "https://schema.org/",
              "@type": "Review",
              "itemReviewed": {
                "@type": "Event",
                "name": this.pageInfo.Seo.PageTitle,
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": this.entity?.Rating ? this.entity.Rating : 5,
              },
              "name": this.entity?.TitleJezicnoNeovisan,
              "author": {
                "@type": "Person",
                "name": this.entity?.WebUsers[0]?.DisplayName
              },
              "reviewBody": this.entity?.Comment
            }

        //TBD
        break;
      }
      case 'Attraction': {
        /* console.log("Attraction");
        console.log(this.entity); */
        //TBD https://schema.org/TouristAttraction
        //TODO: hendle count attractions and populate list
        /*this.jsonToPass = {
            "@context": "https://schema.org",
            "@type": "ItemList",
            "url": "http://multivarki.ru?filters%5Bprice%5D%5BLTE%5D=39600",
            "numberOfItems": "315",
            "itemListElement": [
                {
                    "@context": "https://schema.org",
                    "@type": "TouristAttraction",
                    "name": "Hyde Park",
                    "description": "It's one of nine royal parks of London.",
                    "url": "http://www.royalparks.org.uk/parks/hyde-park",
                    "isAccessibleForFree": true
                },
                {
                    "@type": "Product",
                    "name": "..."
                }
            ]
        }*/
        break;
      }
      case 'Activity': {
       /*  console.log("Activity");
        console.log(this.entity); */
        //TBD
        break;
      }
      case 'News': {
       /*  console.log("News");
        console.log(this.entity); */
        //TBD https://schema.org/NewsArticle
        break;
      }
      case 'Region': {
       /*  console.log("Region");
        console.log(this.entity); */
        //TBD carousel, itemList?
        break;
      }
    }

  }
  setJsonBySchemeType() {
    if (this.schemeType === 'Organization' && this.organizationFlag === false) {
      this.organizationFlag = true;
      this.jsonToPass = this.schemeData;

    }
     if(this.schemeType === 'VideoObject'){
      //console.log(this.entity);
      let videoJson = this.getVideo(this.entity?.MediaGallery as any, this.thumbnail)
      if(videoJson !== undefined){
        //console.log(videoJson);
        this.jsonToPass = videoJson;
      }
    }
  }
  formatToISODate(date: string){
    if(date){
      try {
        return new Date(date).toISOString();
      } catch (e) {
        return new Date().toTimeString();
      }
    }


  }
  getImage(img: PreparedImage[]):{[key: string]: string} | undefined {
   // console.log({img});
    if(img[0]?.lg?.path){
      return {
        "@context": "https://schema.org/",
        "@type": "ImageObject",
        "url": img[0].lg.path,
        "contentUrl": img[0].lg.path,
        //"contentUrl": (process.env.NODE_ENV === 'development' ? 'http://htz-com.p4c-htz' : location.origin) + (img[0].lg.path.startsWith('/') ? '' : '/') + img[0].lg.path,
        "author": img[0].meta.Author?.AuthorName + ' ' + img[0].meta.Author?.AuthorSurname ?? '',
        "contentLocation": img[0].meta.MediaLocation ?? '',
        "description": img[0].meta.MediaDescription ?? '',
        "name": img[0].meta.MediaAltText ?? '',
        "license": "https://example.com/license", // TODO: link na terms and conditions page
      };
    } else {
      return undefined
    }
  }
   getVideo(video: PreparedImage[], thumb: string):{[key: string]: string} | undefined {
     let videoThumb: string = "",
         videoName: string = "",
         videoDesc: string = "",
         videoPath: string = "",
         videoDate: string = "";

     if(video[0]){

        if(thumb){ videoThumb = (process.env.NODE_ENV === 'development' ? 'http://htz-com.p4c-htz' : location.origin) + (thumb.startsWith('/') ? '' : '/') + thumb}
        videoName = video[0].meta.MediaAltText + '';
        videoDesc = video[0].meta.MediaDescription + '';
        videoDate = (new Date(new Date('01 Jan 2020 00:00:00 GMT').getTime() + Math.random() * (new Date().getTime() - new Date('01 Jan 2020 00:00:00 GMT').getTime()))).toISOString();

        if(video[0].meta.MediaSource === "internal"){

          videoPath = (process.env.NODE_ENV === 'development' ? 'http://htz-com.p4c-htz' : location.origin) + (video[0].meta.MediaPath.startsWith('/') ? '' : '/') + video[0].meta.MediaPath;

          return {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": videoName,
            "description": videoDesc,
            "thumbnailUrl": videoThumb,
            "contentUrl": videoPath,
            "uploadDate": videoDate
          }
        }
        else if(video[0].meta.MediaSource === "external"){
          let videoLink: string = "";
          let videoExtThumb: string = "";

          if(video[0].meta.MediaTypeValue === "youtube"){
            videoLink = "https://www.youtube.com/embed/" + video[0].meta.ExternalMediaPath;
            videoExtThumb = "https://img.youtube.com/vi/" + video[0].meta.ExternalMediaPath + "/0.jpg";
          }
          else if(video[0].meta.MediaTypeValue === "vimeo"){
            videoLink = "https://player.vimeo.com/video/" + video[0].meta.ExternalMediaPath;
            videoExtThumb = videoThumb;
          }
          return {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": videoName,
            "description": videoDesc,
            "thumbnailUrl": videoExtThumb,
            "contentUrl": videoLink,
            "uploadDate": videoDate
          }
        }
        else
          return undefined;
     }
     else{
       return undefined;
     }

  }
  getCoords(coordinates = ''){
    const position = coordinates.split("#");
    return {
      lat: position[0],
      lng: position[1]
    };
  }
  getPlace(place: { Title: any; }[]){
    if(place){
      return place[0]?.Title;
    }
    else return '';
  }
}
