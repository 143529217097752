<template>

  <Modal
    :modal-shown="modalShown"
    title="Merge account with {todo add provider}"
    @modalClosed="closeModal"
  ><!--loc_login_merge_accounts-->
    <template #content>
      <div class="errors">
        <div class="error">
        {{ mergeError }}
        </div>
      </div>

      Email:<!--loc_login_email-->
      <input
        v-model="email"
        type="text"
      >
      <br>

      Password:<!--loc_login_password-->
      <input
        v-model="password"
        type="password"
      >
      <br>
    </template>

    <template #actions>
      <div @click="confirmModal(LOGIN_TYPE.EMAIL)">
        Merge accounts<!--loc_login_title_merge_accounts-->
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/includes/modals/Modal";
import {mapState} from "vuex";
import {LoginStateEnum} from "@/interfaces/login/login-state-enum";

export default {
  name: "MergeWithMailForm",
  components: {
    Modal
  },
  emits: {
    'close-modal': null,
    'confirm-modal': null
  },
  data() {
    return {
      mergeError: '',
      email: null,
      password: null,
      LOGIN_TYPE: this.$constants.LOGIN_TYPE // Here defined so we can use it in view
    }
  },
  computed: {
    ...mapState('LoggedInUserModule', {
      modalShown: state => state.loginState === LoginStateEnum.LOGIN_CONFLICT,
    })
  },
  methods: {
    closeModal() {
      this.mergeError = undefined;
      this.$store.commit('LoggedInUserModule/quitConflictResolution');
    },
    confirmModal(loginType) {

      if (loginType === this.$constants.LOGIN_TYPE.EMAIL) {
        if (!this.email) {
          console.log('no email');
          // Write message that email is required
          return;
        }

        if (!this.password) {
          console.log('no password');
          // Write message that password is required
          return;
        }
      }
      this.$store.dispatch('LoggedInUserModule/finalizeConflictResolution', {
        loginType: loginType,
        email: this.email,
        password: this.password
      })
    },
  },
};
</script>

<style lang="scss" scoped>
  button {
    margin-right: 20px;
  }

  input {
      border: 1px solid #333333;
  }
</style>
