import axios from 'axios';
import https from 'https';

// Set config defaults when creating the instance
export const axiosInstance = axios.create({
    withCredentials: false,
/*    httpsAgent: new https.Agent({
        rejectUnauthorized: false,
    }),*/

    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    }
});


// **************************************************************
// FAKE TEST DATA, REMOVE WHEN API CORS IS RESOLVED / START
// export const axiosInstanceTest = axios.create({
//     httpsAgent: new https.Agent({
//         rejectUnauthorized: false
//     }),
//     withCredentials: false,
//     headers: {
//         'Accept': 'application/json; charset=utf-8',
//         'Content-Type': 'application/json; charset=utf-8'
//     }
// });
// FAKE TEST DATA, REMOVE WHEN API CORS IS RESOLVED / END
// **************************************************************

export default axiosInstance;
