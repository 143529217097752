<template>
  <div
    v-if="moduleInfo.Children.length > 0 || moduleInfo.SefUrl || moduleInfo.CustomUrl"
    class="inline-block text-left w-full lg:w-auto"
  >
    <ModuleLink
      v-if="$store.state.AdminMode"
      :module-properties="moduleInfo"
      :show-only-icon="true"
    />
    <Menu>
      <span class="rounded-md shadow-sm md:shadow-none block lg:inline w-full lg:w-auto">
        <MenuButton
          v-if="moduleInfo.Children.length > 0 "
          class="menu-button w-full lg:w-auto"
        >
          <a
            class="btn-small-down w-full lg:w-auto test-link-menubutton"
            :class="{'link-only' : moduleInfo.Children.length < 1}"
            @click="openMenu"
          >{{ moduleInfo.Name }}</a>
        </MenuButton>
        <VirtualLink
          v-else
          :close-menu-on-click="true"
          :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl"
          :class-list="(moduleInfo.Children.length < 1 ? 'link-only' : '') + ' btn-small-down w-full lg:w-auto test-link'"
        >{{ moduleInfo.Name }}</VirtualLink>
      </span>

      <transition
        v-if="moduleInfo.Children.length > 0"
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform lg:scale-95 opacity-0"
        enter-to-class="transform lg:scale-100 opacity-100"
        leave-active-class="transition duration-300 ease-out"
        leave-from-class="transform lg:scale-100 opacity-100"
        leave-to-class="transform lg:scale-95 opacity-0"
      >
        <MenuItems
          :unmount="false"
          class="nav-dropdown-container nav-dropdown-container-outter xl:container max-w-screen-2xl relative lg:absolute z-20 right-0 left-0 lg:mt-6 xl:mt-10 origin-center bg-white lg:border lg:border-gray-200 lg:divide-y lg:divide-gray-100 lg:shadow-lg outline-none"
        >
          <MenuItem v-if="display !== 'mobile'" ref="close" v-slot="{ active }">
            <a
              :class="
                active ? 'text-htz-red' : 'text-htz-darkgrey'
              "
              class="hidden lg:block text-60 top-0 right-0 absolute z-10 mr-5 -mt-5 cursor-pointer transition-all duration-300"
            >
              ×
            </a>
          </MenuItem>

          <NavDropdownWithImagesAbove
            v-if="moduleInfo.MenuItemType === 'WITH_IMAGES_ABOVE'"
            :module-info="moduleInfo"
          />
          <NavDropdownWithMap
            v-else-if="moduleInfo.MenuItemType === 'WITH_MAP'"
            :module-info="moduleInfo"
          />
          <NavDropdownNormal
            v-else-if="moduleInfo.Children.length"
            :module-info="moduleInfo"
          />


        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import NavDropdownWithMap from "@/components/includes/dropdowns/NavDropdownWithMap";
import NavDropdownWithImagesAbove from "@/components/includes/dropdowns/NavDropdownWithImagesAbove";
import NavDropdownNormal from "@/components/includes/dropdowns/NavDropdownNormal";
import {lazyLoad} from "@/components/utils/lazyLoadImages";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    NavDropdownWithMap,
    NavDropdownWithImagesAbove,
    NavDropdownNormal

  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    openMenu() {
      setTimeout(() => {
        lazyLoad();
      }, 1500)

    },
    closeMenu() {
      if(this.$refs.close) {
        this.$refs.close.$el.click()
      }
    },
    windowWidth() {

      if (window.innerWidth >= 768) {

        this.display = "desktop";

      } else {

        this.display = "mobile";

      }

    },
  },
  data() {
    return {
      loggedIn: false,
      display: 'desktop'
    };
  },
  mounted() {
    this.windowWidth()
  },
  computed: {
    closeMenuState() {
      return this.$store.state.closeMenu
    }
  },
  watch: {
    closeMenuState() {
      this.closeMenu()
    }
  }
};
</script>

<style lang="scss" scoped>
button:focus{outline:none;}
</style>
