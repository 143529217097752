<template>
  <div :class="moduleInfo.Module.CssClass">
    <div
      v-if="loaded"
      class="cards-container container max-w-screen-xl mx-auto text-center cards-logo my-16 px-12 2xl:px-4"
    >
      <div class="module-border mt-16" />
      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleHasSlider === 'True' && moduleData.length > minNumbOfDataToBeSlider"
        class="swiper-card-wrapper justify-center mr-auto ml-auto"
      >
        <div class="swiper-card-container container 2xl:px-0 relative">
          <swiper
            v-if="moduleData.length > 0"
            :slides-per-view="ModuleNumOfItemsToShow"
            :navigation="swiperOptions.navigation"
            :initial-slide="swiperOptions.initialSlide"
            :auto-height="swiperOptions.autoHeight"
            :space-between="swiperOptions.spaceBetween"
            :loop="swiperOptions.loop"
            :speed="800"
            :autoplay="{
              'delay': swiperDelay,
              'disableOnInteraction': false
            }"
            @swiper="onSwiper"
          >
            <template
              v-for="card in moduleData"
              :key="card.EntityID"
            >
              <!--               <template v-if="hasPropertyValue('EndDate', moduleProperties, card) && !checkIfDateIsValid(card.EndDate)" />
-->
              <!-- :style="{marginRight: swiperOptions.spaceBetween + 'px'}" -->

              <swiper-slide
                class="card-logo py-8"
              >
                <vue-picture
                  v-if="card.MediaGallery.length > 0"
                  :image="card.MediaGallery[0]"
                  :hide-tooltip="false"
                  css-class="card-img object-cover"
                />
                <img
                  v-else-if="card.MediaGallery.length === 0"
                  class="transition-all duration-300 h-32 w-full object-cover"
                  :src="defaultCardImg"
                  alt="Default card image"
                  @error="imageLoadError"
                >
              </swiper-slide>
            </template>
          </swiper>
          <div class="swiper-button swiper-button-next logos-arrow-next" />
          <div class="swiper-button swiper-button-prev logos-arrow-prev" />
        </div>
      </div>

      <!-- NO SWIPER -->  
      <div
        v-else
        class="container max-w-screen-xl mx-auto"
      >
        <div
          class="card-grid-container grid gap-3 lg:gap-5 grid-cols-2 lg:grid-cols-4 my-8"
          :class="{'card-flex-container': moduleData.length <= minNumbOfDataToBeSlider}"
        >
          <template
            v-for="card in moduleData"
            :key="card.EntityID"
          >
            <!--             <template v-if="hasPropertyValue('EndDate', moduleProperties, card) && !checkIfDateIsValid(card.EndDate)" />
    -->
            <div
              class="card-logo inline-flex flex-col"
            >
              <vue-picture
                v-if="card.MediaGallery.length > 0"
                :image="card.MediaGallery[0]"
                :hide-tooltip="false"
                css-class="card-img object-cover"
              />
              <img
                v-else-if="card.MediaGallery.length === 0"
                class="transition-all duration-300 h-32 w-full object-cover"
                :src="defaultCardImg"
                alt="Default card image"
                @error="imageLoadError"
              >
            </div>
          </template>
        </div>
      </div>
      <div class="module-border container max-w-screen-2xl mx-auto mb-4" />
    </div>
  </div>
</template>

<script>
import VuePicture from "@/components/includes/VuePicture";
// import Swiper core and required components
import SwiperCore, { Navigation, Autoplay  } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperLogosOptions } from "@/services/swiperOptions";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import {selfDataRequestMixin} from "@/mixins";
//import {dateFromString} from "@/services/date-utilis";

// install Swiper components
SwiperCore.use([Navigation, Autoplay ]);



export default {
  name: "Logos",
  components: {
    VuePicture,
    Swiper,
    SwiperSlide,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swiperOptions: {...swiperLogosOptions},
      swiper: {},
      swiperDelay: this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed?.length > 0 ? this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed : this.$store.state.Params.find(o => o.Key === 'SliderSpeed')?.Value || 8000,
      showAdminTool: false,
      ModuleNumOfItemsToShow: this.moduleInfo.Module.ModuleProperties.ModuleNumOfItemsToShow?.length > 0 ? this.moduleInfo.Module.ModuleProperties.ModuleNumOfItemsToShow : 5,
      minNumbOfDataToBeSlider: this.moduleInfo.Module.ModuleProperties.minNumbOfDataToBeSlider?.length > 0 ? this.moduleInfo.Module.ModuleProperties.minNumbOfDataToBeSlider : 5,
      galleryType: 'cardListLogo',
      defaultCardImg: ""
    };
  },
  mounted() {
    this.windowWidth();
    window.addEventListener("resize", this.windowWidth);
  },
  methods: {
    imageLoadError(){
      this.defaultCardImg =  '/dist/img/default-cardSquare.png';
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    windowWidth() {
      if (window.innerWidth >= 992) {
        this.ModuleNumOfItemsToShow = this.moduleInfo.Module.ModuleProperties.ModuleNumOfItemsToShow?.length > 0 ? this.moduleInfo.Module.ModuleProperties.ModuleNumOfItemsToShow : 5;
      } else if (window.innerWidth >= 768) {
        this.ModuleNumOfItemsToShow = 3;
      } else if (window.innerWidth >= 580) {
        this.ModuleNumOfItemsToShow = 2;
      } else { this.ModuleNumOfItemsToShow = 2; }
    },
  },
};
</script>

<style lang="scss">
  
.cards-container.cards-logo{
  .module-border{
    width: 100% !important;
  }
  .cards-container .swipper-wrapper{
    @screen sm{
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .swiper-button-prev {
    left:0;
  }
  .swiper-button-next {
    right:0;
  }
  
  .swiper-card-container.container {
    padding: 0;
  }
  
  .swiper-card-container .swiper-container {
    padding-bottom: 0;
  }
  
  .swiper-button-next.logos-arrow-next,
  .swiper-button-prev.logos-arrow-prev {
    @apply text-htz-red;
    font-size: 2.7rem; 
    opacity: 1;
    transition: all 0.3s;
    padding: 0px;
    margin: 0px;
    height: 100%;
    justify-self: left;
    top: 0px;
    &:after{
      font-size: 2.7rem; 
    }
    @screen lg{
      @apply text-5xl;
      &:after{
        @apply text-5xl; 
      }
    }
  }

  .swiper-button-next.logos-arrow-next{
    right: -40px;
    
    @screen lg{
      right: -46px;
    }
  }
  .swiper-button-prev.logos-arrow-prev{
    left: -40px;
    @screen lg{
      left: -46px;
    }
  }

  .swiper-button.swiper-button-disabled {
    opacity: 0;
    &:after {
      opacity: 0;
    }
  }
}

</style>
