import axiosInstance from "../../services/http-common";
let promise;
let paramsResponse = [];

const PARAMETERS = {
    getParameter: function (key, defaultValue) {
        return new Promise((resolve, reject) => {
        if (!promise) {
            promise = axiosInstance.post('/api/company/params', { LanguageID: 'hr' }
            );
          }
    
          promise
            .then((response) => {
              paramsResponse = response.data;
              const expression = paramsResponse.find(
                (expression) => expression.Key === key
              );
              resolve(expression ? expression.Value : defaultValue);
            })
            .catch((e) => {
              reject(e);
            });
        });
    }
}

export default PARAMETERS
