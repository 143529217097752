<template>
  <template
    v-if="showChildren"
  >
    <ModuleLink
      v-if="$store.state.AdminMode"
      :module-properties="moduleInfo"
      :show-only-icon="true"
    />
    <h3
      v-if="moduleInfo.SefUrl || moduleInfo.CustomUrl"
      class="overtitle-1 md:text-15 text-htz-darkgrey pb-5 text-center md:text-left"
    >
      <VirtualLink :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl">
        {{ moduleInfo.Name }}
      </VirtualLink>
    </h3>
    <h3
      v-else
      class="overtitle-1 md:text-15 text-htz-darkgrey text-center md:text-left pb-5"
    >
      {{ moduleInfo.Name }}
    </h3>
  </template>
  <div
    class="footer-html-object text-center md:text-left"
    v-if="moduleInfo.MenuHtml"
    :class="moduleClass"
    v-html="moduleInfo.MenuHtml"
  />
  <ul
    v-if="(showChildren && moduleInfo.Children.length > 0) || (!showChildren && moduleInfo.length > 0)"
    :class="moduleClass"
    class="text-center md:text-left"
  >
    <template
      v-for="infoFooterItem in (showChildren ? moduleInfo.Children : moduleInfo)"
      :key="infoFooterItem.EntityID"
    >
      <ModuleLink
        v-if="$store.state.AdminMode"
        :module-properties="infoFooterItem"
        :show-only-icon="true"
      />
      <li
        v-if="infoFooterItem.SefUrl || infoFooterItem.CustomUrl"
      >
        <VirtualLink
          :href="infoFooterItem.CustomUrl ? infoFooterItem.CustomUrl : infoFooterItem.SefUrl"
          :class-list="'text-16 leading-8'"
        >
          {{ infoFooterItem.Name }}
        </VirtualLink>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
    moduleClass: {
        type: String,
        default: () => (''),
    },
    showChildren: {
      type: Boolean,
      default: () => (true),
    }
  }
};
</script>

<style lang="scss" scoped>
button:focus{outline:none;}
</style>
