import {createI18n, I18n} from "vue-i18n";
import axiosInstance from "./services/http-common";
import { Appstore } from "./store/main.store";
import {WritableComputedRef} from "@vue/reactivity";



export function setI18nLanguage(i18n = appI18n, locale: string) {
  if (i18n.mode === "legacy" || i18n.mode === "composition" ) {
    // changing the global locale, does not effect the app locale
    // izbvor https://vue-i18n-next.intlify.dev/guide/essentials/scope.html#local-scope-2
    i18n.global.locale.value = locale;
  } else {
    //i18n.global.locale.value = locale // Ovo puca iako postoji locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector("html")?.setAttribute("lang", locale);
}

export const appI18n = createI18n({
  locale: Appstore.state.LanguageId,
  legacy: false,
  fallbackLocale: "hr",
  globalInjection: true,
  missingWarn: process.env.NODE_ENV === 'development',
})

/**
 * main setup for i18n in tthe app
 * and save the instance in the appI18n so it can be used later by the app
 * @param  i18n
 * @param locale
 */

export async function loadLocaleMessages(i18n = appI18n) {
  // load locale messages
  //if (!i18n.global.availableLocales.includes(locale)) {
  const langMap: any = {};
  const langToUse = Appstore.state.LanguageId
  await axiosInstance
    .post("/api/entities/languageexpressions", {
      IDLanguage: langToUse,
    })
    .then((response) => {
      const msgs = response.data;
      msgs.map(
        (item: any) =>
          (langMap[item.Key] =
            item.Value != null ? item.Value : "[" + item.Key + "]")
      );
    /*  if (!i18n.global.availableLocales.includes(langToUse)) {
        i18n.global.availableLocales.push(langToUse)
      }*/
      i18n.global.setLocaleMessage(langToUse, langMap);
      setI18nLanguage(i18n, langToUse);
      Appstore.state.LoadedLocale = true
      Appstore.state.resetLanguage ++;
    });
  // i18n.global.setLocaleMessage(locale, langMap);
  //}
}
export function setupLanguages(i18n = appI18n) {
  //console.log("node", i18n.mode);
  //const locale = i18n.mode === "legacy" ? i18n.global.locale : i18n.global.locale.value;

  // load locale messages
  loadLocaleMessages(i18n);

  // set i18n language
  // setI18nLanguage(i18n, locale);
}
