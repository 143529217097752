<template>
  <div>
    <ModuleLink
      v-if="this.$store.state.AdminMode"
      :module-properties="moduleData"
    />
    <!-- if type of cards is cardIcon show icons-->
    <div
      v-if="moduleType === 'cardIcon'"
      class="min-h-20 mb-4"
    >
      <i class="icon icon-travel-info-ico text-80">
        <span class="path1" /><span class="path2" />
      </i>
    </div>
    <!-- else show images -->
    <div
      v-else
      class="relative"
    >
      <Favorite
        :entity="moduleData"
        :entity-type="entityType"
      />
      <VirtualLink
        :href="moduleData.ExternalLink || moduleData.SefUrl || moduleData.UrlHref"
        class-list="card-img-container block relative mb-6 overflow-hidden cursor-pointer"
      >
        <div
          v-if="moduleData.Rating"
          class="card-icon lg:opacity-0 h-30 absolute z-10 right-0 left-auto flex items-center justify-end rounded-full border-white hover:border-htz-red border-2 bg-white transition-all duration-300 cursor-pointer px-2 m-2"
        >
          <i class="icon icon-stars-ico text-yellow text-24" />
          <span class="text-htz-darkgrey text-16 ml-2">
            {{ moduleData.Rating }}.0
          </span>
        </div>
        <h3
          v-if="moduleProperties.ModuleHasWhiteTitleAndHideText === 'True'"
          class="headline-3b absolute z-10 bottom-0 top-auto left-0 right-0 px-4"
        >
          {{ getPropertyValue($t, "Title", moduleProperties, moduleData) }}
        </h3>
        <vue-picture
          v-if="moduleData.MediaGallery.length > 0"
          :image="moduleData.MediaGallery[0]"
          :hide-tooltip="false"
          :css-class="['card-img object-cover', moduleProperties.ModuleImageWithBorder !== 'True' ? '' : '', 'card-img object-contain img-with-border']"

        />
        <img
          v-if="moduleData.MediaGallery.length === 0 && showDefaultFavPhoto"
          class="card-fav-img"
          :src="defaultFavPhoto"
        >
        <img
          v-else-if="moduleData.MediaGallery.length === 0 && !showDefaultFavPhoto"
          class="w-full h-full object-cover transition-all duration-300"
          :src="defaultCardImg"
          alt="Default card image"
          @error="imageLoadError"
        >
      </VirtualLink>
    </div>
    <div
      v-if="moduleProperties.ModuleHasWhiteTitleAndHideText === 'True'"
      class="hidden"
    />
    <div
      v-else
      class="card-content-container"
      :class="{'card-content-container-smaller-font': moduleProperties.ModuleHasSmallerGreyTitle === 'True'}"
    >
      <span
        v-if="propertyOvertitle?.length"
        class="card-overtitle">
        <span
          v-if="propertyOvertitle[0]?.RelatedTypeID == -1"
          >
            {{getPropertyValue($t, propertyOvertitle[0].PropertyName, moduleData)}}
        </span>
        <Property
          v-else
          :value="getPropertyValue($t, propertyOvertitle[0].PropertyName, moduleData)"
          :property="propertyOvertitle[0]"
          :link="false"
        />
        <!-- samo za property "Category" (od entiteta News) -->
        <template v-if="propertyOvertitle?.length && moduleData.CategoryCards && moduleData.CategoryCards.length">
          <span
            v-for="cat of moduleData.CategoryCards"
            :key="cat.EntityID"
          >
            {{ getPropertyValue(  $t, "NameLanguageIndependent", moduleProperties, cat) }}
          </span>
        </template>

      </span>

      <h3 v-if="hasPropertyValue('Title', moduleProperties, moduleData)">
        <VirtualLink
          class-list="card-subtitle"
          :href="moduleData.SefUrl"
        >
          {{ getPropertyValue($t, "Title", moduleProperties, moduleData)}}
        </VirtualLink>
      </h3>
      <h3
        v-if="hasPropertyValue('Subtitle', moduleProperties, moduleData)"
        class="card-subtitle-2"
      >
        <span
          class="card-subtitle text-htz-darkgrey"
        >
          {{ getPropertyValue($t, "Subtitle", moduleProperties, moduleData) }}
        </span>
      </h3>

      <div
        v-if="
          hasPropertyValue('StartDate', moduleProperties, moduleData)
        "
        class="card-detail"
      >
        <AppDate :date="getPropertyValue(  $t, 'StartDate', moduleProperties, moduleData)" />

        <!--        {{
          new Date(getPropertyValue(  $t, "StartDate", moduleProperties, moduleData)).toLocaleDateString()
        }}-->
        <span
          v-if="
            hasPropertyValue('EndDate', moduleProperties, moduleData)
          "
        >{{ ' ' }}
          {{ $t("loc_to") }}
          {{ ' ' }}
          <AppDate :date="getPropertyValue(  $t, 'EndDate', moduleProperties, moduleData)" />
          <!--          {{
            new Date(getPropertyValue(  $t, "EndDate", moduleProperties, moduleData)).toLocaleDateString()
          }}-->
        </span>
      </div>
      <span
        v-if="moduleData.Place?.length > 0 || hasPropertyValue('Address', moduleProperties, moduleData)"
        class="card-location"
      >
        <i class="icon icon-pin-ico" />
        <span class="link">
          <template v-if="moduleData.Place?.length > 0">
            <Property
              :value="moduleData.Place"
              :property-name="'Place'"
              :property-to-show="'Title'"
              :external-link="true"
              :link="true"
              :link-property="'ExternalLink'"
              :class-list="'font-bold'"
            >
              {{ getPropertyValue(  $t, Place, moduleData) }}
            </Property>
          </template>

        </span>
        <!-- TODO: External Link -->
        <span
          v-if="hasPropertyValue('Address', moduleProperties, moduleData)"
          class="card-poi"
        >
          <span v-if="moduleData.Place?.length > 0" class="comma mr-1 text-htz-steel">,</span>{{getPropertyValue(  $t, 'Address', moduleProperties, moduleData) }}
        </span>

      </span>

      <template v-if="moduleProperties.ModuleShowAsFeed === 'True'">
        <div
          v-if="hasPropertyValue('HighlightedText', moduleProperties, moduleData)"
          class="card-desc"
          v-html="getPropertyValue(  $t, 'HighlightedText',moduleProperties,moduleData)"
        />
        <div
          v-else-if="hasPropertyValue('Text', moduleProperties, moduleData)"
          class="card-desc"
          v-html="getPropertyValue(  $t, 'Text',moduleProperties,moduleData)"
        />
      </template>
      <template v-else>
        <div
          v-if="hasPropertyValue('ShortDescription', moduleProperties, moduleData)"
          class="card-desc"
          v-html="getPropertyValue(  $t, 'ShortDescription',moduleProperties,moduleData)"
        />
      </template>
      <!-- TODO card-tags -->
      <div
        v-if="moduleData.Tag && moduleData.Tag?.length && moduleProperties.ModuleHideTags !== 'True'"
        class="tags-container py-3"
      >
        <Property
          :value="moduleData.Tag"
          :property-to-show="'Title'"
          :class-list="['card-tags']"
        >
          {{ tags }}
        </Property>
      </div>
      <div
        v-if="moduleData.POI_TA_TouristProduct && moduleData.POI_TA_TouristProduct?.length"
        class="py-3"
      >
        <Property
          :value="moduleData.POI_TA_TouristProduct"
          :property-to-show="'Title'"
          :charater-delimited="','"
          :class-list="['delimited-items']"
        >
        </Property>
      </div>

      <div
        v-if="moduleProperties.ModuleShowAsFeed === 'True'"
        class="card-link-share text-center px-2 sm:px-0"
      >
        <VirtualLink
          class-list="btn-small text-center m-auto text-htz-darkgrey"
          :href="moduleData.SefUrl"
        >
          {{ $t("loc_view_more") }}
        </VirtualLink>

        <Favorite
          :entity="moduleData"
          :inline="true"
          :entity-type="entityType"
        />
      </div>
    </div>
    <JsonLdManager
      :entity-id="entityId"
      :entity="moduleData"
    />
  </div>
</template>

<script>
import VuePicture from "./VuePicture";
import Property from "@/components/includes/entity/Property";
import VirtualLink from "@/components/includes/router/VirtualLink";
import { sefUrlMixin } from "@/mixins";
import Favorite from "@/components/includes/Favorite";
import JsonLdManager from "../utils/json-ld-manager";
import AppDate from "@/components/includes/Date";
import FILTERS from "@/components/filters/filter";

export default {
  name: "CardItem",
  components: {
    AppDate,
    JsonLdManager,
    Favorite,
    VuePicture,
    Property,
    VirtualLink,
  },
  mixins: [sefUrlMixin],
  props: {
    moduleData: {
      type: Object,
      default: () => ({}),
    },
    showDefaultFavPhoto: {
      type: Boolean,
      default: false
    },
    defaultFavPhoto: {
      type: String,
      default: '/dist/img/fav.jpg'
    },
    moduleProperties: {
      type: Object,
      default: () => ({}),
    },
    moduleType: {
      type: String,
      default: 'cardSquare'
    },
    entityId: {
      type: Number,
      required: true
    },
    entityType: {
      type: String,
      required: false,
      default: null
    }

  },
  data() {
    return {
      tooltipContent: [
        "<i>Ideal breathtaking beaches.</i><br><span>Photo:<span>Zoran Jelača</span></span><br><span>Location:<span>Grad Poreč, Istria</span></span>",
      ],
      propertyOvertitle: "",
      defaultCardImg: ""
    };
  },
  computed: {
    /*tags() {
      if (Array.isArray(this.moduleData.Tag)) {
        return this.moduleData.Tag.reduce((o, n, i) => o + (i !== 0 ? '#': '') + n.EntityID, '')
      } else {
        return this.moduleData.Tag
      }
    },*/
   /*  getModuleType(){
      console.log("get watch");

        return this.moduleType;
    }, */
  },
  watch:{
    /* getModuleType() {
      console.log("watch");
      this.getDefaultCardImg();

    } */
  },
  mounted(){
    this.getPropertyToShow();
    //console.log("this.moduleType");
    //console.log(this.moduleType);
    //this.getDefaultCardImg();
  },
  methods: {
    hasPropertyValue(property, moduleProperties, firstModuleDataItem) {
      return FILTERS.hasPropertyValue(property, moduleProperties, firstModuleDataItem)
    },
    getPropertyValue($t, property, moduleProperties, firstModuleDataItem) {
      return FILTERS.getPropertyValue($t, property, moduleProperties, firstModuleDataItem)
    },
    getPropertyToShow() {
      let dtaToUse = ''
      try {
        dtaToUse = JSON.parse(this.moduleProperties.ModuleCardOvertitle);

      } catch (e) {
        //console.log(e);
        dtaToUse = this.moduleProperties.ModuleCardOvertitle?.split("#")
          .map(o => ({PropertyID:null, PropertyName: o, RelatedTypeID: -1 ,GroupName: "SvojstvaObjekta"}))
      }
      this.propertyOvertitle = dtaToUse;
    },
    getDefaultCardImg(){
      console.log("this.moduleType");
      console.log(this.moduleProperties);

      this.defaultCardImg = '/cmsmedia/EditUtils/Cards/default-'+ this.moduleType+'.png';
    },
    imageLoadError(){
      this.defaultCardImg =  '/dist/img/default-'+ this.moduleType+'.png';
    }
  }
};
</script>
