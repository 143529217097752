

import {Vue} from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";

export default class JsonLd extends Vue {

  @Prop({required: true})
  json!: {[key:string]: string}

  @Prop({ required: false, default: '' })
    scriptId!: '';

  executed = false

  @Watch('json',{immediate: true, deep: true})
  onJsonChange(val: {[key: string]: string}) {
    if(!this.executed && Object.keys(val).length > 0) {
      this.setData();
      this.executed = this.scriptId ? false : true
    }
  }
/*  mounted() {
    this.setData()
  }*/
  setData() {
    //console.log(this.json);
    if (!this.json) {
      console.error('JSON prop missing')
      return;
    }
    if (typeof this.json !== 'object') {
      console.error('JSON prop wrong type')
      return;
    }

    if(this.scriptId){
      let readEl = document.getElementById(this.scriptId);
      if(!readEl){
        this.createJsonScript();
      } else {
        readEl.innerText = JSON.stringify(this.json);
        document.querySelector('head')!.appendChild(readEl);
      }
    } else {
      this.createJsonScript();
    }


  }

  createJsonScript(){
    const el = document.createElement('script');
    if(this.scriptId){
      el.setAttribute('id', this.scriptId);
    }
    el.type = 'application/ld+json';
    el.text = JSON.stringify(this.json);
    document.querySelector('head')!.appendChild(el);
  }
}
