
import {Prop} from "vue-property-decorator";
import {ModuleInfo} from "@/interfaces";
import {Vue} from "vue-class-component";

export default class AppTitle extends Vue{

  @Prop({default: null})
  moduleInfo: ModuleInfo | null = null

  @Prop({default: ''})
  title: string = ''

  @Prop({default: 'ModuleTitle', type: String})
  moduleProperties: string = 'ModuleTitle'

  @Prop({default: []})
  classList: string[] = []
}
