import axiosInstance from "@/services/http-common";
import {PageDataInterface} from "@/interfaces";
import {Appstore} from "@/store/main.store";
import {AxiosResponse} from "axios";
import {mainRouter} from "@/router/main.router";

export function appNavigate(url = '/'): Promise<AxiosResponse<PageDataInterface>> {
  Appstore.state.PageData.ModuleData = [];
    return axiosInstance.get<PageDataInterface>(
      (process.env.NODE_ENV === 'development' ? 'https://test.croatia.hr/' :  location.origin) + (url.startsWith('/') ? '' : '/') + url,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
}

export function appStoreNavigate(url = '/') {
  mainRouter.push(url);
  Appstore.state.navigateUrl = url + (url.includes('?') ? '&json=true' : '?json=true');
}
