
import {Listbox,ListboxButton,ListboxOptions,ListboxOption} from "@headlessui/vue";
import {Options, Vue} from "vue-class-component";
import {Prop, Emit, Watch} from "vue-property-decorator";
import {FilterType, SelectItem, SelectItemEmit} from "@/libs/ui/.storybook/interfaces.ts";


@Options({
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  emits: ['on-change-selected']
})
export default class AppDropDown extends Vue {

  @Prop({default: []})
  classList: string[] = [];
  @Prop({default: []})
  relationList: {[key: string]: string} = {};
  @Prop({default: []})
  selectedValues: SelectItemEmit[] = [];
  @Prop({default: ''})
  dropDownClass: string = '';
  @Prop({default: ''})
  optionClass: string = '';
  @Prop({default: ''})
  multipleSelect: boolean = false;
  @Prop({required: true})
  selectList: SelectItem[] = [];
  @Prop({default: 'font-semibold', required: false})
  selectedClass: string = 'font-semibold';
  @Prop({default: 'Odaberite', required: false})
  placeholder: string = 'Odaberite';
  @Prop({default: 'Title', required: false})
  title: string = 'Title';
  @Prop({default: false, required: false})
  selectAll: boolean = false;
  @Prop({default: undefined, required: false})
  selectAllString?: string;
  @Prop({default: 'font-normal', required: false})
  notSelectedClass: string = 'font-normal';
  @Prop({default: 'property', required: true})
  property: string = 'property';
  @Prop({default: null, required: false })
  selectedItem: SelectItem | null = null;
  @Prop({default: false, required: false })
  showTitle: boolean  = false;
  @Prop({default: 'dropdown', required: false})
  type: FilterType = 'dropdown';
  @Prop({default: null, required: false})
  lookupSource?: string;

  @Emit()
  onChangeSelected (val: SelectItemEmit) {}
  isSelected: Object = false
  valueToShow: SelectItem[] = [];
  selectedValue: SelectItem | null = null;
  selectMultipleItems: SelectItem[] = [];
  onChangeDropDown(val: SelectItem) {
    this.isSelected = true;
    if (val.value) {
      this.onChangeSelected({
        value: val.value,
        RelatedFields: val.RelatedFields,
        property: this.property,
        lookupSource: this.lookupSource,
        type: this.type
      });
      if(this.multipleSelect && this.selectMultipleItems && this.selectMultipleItems.length > 0){
        //Add all selectedValue to selectMultipleItems
        if(this.selectMultipleItems.some(o => o.value === val.value)){
          this.selectMultipleItems = this.selectMultipleItems.filter(o => o.value !== val.value)
        } else {
          this.selectMultipleItems.push(val)
        }
      } else {
        this.selectedValue = val;
        this.isSelected = true;
        this.selectMultipleItems.push(val)
      }
    } else {
      this.onChangeSelected({
        value: null,
        RelatedFields: val.RelatedFields,
        property: this.property,
        lookupSource: this.lookupSource,
        type: this.type
      });
      this.selectedValue = this.valueToShow[0];
      this.selectMultipleItems = []
      this.isSelected = false;
    }
  }
  @Watch('selectedItem')
  onSelectedItemChange(){
    this.init()
  }
  @Watch('selectedValues', {immediate: true,deep: true})
  onSelectedValuesChange(){
    this.init(false)
  }

  created() {
    // prva vrijednos dok nije neodabran neka bude
    this.init()

  }
  checkIfSelectedValueIncludeMyRelation(dropdownFilters:SelectItemEmit[]): boolean {
    return dropdownFilters.some(item => this.relationList[item.lookupSource ?? ''])
  }
  checkIfSelectedValueIncludeMeAsRelation(dropdownFilters:SelectItemEmit[]): boolean {
    return dropdownFilters.some(item => item.RelatedFields?.[this.lookupSource ?? 'none'])
  }
  getFilteredItemsByMRelation(dropdownFilters:SelectItemEmit[]): SelectItem[] {
    return this.selectList.filter(
      item => dropdownFilters.some(
        selected => item.RelatedFields?.[selected.lookupSource ?? '']?.includes(selected.value as number)
      )
    );
  }
  getFilteredItemsByMeAsRelation(dropdownFilters:SelectItemEmit[]): SelectItem[] {
    const ids = dropdownFilters.map(item => item.RelatedFields?.[this.lookupSource ?? 'none']);

    if (ids.length) {
      return this.selectList.filter(o => {
        return ids.some(i => i?.includes(o.value as number));
      });
    } else {
      return  this.selectList;
    }

  }
  init(reset = true) {
    if (reset) {
      this.isSelected = false;
    }
    this.valueToShow = [{name: this.placeholder, value: null} ];
    if (this.selectAll) {
      this.valueToShow.push({name: this.selectAllString ? this.$t(this.selectAllString) : this.$t("loc_dropdownlist_all"), value: 0})
    }



    if (this.checkIfSelectedValueIncludeMyRelation(this.selectedValues)) {
      this.valueToShow.push(...this.getFilteredItemsByMRelation(this.selectedValues));
    } else if (this.lookupSource && this.checkIfSelectedValueIncludeMeAsRelation(this.selectedValues)) {
      this.valueToShow.push(...this.getFilteredItemsByMeAsRelation(this.selectedValues));
    } else {
      this.valueToShow.push(...this.selectList);
    }


    if (reset) {
      if ( this.selectedItem && this.valueToShow.some(o => o.value === this.selectedItem?.value)) {
        this.selectedValue = {...(this.valueToShow.find(o => o.value === this.selectedItem?.value) as SelectItem)}
        if(this.multipleSelect){
          this.selectMultipleItems.push(this.selectedValue)
        }
      }  else if(this.selectedItem && this.selectedItem.value && this.selectedItem?.value.toString().split('#').length > 1){
          this.selectMultipleItems = this.selectedItem?.value.toString().split('#').map(o => {
            return this.valueToShow.find(i => i.value === +o) as SelectItem
          })
        this.selectedValue = {...(this.valueToShow.find(o => o.value === this.selectedItem?.value) as SelectItem)}

      } else if(this.selectedItem && this.selectedItem.value && this.selectedItem?.value.toString().split('|').length > 1){
          this.selectMultipleItems = this.selectedItem?.value.toString().split('|').map(o => {
            return this.valueToShow.find(i => i.value === +o) as SelectItem
          })
        this.selectedValue = {...(this.valueToShow.find(o => o.value === this.selectedItem?.value) as SelectItem)}

      }
       else {
        this.selectedValue = {name: this.placeholder, value: null}
      }
      if(this.selectedValue!.value || this.selectMultipleItems.length > 0){
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    }

  }
}

