

import AppDropDown from "@/libs/ui/lib/forms/select/AppDropDown.vue";
import {Options, Vue} from "vue-class-component";
import {Emit, Prop, Watch} from "vue-property-decorator";
import {FilterDataDropDownInput} from "@/libs/features/.storybook/interfaces";
import {SelectItemEmit} from "@/libs/ui/.storybook/interfaces";
import { truncateSync } from "fs";

@Options({
  components: {AppDropDown},
  emits: ['on-selected-values']
})
export default class FilterDropDown extends Vue {

  filterDataOriginal: FilterDataDropDownInput[] = []
  filterDataToShow: FilterDataDropDownInput[] = []
  selectedFilters: any[] = []

  @Prop({default: []})
  filterData: FilterDataDropDownInput[] = [];

  @Prop({default: false, required: false})
  selectAll: boolean = false;

  @Prop({default: []})
  multipleSelect: boolean = false;

  @Prop({default: []})
  classList: string[] = [];

  @Prop({default: []})
  dropdownClassList: string[] = [];

  @Emit()
  onSelectedValues(val:SelectItemEmit[]) {}

  selectedValues: SelectItemEmit[] = [];

  @Watch('filterData', {immediate: true, deep: true})
  onFilterDataChange(val:FilterDataDropDownInput[]) {
    this.filterDataOriginal = JSON.parse(JSON.stringify(val))
    this.filterDataToShow = val
    this.selectedValues = []
    val.forEach(o => {
      if (o.selectedValue && o.selectedValue.property) {
        this.selectedValues.push({
          property: o.selectedValue.property,
          lookupSource: o.selectedValue.lookupSource,
          RelatedFields: o.selectedValue.RelatedFields,
          value: (o.selectedValue.value)
        })
      }
    })
  }
  onChangeSelected(val:SelectItemEmit) {

    if (this.selectedValues.some(o => o.property === val.property)) {
      this.selectedValues = this.selectedValues.map(o => {
          if (o.property === val.property && !this.multipleSelect) {
            return {
              ...o,
              ...val,
              value: (o.property === val.property ? val.value : o.value)
            }
          } else if(o.property === val.property && this.multipleSelect) {
            let valueExists = false;
            if(o.value) {
              if(val.value) {
                valueExists = o.value.toString().split('|').some(v => v === val.value?.toString())
                let valuePosition = o.value.toString().split('|').findIndex(v => v === val.value?.toString())
                return {
                  ...o,
                  ...val,
                  value: (o.property === val.property && valueExists ? (valuePosition === 0 ? o.value.toString().replace(new RegExp(val.value.toString() + '\\|?', 'g'), '') : o.value.toString().replace('|' + val.value, '')) : o.value  + '|' + val.value)
                }
              } else {
                return {
                  ...o,
                  ...val,
                  value: (o.property === val.property ? val.value : val.value)
                }
              }
            }
          }
          return o
      })
    } else {
      this.selectedValues.push(val)
    }
    // flat array da se ne stvara array unutar objekta (selectedFilters je array)
/*    this.selectedFilters = [this.selectedFilters, ...this.selectedValues].flatMap(sf => sf);

    // reverse tako da se nade zadnji dodani filter i izbacivanje ako je null (Svi)
    var selectedFiltersReverse = [...this.selectedFilters].reverse()

    this.selectedFilters = selectedFiltersReverse.filter((filter, i) =>
        filter["value"] != null && selectedFiltersReverse.findIndex((f) => {
          return f.property == filter.property
        }) == i
      )*/

    // TODO provjeriti ako je potrebno koristiti property ili lookupSource
    // ako nema filtera pusti originalne podatke inace ako postoje filteri izbrisi podatke iz filterDataToShow.data (poslije se dodaju)
/*
    if(this.selectedFilters.length > 0)
        this.filterDataToShow.map(o => {
          if(this.selectedFilters.some(filter => {
              return o.relatedFields?.split(",").includes(filter["lookupSource"])
            })) o.data = []
        })
    else this.filterDataToShow = this.filterDataOriginal;


    console.log("selectedFilters (stringify)", JSON.stringify(this.selectedFilters))
    console.log("filterDataToShow prije dodavanja", this.filterDataToShow)

    // TODO provjeriti ako je potrebno koristiti property ili lookupSource
      this.filterDataOriginal.forEach(o =>
        o.data.forEach(obj =>
          {
            var pass = true;
            var relatedfield = obj["RelatedFields" as keyof typeof obj] as Array<object>;
            for(const prop in relatedfield) {
              var relatedfieldobject = relatedfield[prop]
              for(const props in relatedfieldobject) {
                var relatedfieldobjectprops = relatedfieldobject[props as keyof typeof relatedfieldobject]
                if(this.selectedFilters.some(filter => filter["lookupSource"] === prop) && pass)
                {
                  if(this.selectedFilters.some(filter => filter["value"] === relatedfieldobjectprops["EntityID"]) ){
                    pass = true;
                    console.log("obj",obj,"send",pass)
                  }
                  else pass = false;
                }
              }
            }
            if(pass)
              this.filterDataToShow.forEach(oShow =>
                                {if(o.lookupSource == oShow.lookupSource) {
                                  oShow.data.push(obj)
                                }}
                              )
            }
        ))

    console.log("filterDataToShow nakon dodavanja", JSON.stringify(this.filterDataToShow))
*/

    this.selectedValues = this.selectedValues.filter(o => o.value)
    this.onSelectedValues(this.selectedValues)
  }

}
