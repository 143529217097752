<template> 
  <div
    class="footer-small container max-w-screen-3xl mx-auto flex items-center bg-htz-steel text-white mt-5"
  >
    <div
      class="container flex-col lg:flex-row justify-between max-w-screen-xl flex py-5 px-6 text-center"
    >
      <p 
        v-if="$t('loc_copyright')"
        class="block lg:flex w-full lg:w-6/12 text-16 lg:text-left"        
      >        
        {{ $t("loc_copyright") }}
      </p>
      <template
        v-if="moduleInfo.length > 0"          
      >
        <FooterMenuItem           
          :module-info="moduleInfo"
          :module-class="'flex flex-col lg:flex-row w-full sm:w-auto md:space-x-4 items-center leading-10 lg:leading-8 mt-5 lg:mt-0'"
          :show-children="false"
        /> 
      </template>      
    </div>
  </div>                                
</template>

<script>
import FooterMenuItem from "./FooterMenuItem";

export default {
  components: {    
    FooterMenuItem
  },
  props: {
    moduleInfo: {
      type: Array,
      default: () => ([]),
    },
  }
};
</script>
