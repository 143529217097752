<template>
  <slot />
  <div
    v-if="isLoading"
  >
    <div class="loader" />
  </div>
</template>

<script>
 export default {
   name: 'LoadingLayout',
   props: {
     isLoading: {
       type: Boolean,
       default: false
     }
   }
 }
</script>
