<template>
  <div
    class="intro-text content max-w-screen-md lg:max-w-screen-2xl mx-auto"
    :class="moduleInfo.Module.CssClass"
  >
    <div
      v-if="loaded"
      class="max-w-screen-xl mx-auto lg:px-4"
    >
      <div
        class="px-4 mt-8 lg:pl-0"
        :class="moduleInfo.Module.ModuleProperties.ModuleHasLayoutWidth === '940px' ? 'lg:max-w-940' : 'lg:max-w-835'"
      >
        <!-- intro overtitle -->
        <div
          :class="{
            'border-htz-red border-0 border-l-2 py-2 lg:py-4 px-4 lg:px-8':
              moduleInfo.Module.ModuleProperties.ModuleHasBorder === 'True',
          }"
        >
          <p
            v-if="hasPropertyValue('Overtitle', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
            class="overtitle pb-3"
          >
            {{ getPropertyValue(  $t, "Overtitle", moduleInfo.Module.ModuleProperties,firstModuleDataItem) }}
          </p>
          <p
            v-if="hasPropertyValue('IntroText', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
            class="p-lg leading-1/33"
            v-html="getPropertyValue(  $t, 'IntroText', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          />
          <p
            v-if="hasPropertyValue('HighlightedText', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
            class="p-base leading-1/66 mt-4"
            v-html="getPropertyValue(  $t, 'HighlightedText', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          />
        </div>
        <!-- intro tekst big sa borderom na mobile (na templatima kada je odabran page title big) -->
        <!-- <p class="p-lg border-htz-red border-0 border-l-2 lg:border-none pl-3 lg:px-0 mb-6">
                      Intro tekst big sa borderom na mobile
                  </p> -->

        <div
          v-if="hasPropertyValue('Text',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          class="py-5 md:py-10 heading-sizes"
          :class="{
            'lg:pl-20 lg:pr-12':
              moduleInfo.Module.ModuleProperties.ModuleHasIndentText ===
              'True',
          }"
        >
          <p
            class="content-text p-sm leading-8"
            :class="{
              ' text-htz-steel':
                moduleInfo.Module.ModuleProperties.ModuleHasIndentText ===
                'True',
            }"
            v-html="getPropertyValue($t,'Text',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          />
          <span
            v-if="
              hasPropertyValue(
                'TextReadMore',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
          >
            <span v-if="readMore" />
            <span v-else>...</span>
          </span>
          <p
            v-show="readMore"
            v-if="
              hasPropertyValue(
                'TextReadMore',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
            class="content-text p-sm leading-8"
            :class="{
              ' text-htz-steel':
                moduleInfo.Module.ModuleProperties.ModuleHasIndentText ===
                'True',
            }"
            v-html="
              getPropertyValue(  $t,
                'TextReadMore',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
          />
          <button
            v-if="
              hasPropertyValue(
                'TextReadMore',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
            class="btn-small-more"
            :class="{ active: readMore }"
            @click="expandText"
          >
            <span v-if="readMore"> {{ $t("loc_read_less") }}</span>
            <span v-else> {{ $t("loc_read_more") }}</span>
          </button>
        </div>
        <!-- paragraf tekst (ne uvučen i svjetliji)-->
        <!-- <div class="py-5 md:py-10">
                      <p class="p-sm text-htz-steel">
                          Paragraf tekst ne uvučen i svjetliji Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium distinctio iure cumque at excepturi maxime quis amet quaerat fugiat aut, earum facere illum voluptates saepe vitae cupiditate voluptatem odio assumenda mollitia numquam ex obcaecati voluptate eveniet illo? Eaque molestias sint optio saepe nesciunt harum, quisquam, aut voluptates, magni minima repudiandae voluptatem? Eum perspiciatis repudiandae laboriosam debitis vitae voluptatem rem, vero distinctio inventore, voluptatum id quae excepturi aliquid quaerat velit ex, odit tenetur molestias incidunt ratione culpa facilis eaque. Rem, magni.
                      </p>
                  </div> -->
      </div>
    </div>
  </div>
</template>
<script>

import {selfDataRequestMixin} from "@/mixins";

export default {
  name: "IntroText",
  components: {
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      readMore: false,
    };
  },
  methods: {

    expandText: function (e) {
      e.preventDefault();
      this.readMore = !this.readMore;
    },
  },
};
</script>

<style lang="scss" scoped>
/* .content-text::v-deep(ul) {
  @apply list-none m-0 p-0;
}
.content-text::v-deep(ul li) {
  @apply font-livory text-htz-darkgrey text-18 leading-loose pl-8 relative mb-3;
}
.content-text::v-deep(ul li:before) {
  content: "•";
  @apply absolute inline-block left-0;
} */

.col-container .intro-text.content{
    @screen lg{@apply pr-12;}
}
</style>
