<template>
  <div>
    <div
      v-if="moduleInfo.length > 0"
      class="w-full flex flex-wrap items-end justify-center lg:justify-start text-left pb-5 lg:pb-10 lg:pt-4 space-y-4 md:space-y-0 lg:min-w-900"
    >
      <MenuImagesLink
        v-for="logo in moduleInfo"
        :key="logo.EntityID"
        :menu-item="logo"
        :class-menu="'relative flex items-center w-auto lg:w-3/12 h-20 px-4 lg:px-0 h-auto'"
        :media-type="'default'"
        :img-class="'footer-logo-image'"
      />
    </div>
    <p
      v-if="$t('loc_footer_logos')"
      class="footer-extra-text p-xs lg:w-12/12 flex flex-wrap text-htz-grey">
      <span v-html="$t('loc_footer_logos').replace(/\./g, '.<br>')"></span>
    </p>

  </div>
</template>

<script>
import { menuMediaMixin } from '@/mixins';
import MenuImagesLink from "../MenuImagesLink";

export default {
  components: {MenuImagesLink},
  mixins: [menuMediaMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      footerText: ''
    }
  },
  mounted() {
    this.footerText = this.$t("loc_footer_logos").replace(/\./g, '.<br>'); //add new line after dot BUG #96229
  },
};
</script>
