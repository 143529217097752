

import {Options, Vue} from "vue-class-component";
import {regionList} from "../../utils/regionList";
import {Action} from "vuex-class";
import {Prop, Watch} from "vue-property-decorator";
import SelectItemRegion from "../selects/SelectItemRegion.vue";

@Options({
  components: {
    SelectItemRegion
  }
})
export default class RegionMap extends Vue{

  @Prop({default: ''})selectedRegion: string = ''

  @Prop({default: ''})mapPosition: string = ''

  @Prop({default: ''})cssClass: string = ''
  @Watch('regionsPaths') onRegionsPaths() {
    this.initRegionStore()
  }
  @Action('initRegionStore')
  initRegionStore!: () => void

  regionsPaths: {dataRegion: string, d: string}[] = regionList;
  showTooltip = false;
  hoveredRegion = '';
  mousePosition = {
    top: '0',
    left: '0'
  };

  get entityTypes() {
    return this.$store.state.entityTypes
  }
  get regionList() {
    return Object.values(this.$store.state.entitiesStore['Region']?.entities ?? {})
  }
  get mapOffsetTop() {
    return (this.$refs?.tooltipMap as any)?.offsetTop
  }
  get regionTitle() {
    return this.hoveredRegion ? this.getRegion( this.hoveredRegion)?.Title ?? this.hoveredRegion : ''
  }
  activeRegion(activeRegion: string): boolean {
    //console.log(activeRegion, this.getRegion(activeRegion)?.EntityID.toString(),this.selectedRegion);
    return this.getRegion(activeRegion)?.EntityID.toString() === this.selectedRegion;
  }
  mounted() {
    this.initRegionStore()
  }
  getRegion(NameMap: any) {
    return this.regionList.find((e: any) => e.NameMap?.toLowerCase().includes(NameMap.replace('region-','')))
  }
  mouseMove(e: any) {
    this.mousePosition = {
      top:(this.mapOffsetTop + e.clientY) - 80 + "px",
      left: e.clientX - 0 + "px",
    }
  }
  getLink(e: any) {
    return this.getRegion(e.dataRegion)?.SefUrl
  }
  mouseLeave() {
    this.showTooltip = false
  }
  hoverRegion(e: any) {
    this.hoveredRegion = e.target.dataset.region;
    this.showTooltip = true
  }
  scrollTo(e: any){
    e.target.closest(".regions-map-container").scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
  }
}
