<template>
  <!-- TODO
  - potrebno je firstModuleDataItem izmjeniti u moduleData - tako da dohvaća sve objekte a ne samo jedan
  - dodati swiper na banner, tako da je svaki objekt jedan slide, neka se loopa, ako se modul spoji na objekt koji baca manje od 2, neka se slider disablea
  - urednik mora moći mijenjati brzinu kojom se slider mijenja, to napraviti isto kao što ima npr Card Square modul, dakle može se mijenjati brzina slideanja na razini modula, ali isto postoji i globalni parametar kojim se može definirati za sve slidere na web-u
  swiperDelay: this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed?.length > 0 ? this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed : this.$store.state.Params.find(o => o.Key === 'SliderSpeed')?.Value || 8000,
  - paziti da se prilikom izmjene ovog modula ne promjeni ništan a Banner.vue modulu
  -->
  <div class="banner-slider">
    <div
      v-if="loaded"
      class="cards-container cards-portrait cards-logo my-16 2xl:px-0"
      :class="moduleInfo.Module.CssClass"
    >
      <div
        class="container max-w-screen-3xl mx-auto mt-16 mb-16 lg:mt-20 lg:mb-32"
        v-if="moduleData.length > 1"
      >
        <div class="swiper-card-container swiper-container-horizontal banner max-w-screen-2xl mx-auto relative my-12 relative">
          <div class="">
            <swiper
              v-if="moduleData.length > 1"
              :navigation="swiperBannerSlider.navigation"
              :pagination="{ clickable: true, dynamicBullets: true }"
              :speed="800"
              :loop="swiperBannerSlider.loop"
              :autoplay="{
                delay: swiperDelay,
                disableOnInteraction: false,
              }"
            >
              <template v-for="item in moduleData" :key="item.EntityID">
                <swiper-slide>
                  <!-- banner naslov, CTA -->
                  <div
                    class="banner max-w-screen-2xl mx-auto relative my-12 h-fixed-460 max-h-460"
                  >
                    <div
                      class="overlay-gradient absolute z-1 left-0 right-0 top-0 w-full h-full"
                    />
                    <vue-picture
                      v-if="item?.MediaGallery.length"
                      :image="item.MediaGallery[0]"
                      css-class="banner-img object-cover"
                    />

                    <div
                      class="container max-w-screen-xl absolute z-1 vh-center"
                    >
                      <div
                        class="max-w-835"
                        :class="{
                          'max-w-full text-center':
                            moduleInfo.Module.ModuleProperties.ModuleHasCenterContent === 'True',
                        }"
                      >
                        <p
                          v-if="hasPropertyValue('Overtitle', moduleInfo.Module.ModuleProperties, item)"
                          class="overtitle text-white"
                        >
                          {{
                            getPropertyValue($t,"Overtitle",moduleInfo.Module.ModuleProperties,item)
                          }}
                        </p>
                        <p
                          v-if="
                            hasPropertyValue(
                              'HighlightedText',
                              moduleInfo.Module.ModuleProperties,
                              item
                            )
                          "
                          class="p-lg mb-4 text-white"
                          v-html="
                            getPropertyValue(
                              $t,
                              'HighlightedText',
                              moduleInfo.Module.ModuleProperties,
                              item
                            )
                          "
                        />
                        <VirtualLink
                          v-if="
                            hasPropertyValue(
                              'UrlText',
                              moduleInfo.Module.ModuleProperties,
                              item
                            )
                          "
                          :href="
                            getHref(moduleInfo.Module.ModuleProperties, item)
                          "
                          :class="'btn ' + btnClass + ' '"
                        >
                          {{
                            getPropertyValue(
                              $t,
                              "UrlText",
                              moduleInfo.Module.ModuleProperties,
                              item
                            )
                          }}
                        </VirtualLink>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </template>
            </swiper>
          </div>
          <div class="swiper-button swiper-button-next banner-arrow-next" />
          <div class="swiper-button swiper-button-prev banner-arrow-prev" />
        </div>
      </div>
      <div class="container max-w-screen-3xl mx-auto mt-16 mb-16 lg:mt-20 lg:mb-32" v-else>
        <div
          class="banner max-w-screen-2xl mx-auto relative my-12 h-fixed-460 max-h-460"
        >
          <div
            class="overlay-gradient absolute z-1 left-0 right-0 top-0 w-full h-full"
          />
          <vue-picture
            v-if="firstModuleDataItem?.MediaGallery.length"
            :image="firstModuleDataItem.MediaGallery[0]"
            css-class="banner-img object-cover"
          />

          <div class="container max-w-screen-xl absolute z-1 vh-center">
            <div
              class="max-w-835"
              :class="{
                'max-w-full text-center':
                  moduleInfo.Module.ModuleProperties.ModuleHasCenterContent ===
                  'True',
              }"
            >
              <p
                v-if="
                  hasPropertyValue(
                    'Overtitle',
                    moduleInfo.Module.ModuleProperties,
                    firstModuleDataItem
                  )
                "
                class="overtitle text-white"
              >
                {{
                  getPropertyValue(
                    $t,
                    "Overtitle",
                    moduleInfo.Module.ModuleProperties,
                    firstModuleDataItem
                  )
                }}
              </p>
              <p
                v-if="
                  hasPropertyValue(
                    'HighlightedText',
                    moduleInfo.Module.ModuleProperties,
                    firstModuleDataItem
                  )
                "
                class="p-lg mb-4 text-white"
                v-html="
                  getPropertyValue(
                    $t,
                    'HighlightedText',
                    moduleInfo.Module.ModuleProperties,
                    firstModuleDataItem
                  )
                "
              />
              <VirtualLink
                v-if="
                  hasPropertyValue(
                    'UrlText',
                    moduleInfo.Module.ModuleProperties,
                    firstModuleDataItem
                  )
                "
                :href="getHref(moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
                :class="'btn ' + btnClass + ' '"
              >
                {{
                  getPropertyValue(
                    $t,
                    "UrlText",
                    moduleInfo.Module.ModuleProperties,
                    firstModuleDataItem
                  )
                }}
              </VirtualLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePicture from "../../includes/VuePicture";

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperBannerSlider } from "@/services/swiperOptions";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import { selfDataRequestMixin } from "@/mixins";
//import {dateFromString} from "@/services/date-utilis";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default {
  name: "BannerSlider",
  components: {
    VuePicture,
    Swiper,
    SwiperSlide,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      galleryType: "bannerImage",
      target: "_self",
      btnClass:
        this.moduleInfo.Module.ModuleProperties.ModuleButtonType?.length > 0
          ? this.moduleInfo.Module.ModuleProperties.ModuleButtonType
          : "btn-transparent-blue",
      swiperBannerSlider: { ...swiperBannerSlider },
      swiper: {},
      swiperDelay:
        this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed?.length > 0
          ? this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed
          : this.$store.state.Params.find((o) => o.Key === "SliderSpeed")
              ?.Value || 8000,
    };
  },
  computed: {
    externalUrl() {
      const regex = new RegExp(
        `((http:|https:)?(//)(?!${window.location.host})[\\w\\.\\/\\-=?#]+)`,
        "g"
      );
      console.log(this.url);
      console.log(regex.test(this.url));
      return regex.test(this.url);
    },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.banner {
  .btn {
    @apply w-auto min-w-200 pr-8;
    width: fit-content;
  }
}

.banner-slider {
  .cards-portrait .swiper-card-container {
    max-width: 1440px;
    margin-right: auto;
  }
  .swiper-card-container {
    &.container{
      padding-left: 1rem;
      padding-right: 1rem;
      @screen md {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .swiper-button-next.banner-arrow-next,
    .swiper-button-prev.banner-arrow-prev {
      @apply text-htz-red text-32 hidden;
      opacity: 1;
      display: none;
      transition: all 0.3s;
      @screen md {
        @apply block;
        opacity: 0;
        display: flex;
      }
      &:after{
        @apply text-32;
      }
      @screen lg{
        @apply text-5xl;
        &:after{
          @apply text-5xl;
        }
      }
      @screen lg{
        @apply text-5xl;
        &:after{
          @apply text-5xl;
        }
      }

    }
    .swiper-button-prev.banner-arrow-prev {
      left: 20px;
      @screen md {
        left: 10px;
      }
      @media (min-width: 1550px) {
        left: -40px;
      }
    }
    .swiper-button-next.banner-arrow-next {
      right: 20px;
      @screen md {
        right: 10px;
      }
      @media (min-width: 1550px) {
        right: -40px;
      }
    }
    &:hover {
      .swiper-button-next.banner-arrow-next,
      .swiper-button-prev.banner-arrow-prev {
        opacity: 1;
      }
    }
  }

  .cards-container.cards-logo .swiper-only-container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

</style>
