import {createRouter, createWebHistory, RouterScrollBehavior} from "vue-router";
import AppRoute from "@/AppRoute.vue";
import HtzIcons from "@/components/html/HtzIcons.vue";

export const mainRouter = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/', component: AppRoute
    },
    {
      path: '/htz-icons', component: HtzIcons,
    },
    {
      path: '/:lang', component: AppRoute, props: true, children: [
        {
          path: ':first', component: AppRoute, props: true, children: [
            {
              path: ':second', component: AppRoute, props: true, children: [
                {
                  path: ':third', component: AppRoute, props: true, children: [
                    {
                      path: ':forth', component: AppRoute, props: true, children: [
                        {
                          path: ':fifth', component: AppRoute, props: true, children: [
                            {
                              path: ':sixth', component: AppRoute, props: true, children: [
                                {
                                  path: ':seventh', component: AppRoute, props: true, children: [
                                    {
                                      path: ':eight', component: AppRoute, props: true, children: [

                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.params.savePosition === 'true') {
      return {}
    }
    if (to.hash) {
      let position = {selector: to.hash}
      return position
    }

    // scroll to top by default
    return {left: 0, top: 0, behavior: 'smooth',}
  }
})

