import {Options} from "vue-class-component";
import {mapActions} from "vuex";
import {Prop} from "vue-property-decorator";
import {ComponentState, ModuleData, ModuleInfo, ModulePicturesInterface, ModulePropertiesInterface} from "@/interfaces";
import {DefaultMixin} from "@/mixins/default.mixin";
import {Action} from "vuex-class";
import {LocationQuery} from "vue-router";

@Options({
  methods: {
    ...mapActions(['makeRequest', 'setModuleInfo']),
  }
})

export class SelfDataMixin extends DefaultMixin {

  @Action('makeRequest')
  makeRequest!: (data: {moduleInfo: ModuleInfo, galleryType: string, hasVideoPoster: boolean}) => void

  @Action('setModuleInfo')
  setModuleInfo!: (data: {moduleInfo: ModuleInfo, query: LocationQuery, galleryType: string,}) => void

  @Prop({default: 'default'})
  galleryType: keyof  ModulePicturesInterface = 'default'

  hasVideoPoster = false;


  mounted() {
    // this.registerStore()
  }


  /*registerStore() {
    this.setModuleInfo({
      moduleInfo: this.moduleInfo,
      query: this.$route.query,
      galleryType: this.galleryType
    })
    if (this.moduleInfo.Module.Api.RequestBody.EntityTypeID) {
      this.makeRequest(
        {
          moduleInfo: this.moduleInfo,
          galleryType: this.galleryType,
          hasVideoPoster: this.hasVideoPoster
        })
    }
  }*/
}
