<template>
  <div :class="moduleInfo.Module.CssClass">
    <div
      v-if="loaded"
      class="cards-container cards-landscape my-16"
    >
      <div
        v-if="hasPropertyValue('ModuleTitle', moduleInfo.Module.ModuleProperties, moduleData) ||
          hasPropertyValue('ModuleIntroTitle',moduleInfo.Module.ModuleProperties,moduleData)"
        class="container max-w-screen-xl mx-auto"
      >
        <h2 class="card-headline mt-8 mb-4">
          <span
            v-if="
              hasPropertyValue(
                'ModuleIntroTitle',
                moduleInfo.Module.ModuleProperties,
                moduleData
              )
            "
            class="-mt-8"
          >{{
            getPropertyValue( $t,
                              "ModuleIntroTitle",
                              moduleInfo.Module.ModuleProperties,
                              moduleData
            )
          }}</span>
          {{
            getPropertyValue( $t,
                              "ModuleTitle",
                              moduleInfo.Module.ModuleProperties,
                              moduleData
            )
          }}
        </h2>
      </div>
      <!-- && moduleInfo.Module.ModuleProperties.ModuleShowAsFeed !== 'True' "-->
      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleHasSlider === 'True' && moduleData.length > minNumbOfDataToBeSlider"
        class="swiper-card-wrapper lg:max-w-full xl:max-w-screen-3xl"
      >
        <div
          class="swiper-card-container container 3xl:px-0 lg:max-w-screen-xl xl:max-w-screen-2xl lg:ml-0 3xl:ml-auto 4xl:max-w-screen-xl relative"
          :class="{'mt-8': moduleInfo.Module.ModuleProperties.ModuleShowAsFeed === 'True'}"
        >
          <swiper
            v-if="moduleData.length > 0"
            :breakpoints="swiperPortraitOptions.breakpoints"
            :slides-per-view="swiperPortraitOptions.slidesPerView"
            :loop="swiperPortraitOptions.loop"
            :centered-slides="swiperPortraitOptions.centeredSlides"
            :space-between="swiperPortraitOptions.spaceBetween"
            :watch-slides-visibility="swiperPortraitOptions.watchSlidesVisibility"
            :navigation="swiperPortraitOptions.navigation"
            :pagination="{ clickable: true, dynamicBullets: true }"
            :auto-height="swiperPortraitOptions.autoHeight"
            :speed="800"
            :autoplay="{
              'delay': swiperDelay,
              'disableOnInteraction': false
            }"

            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <template
              v-for="card in moduleData"
              :key="card.EntityID"
            >
              <!--               <template v-if="hasPropertyValue('EndDate', moduleProperties, card) && !checkIfDateIsValid(card.EndDate)" />
 -->
              <swiper-slide
                :style="{marginRight: swiperPortraitOptions.spaceBetween + 'px'}"
                class="card inline-flex flex-col py-8"
                :class="{'card-feed': moduleInfo.Module.ModuleProperties.ModuleShowAsFeed === 'True'}"
              >
                <card-item
                  :entity-type="moduleInfo.Module.Binding.SourceName"
                  :entity-id="moduleInfo.Module.Binding.SourceEntityTypeID"
                  :module-data="card"
                  :module-properties="moduleInfo.Module.ModuleProperties"
                  module-type="cardLandscape"
                />
              </swiper-slide>
            </template>

            <div class="swiper-button swiper-button-next landscape-arrow-next" />
          </swiper>
          <div class="swiper-button swiper-button-prev landscape-arrow-prev" />
        </div>
      </div>

      <!-- NO SWIPER -->
      <div
        v-else
        class="container max-w-screen-xl mx-auto"
      >
        <!--class="card-grid-container grid gap-5 sm:gap-3 lg:gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mt-8"-->
        <div
          class="card-grid-container grid gap-3 lg:gap-5 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mt-8"
          :class="{'card-flex-container': moduleData.length < 3}"
        >
          <template
            v-for="card in moduleData"
            :key="card.EntityID"
          >
            <!--             <template v-if="hasPropertyValue('EndDate', moduleProperties, card) && !checkIfDateIsValid(card.EndDate)" />
 -->
            <div
              class="card inline-flex flex-col"
              :class="{'card-feed': moduleInfo.Module.ModuleProperties.ModuleShowAsFeed === 'True'}"
            >
              <card-item
                class="card-content-div"
                :entity-type="moduleInfo.Module.Binding.SourceName"
                :entity-id="moduleInfo.Module.Binding.SourceEntityTypeID"
                :module-data="card"
                :module-properties="moduleInfo.Module.ModuleProperties"
                module-type="cardLandscape"
              />
            </div>
          </template>
        </div>
      </div>

      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleLinkName"
        class="container max-w-screen-xl mx-auto text-center md:text-right"
      >
        <VirtualLink
          :href="moduleInfo.Module.ModuleProperties.ModuleLinkUrl"
          class-list="btn-small inline-flex t mt-2"
        >
          {{ tt(moduleInfo.Module.ModuleProperties.ModuleLinkName) }}
        </VirtualLink>
      </div>

      <div class="module-border container max-w-screen-2xl mx-auto mt-16 mb-4" />
    </div>
  </div>
</template>

<script>
import CardItem from "@/components/includes/CardItem";

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperPortraitOptions } from "@/services/swiperOptions";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss"
import {selfDataRequestMixin} from "../../../mixins";
//import {dateFromString} from "@/services/date-utilis";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay]);


export default {
  name: "Landscape",
  components: {
    CardItem,
    Swiper,
    SwiperSlide,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swiperPortraitOptions: {...swiperPortraitOptions},
      swiperDelay: this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed?.length > 0 ? this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed : this.$store.state.Params.find(o => o.Key === 'SliderSpeed')?.Value || 8000,
      minNumbOfDataToBeSlider: 4,
      galleryType: 'cardListLandscape',
      swiper: {},
    };
  },
  mounted() {
    this.windowWidth();
    window.addEventListener("resize", this.windowWidth);
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.fadeSlide(swiper);
    },
    onSlideChange(swiper) {
      this.fadeSlide(swiper);
    },
    windowWidth() {
       if (window.innerWidth >= 1440) {
        this.minNumbOfDataToBeSlider = 3;
      } else if (window.innerWidth >= 992) {
        this.minNumbOfDataToBeSlider = 3;
      } else if (window.innerWidth >= 580) {
        this.minNumbOfDataToBeSlider = 2;
      } else { this.minNumbOfDataToBeSlider = 1; }
    },
    fadeSlide(swiperRef) {
      setTimeout(() => {
        const lastVisibleSlide = swiperRef.visibleSlides?.slice(-1)[0];
        if (lastVisibleSlide) {
          swiperRef.slides.map((slide) =>
            slide.classList.remove("faded-slide")
          );
          lastVisibleSlide.classList.add("faded-slide");
        }
      }, 100);
    },
  },
};
</script>
<style lang="scss">
.swiper-button-prev {
  opacity: 0;
}

.swiper-card-container:hover {
  .swiper-button-next,
  .swiper-button-prev {
    opacity: 1;
  }
}

.swiper-button-next.landscape-arrow-next,
.swiper-button-prev.landscape-arrow-prev {
  @apply text-htz-red hidden;
  opacity: 0;
  transition: all 0.3s;
  padding: 80px 40px;
  top: 80px;
  @screen md{
    @apply block;
    }
  @screen lg{
    top: 100px;
  }
  &:after {
    @apply text-5xl;
  }
  @screen 2xl {
    right: 11%;
  }
  &:hover {
    opacity: 1;

    @screen 2xl {
      right: 10.5%;
    }
  }
}
.cards-landscape{
  .swiper-pagination-bullets-dynamic{
    bottom: 0;
    @screen md{
      @apply hidden;
    }
      .swiper-pagination-bullet{
          width: 11px;
          height: 11px;
          margin: 0 9px;
          border: solid 2px #9a9a9a;
          background-color: #fff;
          transform: scale(1);
      }
      .swiper-pagination-bullet-active-main{
          width: 11px;
          height: 11px;
          margin: 0 9px;
          border: solid 2px #9a9a9a;
          background-color: #9a9a9a;
      }
  }
  .swiper-button-prev.landscape-arrow-prev{
    @screen 2xl{
      left: -65px;
    }
    @screen 3xl{
      left: -100px;
    }
  }
}
</style>
