import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (
      !_ctx.title && _ctx.moduleInfo && _ctx.moduleInfo.Module && _ctx.moduleInfo.Module.ModuleProperties &&
        _ctx.$filters.hasPropertyValue(
          _ctx.moduleProperties,
          _ctx.moduleInfo.Module.ModuleProperties,
          _ctx.moduleInfo.Module //TODO niuje dobro
        )
    )
    ? (_openBlock(), _createBlock("h2", {
        key: 0,
        class: ["headline-3a text-left", _ctx.classList]
      }, _toDisplayString(_ctx.$filters.getPropertyValue(  _ctx.$t,
        _ctx.moduleProperties,
        _ctx.moduleInfo.Module.ModuleProperties,
        _ctx.moduleInfo.Module
      )), 3))
    : (_openBlock(), _createBlock("h2", {
        key: 1,
        class: ["headline-3a text-left", _ctx.classList]
      }, _toDisplayString(_ctx.$t(_ctx.title)), 3))
}