

import {Options, Vue} from "vue-class-component";
import FormaPersonaChild from './FormaPersonaChild.vue'
import {SelfDataMixin} from "@/mixins/self-data.mixin";
import {Prop} from "vue-property-decorator";
import {ModuleInfo} from "@/interfaces";
@Options({
  components: {
    FormaPersonaChild
  },
  name: 'FormaPersona'
})
export default class FormaPersona extends SelfDataMixin {

  //TODO dodati još uvjet || ModulLayoutVue == "HeaderVideo"
  get comp(): boolean {
    const heroBigModuleExist = this.$store.state.PageData.ModuleData.find(m => m.Module.ModuleVariation?.Title.replace(/\s/g, '') === "HeaderBig")
    const heroVideoModuleExist = this.$store.state.PageData.ModuleData.find(m => m.Module.ModuleVariation?.Title.replace(/\s/g, '') === "HeaderVideo")
    return (!!heroBigModuleExist && heroBigModuleExist.Module.ModuleProperties.ModuleHasHomepage === 'True') || !!heroVideoModuleExist
  }


}
