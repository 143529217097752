export function getImagetoShare(){
  let heroCont = document.getElementsByClassName("hero-container");
  if(heroCont.length){
    let picture = heroCont[0].getElementsByTagName('source')[0]?.getAttribute("data-class");
    if(picture === "hero-img-small" || picture == "hero-img-big"){
      return heroCont[0].getElementsByTagName('source')[0].getAttribute("srcset");
    }
    else{
      return false;
    }
  }
  else{
    return false;
  }
}

export function setMetaPicture(url: string | boolean) {
  const meta = document.head.querySelector("[property=\"og:image\"]");
  if (!meta && url && typeof url === "string") {
    const meta = document.createElement("meta");
    meta.setAttribute("property", "og:image");
    meta.setAttribute("content", url);
    document.head.appendChild(meta);
  }
}
