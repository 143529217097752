<template>
  <div
    class="content max-w-screen-md lg:max-w-screen-2xl mx-auto lg:pt-12"
    :class="moduleInfo.Module.CssClass"
  >
    <div
      v-if="loaded"
      class="container max-w-screen-md lg:max-w-screen-xl flex flex-col lg:flex-row justify-between mx-auto my-6 lg:mt-8 lg:px-0"
      :class="{'flex-col-reverse lg:flex-row lg:mt-12':showMap === true}"
    >
      <div
        class="max-w-740 xl:min-w-625"
        :class="{
          'max-w-740':
            moduleInfo.Module.ModuleProperties.ModuleHasLayoutType ===
            '60:40',
          'max-w-auto lg:w-1/2 lg:px-4 xl:pr-0':
            moduleInfo.Module.ModuleProperties.ModuleHasLayoutType ===
            '50:50',
        }"
      >
        <div
          :class="{
            'border-htz-red border-0 border-l-2 py-2 lg:py-4 px-4 lg:pl-10':
              moduleInfo.Module.ModuleProperties.ModuleHasBorder === 'True',
          }"
        >
          <p
            v-if="
              hasPropertyValue(
                'Overtitle',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
            class="overtitle pb-3"
          >
            {{
              getPropertyValue( $t,
                                "Overtitle",
                                moduleInfo.Module.ModuleProperties,
                                firstModuleDataItem
              )
            }}
          </p>
          <p
            v-if="hasPropertyValue('IntroText', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
            class="p-lg leading-1/33 mb-4"
            v-html="getPropertyValue( $t, 'IntroText', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          />
          <p
            v-if="hasPropertyValue('HighlightedText', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
            class="p-base leading-1/66"
            :class="{
              'p-sm': moduleInfo.Module.ModuleProperties.ModuleHasLayoutType === '50:50' && showMap === false,
              'p-base p-base-map': showMap
            }"
            v-html="getPropertyValue( $t, 'HighlightedText', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          />
          <!-- Zakomentirano zbog bug-a #95719 -->
          <!-- :class="{'p-sm': moduleInfo.Module.ModuleProperties.ModuleHasLayoutType === '50:50'}" -->
        </div>

        <div
          class="heading-sizes"
          :class="{
            'lg:pl-20 lg:pr-12':
              moduleInfo.Module.ModuleProperties.ModuleHasIndentText ===
              'True',
          }"
        >
          <p
            v-if="
              hasPropertyValue(
                'Text',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
            class="content-text p-sm text-htz-darkgrey py-5 md:py-10"
            v-html="
              getPropertyValue( $t,
                                'Text',
                                moduleInfo.Module.ModuleProperties,
                                firstModuleDataItem
              )
            "
          />
          <span
            v-if="
              hasPropertyValue(
                'TextReadMore',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
          >
            <span v-if="readMore" />
            <span v-else>...</span>
          </span>
          <p
            v-show="readMore"
            v-if="
              hasPropertyValue(
                'TextReadMore',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
            class="content-text p-sm text-htz-darkgrey leading-8"
            v-html="getPropertyValue($t,'TextReadMore',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          />
          <button
            v-if="
              hasPropertyValue(
                'TextReadMore',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
            class="btn-small-more"
            :class="{ active: readMore }"
            @click="expandText"
          >
            <span v-if="readMore"> {{ $t("loc_read_less") }}</span>
            <span v-else> {{ $t("loc_read_more") }}</span>
          </button>
        </div>
      </div>
      <div
        :class="{
          'show-map' : showMap,
          'sixty-forty lg:px-4': moduleInfo.Module.ModuleProperties.ModuleHasLayoutType === '60:40',
          'fifty-fifty lg:px-4': moduleInfo.Module.ModuleProperties.ModuleHasLayoutType === '50:50',
        }"
      >
        <div
          v-if="showMap"
          class="intro-text-image-map my-8"
        >
          <RegionMap
            :css-class="'mx-auto max-w-495'"
            :selected-region="selectedRegion"
            map-position="IntroTextImg"
          />
        </div>
        <template v-else>
          <vue-picture
            v-if="firstModuleDataItem?.MediaGallery.length"
            :image="firstModuleDataItem?.MediaGallery[0]"
            css-class="intro-img max-h-500 object-contain"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import VuePicture from "../../includes/VuePicture";
import RegionMap from "../../includes/region/RegionMap";
import {selfDataRequestMixin} from "../../../mixins";

export default {
  name: "ContentIntroTextImg",
  components: {
    RegionMap,
    VuePicture,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      galleryType: 'introImg',
      readMore: false,
    };
  },
  computed: {
    selectedRegion() {
      return this.moduleProperties.ModuleRegionToShow ?? ''
    },
    showMap() {
      return this.moduleProperties.ModuleShowMapRegion === 'True'
    }
  },
  methods: {
    expandText: function (e) {
      e.preventDefault();
      this.readMore = !this.readMore;
    },
  },
};
</script>

<style lang="scss" scoped>

/* .content-text::v-deep(ul) {
  @apply list-none m-0 p-0;
}
.content-text::v-deep(ul li) {
  @apply font-livory text-htz-darkgrey text-18 leading-loose pl-8 relative mb-3;
}
.content-text::v-deep(ul li:before) {
  content: "•";
  @apply absolute inline-block left-0;
} */


.intro-text-image-map .regions-map{
    @apply hidden;
      @screen md{
        @apply block;
      }
    .line-height-1-33{
      line-height: 1.33;
    }
  }

.show-map{
  @screen lg{
      @apply w-2/5;

  }
}

.show-map.sixty-forty{
  @screen lg{
      @apply w-2/5;
  }
}


.fifty-fifty,
.show-map.fifty-fifty{
  @screen lg{
    @apply w-1/2;
  }
}


</style>
