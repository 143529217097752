

import {Options, Vue} from "vue-class-component";
import FormaPersonaChild from './FormaPersonaChild.vue'
import {SelfDataMixin} from "@/mixins/self-data.mixin";
import {Prop} from "vue-property-decorator";
import {ModuleInfo} from "@/interfaces";
@Options({
  components: {
    //FormaPersonaChild
  },
  name: 'FormaPersonaHeader'
})
export default class FormaPersonaHeader extends SelfDataMixin {

  @Prop({default: []})
  dropdownClassList: string[] = [];

  teleportMe: boolean = true;
  isActive: boolean = true;
  flag: boolean = false;
  display: String = "desktop"


  mounted() {
    this.teleportMe = this.moduleInfo ? this.moduleInfo.Module.ModuleProperties.ShowInHeader === 'True' : true;
    this.windowWidth();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.windowWidth);


  }
  toggleForm(){
    this.isActive = !this.isActive;
  }
  handleScroll() {
      const forma = document.querySelector<HTMLElement>(".form-personalize");
      const formaBox = document.querySelector<HTMLElement>(".form-personalize-box");
      let documentHeight = document.body.scrollHeight;
      let currentScroll = window.scrollY + window.innerHeight;
      let modifier = 100;
    if(forma){
      const formaTop = forma .offsetHeight;

      if(currentScroll + modifier > documentHeight) {
          this.isActive = false;
          forma.classList.add("hidden");
      }
      if (window.scrollY > formaTop && (currentScroll + modifier < documentHeight)) {
        this.isActive = false;
        forma.classList.remove("hidden");
      }

      else {
        const cccContent = document.getElementById("ccc-content");
        if(this.display != "mobile" && !cccContent){
          this.isActive = true;
        }
      }
    }
  }
  windowWidth() {
    if (window.innerWidth >= 768) {
      this.isActive = true;
      this.display = "desktop";
    } else {
      this.isActive = false;
      this.display = "mobile";
    }
  }
}
