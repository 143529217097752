<template>
  <div
    class="paragraph content"
    :class="moduleInfo.Module.CssClass"
  >
    <div
      v-if="loaded"
      class="flex flex-col"
    >
      <div
        class="container max-w-screen-md lg:max-w-screen-xl flex flex-col lg:flex-row justify-between mx-auto my-4"
        :class="{
          'order-2':
            moduleInfo.Module.ModuleProperties.ModuleHasImageAbove === 'True',
        }"
      >
        <!-- left column -->
        <div class="max-w-740">
          <div
            :class="{
              'border-htz-red border-0 border-l-2 py-2 lg:py-4 px-4':
                moduleInfo.Module.ModuleProperties.ModuleHasBorder === 'True',
            }"
          >
            <p
              v-if="hasPropertyValue('Overtitle',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
              class="overtitle pb-3"
            >
              {{ getPropertyValue( $t,"Overtitle",moduleInfo.Module.ModuleProperties,firstModuleDataItem) }}
            </p>
            <p
              v-if="hasPropertyValue('HighlightedText', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              class="p-lg content-text leading-1/33 mb-4"
              v-html="getPropertyValue( $t, 'HighlightedText', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
            />
            <p
              v-if="hasPropertyValue('IntroText', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              class="p-base content-text leading-1/66"
              v-html="getPropertyValue( $t, 'IntroText', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
            />
          </div>

          <div
            class="heading-sizes"
            :class="{
              'lg:pl-20 lg:pr-12':
                moduleInfo.Module.ModuleProperties.ModuleHasIndentText ===
                'True',
            }"
          >
            <p
              v-if="
                hasPropertyValue(
                  'Text',
                  moduleInfo.Module.ModuleProperties,
                  firstModuleDataItem
                )
              "
              class="content-text p-sm text-htz-darkgrey py-5 md:py-10"
              v-html="
                getPropertyValue( $t,
                  'Text',
                  moduleInfo.Module.ModuleProperties,
                  firstModuleDataItem
                )
              "
            />
            <span
              v-if="
                hasPropertyValue(
                  'TextReadMore',
                  moduleInfo.Module.ModuleProperties,
                  firstModuleDataItem
                )
              "
            >
              <span v-if="readMore" />
              <span v-else>...</span>
            </span>
            <p
              v-show="readMore"
              v-if="hasPropertyValue('TextReadMore',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
              class="p-sm content-text text-htz-darkgrey"
              v-html="getPropertyValue( $t,'TextReadMore',moduleInfo.Module.ModuleProperties,firstModuleDataItem)
              "
            />
            <button
              v-if="
                hasPropertyValue(
                  'TextReadMore',
                  moduleInfo.Module.ModuleProperties,
                  firstModuleDataItem
                )
              "
              class="btn-small-more"
              :class="{ active: readMore }"
              @click="expandText"
            >
              <span v-if="readMore">
                {{ $t("loc_read_less") }}
              </span>
              <span v-else>
                {{ $t("loc_read_more") }}

              </span>
            </button>
          </div>
        </div>

        <!-- right column -->
        <div />
      </div>

      <div
        class="max-w-screen-3xl w-full mx-auto my-4"
        :class="{
          'order-1':
            moduleInfo.Module.ModuleProperties.ModuleHasImageAbove === 'True',
        }"
      >
        <!--v-if="moduleData.Photo"-->
        <div
          v-if="firstModuleDataItem?.MediaGallery.length"
          class="max-w-740 lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl"
        >
          <vue-picture
            v-if="firstModuleDataItem?.MediaGallery[0]?.length"
            :image="firstModuleDataItem.MediaGallery[0]"
            css-class="paragraph-img max-h-500 object-cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VuePicture from "../../includes/VuePicture";
import {selfDataRequestMixin} from "@/mixins";
export default {
  name: "Paragraph",
  components: {
    VuePicture,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      readMore: false,
      galleryType: 'paragraphImage'
    };
  },
  methods: {
    expandText: function (e) {
      e.preventDefault();
      this.readMore = !this.readMore;
    },
  },
};
</script>
