import {mapActions} from "vuex";
import axiosInstance from "@/services/http-common.js";
import {getModuleDataKey, setStoreModule} from "@/store/store.utils.ts";
import {Options, Vue} from "vue-class-component";
import {moduleExcludedLoadMore} from "@/services/load-more.settings";
import { lazyLoad } from "../components/utils/lazyLoadImages";
import {moduleDataForModule} from "@/mixins/shared-mixin-functions";
import {oldPrepareImage, newPrepareImage} from "@/components/utils/prepareImages";
import FILTERS from "@/components/filters/filter";

export const selfDataRequestMixin = {

  data() {
    return {
      hasVideoPoster: false,
      galleryType: 'default',
    }
  },
  beforeCreate() {
    const uuid = this.$store.state[this.moduleInfo.Module.EntityID]?.uuid;
    const logFound = this.$store.state.logs.find(o => o.Type === 'response' && o.RequestUUID === uuid)
    if (logFound) {
      this.$store.state.logs.push({
        ...logFound,
        Type: 'before render',
        Timestamp: new Date(),
        ExecutionTimeMs: new Date().getTime() - new Date(logFound.Timestamp).getTime()
      })
      const timeDiff = new Date().getTime() - new Date(logFound.Timestamp).getTime()
      console.log('moduleId = ' + this.moduleInfo.Module.EntityID + ', beforeCreate Time diff = ' + timeDiff)
    }
    //this.registerStore()
    //this.registerStore()
  },
  mounted() {
    //this.registerStore()
  },
  beforeMount() {
    const uuid = this.$store.state[this.moduleInfo.Module.EntityID]?.uuid;
    const logFound = this.$store.state.logs.find(o => o.Type === 'response' && o.RequestUUID === uuid)
    if (logFound) {
      this.$store.state.logs.push({
        ...logFound,
        Type: 'after render',
        Timestamp: new Date(),
        ExecutionTimeMs: new Date().getTime() - new Date(logFound.Timestamp).getTime()
      })
      const timeDiff = new Date().getTime() - new Date(logFound.Timestamp).getTime()
      console.log('moduleId = ' + this.moduleInfo.Module.EntityID + ' beforeMount Time diff = ' + timeDiff)
    }
    //this.registerStore()
  },

  computed: {
    favoriteList() {
      return this.$store.state.favorites;
    },
    firstMediaGallery() {
      return this.moduleData?.[0]?.MediaGallery || [];
    },
    firstMediaGalleryWithoutDefault() {
      return (this.moduleData?.[0]?.MediaGallery || []).filter(o => o.meta.IsDefault === false) || [];
    },
    allMediaGallery() {
      return (this.moduleData || []).flatMap(o => o.MediaGallery) || [];
    },
    galleryWithoutDefault() {
      return this.allMediaGallery.filter(o => o.IsDefault === false);
    },
    moduleDataLoading() {
      return this.$store.state.moduleDataList[getModuleDataKey(this.moduleInfo)]?.isLoading
    },
    moduleDataError() {
      return this.$store.state.moduleDataList[getModuleDataKey(this.moduleInfo)]?.error
    },
    loaded() {
      return this.storeModule.moduleInfo ?
        (this.storeModule.moduleInfo.Module.Binding.SourceEntityTypeID ? // TODO krivo
        !this.moduleDataLoading && !this.moduleDataError :
        true) :
        false
    },
    isLoading() {
      return this.$store.state[this.moduleInfo.Module.EntityID.toString()].isLoading;
    },
    storeModule() {
      return this.$store.state[this.moduleInfo.Module.EntityID.toString()] ?? {
          galleryType: 'default',
          errorMessage: '',
          moduleInfo: null,
          moduleData: [],
          filters: [],
          childData: [],
          triggerMounted: 0,
          isLoading: false,
          isError: false,
          pagination: {
            CurrentPage: 1,
            LastPage: 1,
            PerPage: 1,
            From: 1,
            To: 1,
            Total: 1,
          },
          paginationActive: false,
        }

    },
    /*loadMoreLimitModule() {
      return this.storeModule.moduleInfo?.Module.Api.RequestBody.ItemPerPage || this.loadMoreLimitData
    },*/
    /*loadMoreLimitData() {
      return this.storeModule.moduleInfo?.Module.Api.RequestBody.DataLimit || this.loadMoreLimitParams
    },*/
    loadMoreLimitParams() {
      return this.storeModule.paginationActive ?
         this.storeModule.pagination.PerPage :
         this.$store.state.Params.find(o => o.Key === 'LoadMoreLimit') ? +this.$store.state.Params.find(o => o.Key === 'LoadMoreLimit').Value : 30
    },
    moduleData() {
      return moduleDataForModule(this.storeModule,this.moduleHasSlider,this.loadMoreLimitParams,this.galleryType,this.hasVideoPoster, this.favorites )
      /*return this.storeModule.moduleData
        .slice(
          0,
          !this.moduleHasSlider ? (this.loadMoreLimitParams + this.storeModule.loadMore) : this.storeModule.moduleData.length
        )
        .map(o => ({
          ...o,
          MediaGallery: PrepareImages(
            o.MediaGallery,
            this.galleryType,
            this.hasVideoPoster
          )
        })
      );*/
    },
    moduleHasSlider() {
      return this.moduleInfo.Module.ModuleProperties.ModuleHasSlider === 'True' &&
        (this.storeModule.moduleData || []).length > 4 &&
        !moduleExcludedLoadMore.includes(this.moduleInfo.Module?.ModuleVariation?.Title.replace(/\s/g, ''))
    },
    firstModuleDataItem() {
      return this.moduleData[0] ? this.moduleData[0] : null
    },
    firstModuleDataItemWithoutDefault() {
      return this.moduleData[0] ?
        {...this.moduleData[0], MediaGallery: (this.moduleData[0]?.MediaGallery || []).filter(o => o.meta.IsDefault === false)} :
        null
    },
    triggerMounted() {
      return this.storeModule.triggerMounted
    },
    hasModuleBinding() {
      return !!this.moduleInfo.Module.EntityTypeID
    },
    moduleProperties() {
      return this.storeModule.moduleInfo.Module.ModuleProperties
    },
    setGalleryTypeFn({galleryType}) {
      this.setGalleryType({galleryType, id: this.moduleInfo.Module.EntityID.toString()})
    },
    setVideoPosterFn({videoPoster}) {
      this.setVideoPoster({videoPoster : videoPoster ?? false, id: this.moduleInfo.Module.EntityID.toString()})
    },
  },
  watch: {
    triggerMounted() {
      this.onReload()
    },
    loaded(loaded){
      if(loaded){
        setTimeout(
          lazyLoad,
          50
        );
      }
    },

    galleryType: [
      {
        handler: 'setGalleryTypeFn',
        immediate: true,
        deep: false,
      },
    ],
    hasVideoPoster: [
      {
        handler: 'setVideoPosterFn',
        immediate: true,
        deep: false,
      },
    ],
  },
  methods: {
    ...mapActions(['makeRequest', 'setModuleInfo','setGalleryType', 'setVideoPoster']),
    tt(val) {
      let temp = val
      if (temp?.startsWith('%') && temp.endsWith('%')) {
        temp = temp.replace(/%/g, '')
      }
      return this.$t(temp)
    },
    hasPropertyValue(property, moduleProperties, firstModuleDataItem) {
      return FILTERS.hasPropertyValue(property, moduleProperties, firstModuleDataItem)
    },
    getPropertyValue($t, property, moduleProperties, firstModuleDataItem) {
      return FILTERS.getPropertyValue($t, property, moduleProperties, firstModuleDataItem)
    },
    getPropValue(property) {
      return this.getPropertyValue(this.$t,property, this.moduleProperties, this.firstModuleDataItem)
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          try {
            return JSON.parse(c.substring(name.length, c.length));
          } catch (e) {
            return c.substring(name.length, c.length);
          }
        }
      }

      return "";
    },
    setCookie(name, value, hours = 8) {
      const d = new Date();
      d.setTime(d.getTime() + (hours*60*60*1000));
      document.cookie = `${name}=${typeof  value === 'string' ? value : JSON.stringify(value)};expires=${d.toUTCString()}`;
    },

    getHref(modProp, modData){
      if(modProp?.UrlHref){
        //ako je user input nešto od sljedećeg returnaj SefUrl
        if(["SEO", "SefUrl"].some(v => modProp.UrlHref.includes(v))) {
          return modData?.SefUrl;
        }
        else{
          if(modProp?.UrlHref?.startsWith('http')){
            this.target= "_blank"
          }
          return this.$filters.getPropertyValue(  this.$t, "UrlHref", modProp, modData);
        }
      }
      else if(modProp?.ModuleButtonUrl){
        return this.$filters.getPropertyValue(  this.$t, "ModuleButtonUrl", modProp, modData);
      }
      else if(modData?.UrlHref){
        return this.$filters.getPropertyValue(  this.$t, "UrlHref", modProp, modData);
      }
      else if(modData?.SefUrl){
        return modData.SefUrl;
      }
      else if(modData?.Link){
        return modData.Link
      }

    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    /*onReload() {
      this.makeRequest(
        {
          moduleInfo: this.moduleInfo,
          galleryType: this.galleryType,
          hasVideoPoster: this.hasVideoPoster
        })
    },*/

    registerStore() {
 /*     this.setModuleInfo({
        moduleInfo: this.moduleInfo,
        query: this.$route.query,
        setModuleInfo: this.galleryType
      })
      if (this.moduleInfo.Module.Api.RequestBody.EntityTypeID) {
        this.makeRequest(
          {
            moduleInfo: this.moduleInfo,
            galleryType: this.galleryType,
            hasVideoPoster: this.hasVideoPoster
          })
      } else {
        this.$store.commit('loadingFinished', {
          id: this.moduleInfo.Module.EntityID.toString()
        })
      }*/
    },

  },
}

export const sefUrlMixin = {
  computed: {
    gotHyperlinksData(){
      return this.$store.state.seoLinks.length > 0;
    }
  },
  methods: {
    getSefUrlFromEntityId(entityId) {
      if(this.gotHyperlinksData){
        return this.$store.state.seoLinks.find(o => {
          return o.IDObjekta === entityId;
        });
      }
    },
  }
}

export const logoHyperlink = {
  mounted() {
    // this.registerStore()
  },
  computed: {
    gotHpHyperlinkData() {
      return this.$store.state.HpHyperLink;
    },
  },
}

export const layoutRowMixin = {

  mounted() {
    console.log('mounted');
    // setStoreModule(this.moduleInfo.Module.EntityID.toString())
    //this.registerChildStore()
  },
  computed: {
    childData() {
      return this.$store.state[this.moduleInfo.Module.EntityID.toString()].moduleInfo.Module.ChildModules || []
    }
  },
  methods: {
    ...mapActions([ 'setModuleInfo']),
    registerChildStore() {
      this.setModuleInfo({
        moduleInfo: this.moduleInfo,
        query: this.$route.query,
        setModuleInfo: this.galleryType
      });
      axiosInstance
        .post(
          this.moduleInfo.Module.ChildModule.Url,
          this. moduleInfo.Module.ChildModule.RequestBody
      ).then(res => {
        res.data.forEach(m => {
          setStoreModule(m.EntityID.toString())
        })
        this.$store.commit('setChildData', {childData: res.data.map(m => ({Module: m})), id: this.moduleInfo.Module.EntityID.toString()})
        this.$store.commit('loadingFinished', {
          id: this.moduleInfo.Module.EntityID.toString()
        })
      })
        .catch((e) => {
          console.error('Error response: ', e)
        })
    },
  },
}
export const menuMixin = {

  computed: {
    gotMenuData() {
      return this.$store.state.Menu;
    },
  },
  methods: {

    filterMenu(menuType) {
      return this.gotMenuData.find(obj => obj.MenuType == menuType)?.Children || [];
    },
    getNumbOfItemsToShow() {
      const propValue = this.gotMenuData.find(obj => obj.MenuType == "HEADER_MENU").ItemsToShow;
      const paramValue = this.$store.state.Params.find(o => o.Key === 'LimitOfMenuLinks')?.Value;
      if (propValue){ return propValue; }
      else if(paramValue){ return paramValue; }
      else return 5;
    },
  },
}

export const menuMediaMixin = {
  methods: {
    getMediaItemPath(menuItem, mediaType){
        if(menuItem.Media.length > 0 && menuItem.MediaGalleryToShow == 0){
        setTimeout(() => {
          menuItem.MediaGalleryToShow = newPrepareImage(menuItem.Media, 'menuImage', false);
          }, 500);
      }
      setTimeout(() =>{
        lazyLoad()
      },300)
      return menuItem;
    }
  }
}

export const layoutColMixin = {

  mounted() {
    //this.registerChildStore()
  },
  computed: {
    childData() {
      return this.$store.state[this.moduleInfo?.EntityID?.toString()]?.moduleInfo?.Module?.ChildModules || []
    }
  },
  methods: {
    ...mapActions(['setModuleInfo','makeRequest']),
    registerChildStore() {
      this.setModuleInfo({
        moduleInfo: this.moduleInfo,
        query: this.$route.query,
        id: this.galleryType
      })
      axiosInstance
        .post(
          this.moduleInfo.Module.ChildModule.Url,
          this.moduleInfo.Module.ChildModule.RequestBody
        ).then(res => {
        res.data.forEach(m => {
          setStoreModule(m.EntityID.toString());
          this.setModuleInfo({moduleInfo: {Module: m}, query: this.$route.query})
          if (m.SourceEntityID && m.ModuleVariation?.Title.replace(/\s/g, '') !== 'FormaPersona') {
            this.makeRequest(
              {
                moduleInfo: {Module: m},
              })
          } else {
            this.$store.commit('loadingFinished', {
              id: m.EntityID.toString()
            })
          }
        })
        this.$store.commit('setChildData', {childData: res.data.map((m) =>({Module: m})), id: this.moduleInfo.Module.EntityID.toString()})
      })
        .catch((e) => {
          console.error('Error response: ', e)
        })
    },
    /*getModuleData() {
      this.pageData.ModuleData.forEach(mi => {
        this.setModuleInfo({moduleInfo: mi, query: this.$route.query})
        if (mi.Module.Api.RequestBody.EntityTypeID && mi.Module.ModulLayoutVue !== 'FormaPersona') {
          this.makeRequest(
            {
              moduleInfo: mi,
            })
        } else {
          this.$store.commit('loadingFinished', {
            id: mi.Module.EntityID.toString()
          })
        }
      })
    }*/
  },
}



@Options({
  methods: {
    ...mapActions(['makeRequest', 'setModuleInfo']),
  }
})
export class SelfData extends Vue {

  mounted() {
    this.registerStore()
  }

  get moduleData() {
    return this.$store.state[this.moduleInfo.Module.EntityID.toString()].moduleData || []
  }

  registerStore() {
    this.setModuleInfo({
      moduleInfo: this.moduleInfo,
      query: this.$route.query,
      setModuleInfo: this.galleryType
    })
    this.makeRequest({moduleInfo: this.moduleInfo, galleryType: this.galleryType})
  }
}
