import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDatepicker = _resolveComponent("AppDatepicker")

  return (_openBlock(), _createBlock(_component_AppDatepicker, {
    "filter-data": _ctx.filterData,
    type: _ctx.filterData.type,
    "class-list": _ctx.classList,
    class: "",
    onOnChangeSelected: _ctx.onChangeSelected
  }, null, 8, ["filter-data", "type", "class-list", "onOnChangeSelected"]))
}