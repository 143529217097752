<template>
  <VirtualLink
    :href="moduleData.meta.MediaPath"
    class-list="h-full block relative overflow-hidden bg-htz-lightgrey transition-all duration-300 hover:bg-htz-midgrey"
  >
    <figure class="w-full h-full ">
      <img
        v-if="moduleProperties.ModuleHasDefaultDocumentImage === 'True'"
        class="document-preview-image w-full h-full object-cover transition-all duration-300"
        :src="defaultDocImg"
        alt="Document Mosaic Preview Image"
        @error="imageLoadError"
      >
      <!-- VideoPath svojstvo koristimo ovdje kao preview image dokumenta kao privremeno rjesenje-->
      <img
        v-else-if="moduleData.meta.VideoPath.length > 0"
        class="document-preview-image w-full h-full object-cover transition-all duration-300"
        :src="moduleData.meta.VideoPath"
        alt="Document Mosaic Preview Image"
        @error="imageLoadError"
      >
      <div 
        v-else
        class="flex justify-center items-center w-full h-full" >
        <i class="icon icon-file-ico mx-1 text-48 mb-10" />
      </div>

      <figcaption class>
        <h3 class="headline-3b absolute left-0 bottom-0 top-auto my-2 mx-3" >
          <template v-if="!moduleData.meta.MediaAltText">
            {{$filters.extractDocumentTitle(moduleData.meta.MediaPath)}}
          </template>
          <template v-else>
            {{moduleData.meta.MediaAltText }}
          </template>
        </h3>
      </figcaption>
    </figure>
  </VirtualLink>
  <!-- <JsonLdManager
    :entity-id="entityId"
    :entity="moduleData"
  /> -->
</template>

<script>
 import VuePicture from "./VuePicture";
/*import JsonLdManager from "@/components/utils/json-ld-manager"; */

export default {
    name: "DocumentMosaicItem",
    components: {
        /* JsonLdManager */
    },
    props: {
        moduleData: {
            type: Object,
            default: () => ({}),
        },
        moduleProperties: {
            type: Object,
            default: () => ({}),
        },
        moduleType: {
          type: String,
          default: ''
        },
        entityId: {
          type: Number,
          required: true
        }
    },
    data() {
      return {
        defaultDocImg: ""

      }
    },
    mounted() {
      this.getdefaultDocImg()
    },
    methods: {
      getdefaultDocImg(){
        this.defaultDocImg = '/cmsmedia/EditUtils/Cards/default-document-image.png';
      },
      imageLoadError(){
        this.defaultDocImg =  '/dist/img/default-document-image.png';
      }
    },

};
</script>


<style scoped>
.document-preview-image:hover{
  filter: brightness(0.7);
}
</style>