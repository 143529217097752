
<template v-if="menuItem && gotMenuGallery">
  <VirtualLink
    v-if="menuItem.SefUrl || menuItem.CustomUrl"
    :href="menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl"
    :class-list="anchorClass"
    :target="menuItem.Target ? '_' + $filters.lowercase(menuItem.Target) : ''"
  >
    <Icon
      :icon-name="icon"
      :icon-class="'icon '+icon+' text-htz-steel hover:text-htz-red text-40'"
      :span-class="'text-40'"
    />
  </VirtualLink>
</template>

<script>
import { menuMediaMixin } from "@/mixins";
import Icon from "./Icon.vue";
import { computed } from '@vue/runtime-core';

export default {
    components: { Icon },
    mixins: [menuMediaMixin],
    props: {
        menuItem: {
            type: Object,
            default: () => ({}),
        },
        anchorClass: {
            type: String,
            default: () => (""),
        }
    },
    data() {
      return {
        icon: ''
      }
    },
    computed(){
      
    },
    mounted() {
      this.icon = this.menuItem.Name.replace(/<[^>]*>?/gm, '');
    }
    

};
</script>
