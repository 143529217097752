
<template>


  <!-- TODO: hendlati clear msgs -->
  <div
    v-if="errorMessage"
    class="login-msg login-msg--error"
  >
    <p>{{ $t(errorMessage) }} </p>
    <button @click="dismissErrorMessage">
      ×
    </button>
  </div>
  <div
    v-if="infoMessage"
    class="login-msg login-msg--info"
  >
    <p>{{ $t(infoMessage) }} </p>
    <button @click="dismissInfoMessage">
      ×
    </button>
  </div>
  <div v-if="!onlyButton">
    <AppInput
      v-model="email"
      :input-type="'email'"
      :placeholder="$t('loc_login_email')"
      :class-list="['login-input border-b-2 border-htz-midgrey bg-transparent text-htz-steel text-16 font-livory-bold w-full pb-1']"
      :show-icon="false"
      :value="''"
    />
    <AppInput
      v-model="password"
      :placeholder="$t('loc_login_password')"
      :class-list="['login-input border-b-2 border-htz-midgrey bg-transparent text-htz-steel text-16 font-livory-bold w-full mt-8 pb-1']"
      :show-icon="false"
      :input-type="'password'"
      :value="''"
    />

    <button
      class="btn-blue-border w-full mt-4 mb-2"
      data-cy="loginBtn"
      @click="confirmModal(LOGIN_TYPE.EMAIL)"
    >
      {{ $t('loc_login_login_with_email') }}
    </button>
    <a
      class="link-dark-underline cursor-pointer"
      data-cy="loginBtn"
      @click.prevent="openForgotPassword()"
    >
      {{ $t('loc_login_open_forgot_password') }}
    </a>
  </div>

  <div v-if="onlyButton">
    <button
      class="btn-blue-border w-full justify-start space-x-3 mt-4"
      @click.stop="confirmModal(LOGIN_TYPE.GOOGLE)"
    >
      <img src="/dist/img/google-icon.svg">
      <span>{{ $t('loc_login_login_with_google') }}</span>
    </button>

    <button
      class="btn-blue-border w-full justify-start space-x-2 mt-4"
      @click.stop="confirmModal(LOGIN_TYPE.FACEBOOK)"
    >
      <img src="/dist/img/facebook-icon.svg">
      <span>{{ $t('loc_login_login_with_facebook') }}</span>
    </button>

    <button
      class="btn-blue-border w-full mt-4 mb-2"
      data-cy="loginBtn"
      @click="showInputs"
    >
      {{ $t('loc_login_login_with_email') }}
    </button>
  </div>


  <a
    href="#"
    class="link-dark-underline mt-6"
    @modalClosed="closeModal"
  >
    {{ $t('loc_login_sign_in_later') }}
  </a>
</template>

<script>

import {LoginStateEnum} from "@/interfaces/login/login-state-enum";
import {mapActions, mapGetters} from "vuex";
import AppInput from "@/libs/ui/lib/forms/Input/AppInput.vue"

export default {
  name: "LoginForm",
  components: {
    AppInput
  },
  data() {
    return {
      email: '',
      password: '',
      onlyButton: true,
      LOGIN_TYPE: this.$constants.LOGIN_TYPE,

    }
  },
  computed: {
    ...mapGetters({
      errorMessage: 'LoggedInUserModule/errorMessage',
      infoMessage: 'LoggedInUserModule/infoMessage',
    }),
  },
  methods: {
    ...mapActions({
      closeAuthModal: 'LoggedInUserModule/closeAuthModal',
      loginUser: 'LoggedInUserModule/loginUser',
    }),
    dismissInfoMessage() {
      this.$store.commit('LoggedInUserModule/setInfoMessage', '');
    },
    dismissErrorMessage() {
      this.$store.commit('LoggedInUserModule/setErrorMessage', '')
    },
    showInputs() {
      this.onlyButton = false
    },
    openForgotPassword() {
      this.$store.state.LoggedInUserModule.loginState = LoginStateEnum.FORGOT_PASSWORD
    },
    closeModal() {
      this.closeAuthModal();
    },
    confirmModal(loginType) {
      if (loginType !== this.LOGIN_TYPE.EMAIL || (this.email && this.password)) {
        this.loginUser( {
          loginType: loginType,
          email: this.email,
          password: this.password
        })
      } else {
        this.$store.state.LoggedInUserModule.errorMessage = 'loc_login_failed'
      }

    },
  },
};
</script>

<style lang="scss" scoped>



</style>
