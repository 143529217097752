<template>
  <div 
    v-if="moduleInfo.length > 0" 
    class="flex flex-wrap justify-between w-full items-center">
    <p
      v-if="$t('loc_footer_logos')"
      class="footer-extra-text p-xs lg:w-12/12 flex flex-wrap text-htz-grey pb-5">
      <span v-html="$t('loc_footer_mobile_app_text').replace(/\./g, '.<br>')" />
    </p>
    <div
      class="flex flex-wrap items-end justify-center md:justify-end w-full md:w-auto gap-3 md:gap-10 min-w-[50%] lg:justify-start text-left pb-5 lg:pb-10 lg:pt-4 space-y-4 md:space-y-0 lg:pr-16"
    >
      <MenuImagesLink
        v-for="appLogo in moduleInfo"
        :key="appLogo.EntityID"
        :menu-item="appLogo"
        :class-menu="'relative flex items-center w-auto h-20 px-4 lg:px-0 h-auto'"
        :media-type="'default'"
        :img-class="'footer-mobile-app-image'"
      />
    </div>
  </div>
</template>
  
  <script>
  import { menuMediaMixin } from '@/mixins';
  import MenuImagesLink from "../MenuImagesLink";
  
  export default {
    components: {MenuImagesLink},
    mixins: [menuMediaMixin],
    props: {
      moduleInfo: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        footerText: ''
      }
    },
    mounted() {
      this.footerText = this.$t("loc_footer_mobile_app_text").replace(/\./g, '.<br>'); //add new line after dot BUG #96229
    },
  };
  </script>
  