<template>

  <!-- TODO: hendlati clear msgs -->
  <div
    v-if="infoMessage"
    class="login-msg login-msg--info"
  >
    <p> {{ $t(infoMessage) }} </p>
    <button @click="dismissInfoMessage">
      ×
    </button>
  </div>

  <AppInput
    v-model="displayName"
    :input-type="'text'"
    :placeholder="$t('loc_login_display_name')"
    :class-list="['login-input border-b-2 border-htz-midgrey bg-transparent text-htz-steel text-16 font-livory-bold w-full pb-1']"
    :show-icon="false"
    :value="''"
  />
  <div class="filter-dropdown-container">
    <AppDropDown
      v-if="eVisitorList.length"
      :select-list="eVisitorList"
      :selected-item="selectedEVisitor"
      :placeholder="$t('loc_dropdownlist_choose')"
      :property="entityEVisitorCountry"
      :class-list="['auth-dropdown']"
      :select-all="true"
      :select-all-string="'loc_login_dashboard_remove_select'"
      
      @onChangeSelected="onEVisitorChange"
    />
  </div>
  <div class="filter-dropdown-container">
    <AppDropDown
      v-if="personaList.length"
      :select-list="personaList"
      :selected-item="selectedPersona"
      :placeholder="$t('loc_dropdownlist_choose')"
      :property="entityPersona"
      :class-list="['auth-dropdown']"
      :select-all="true"
      :select-all-string="'loc_login_dashboard_remove_select'"
      :multiple-select="true"
      @onChangeSelected="onPersonaChange"
    />
  </div>
  <div class="filter-dropdown-container">
    <AppDropDown
      v-if="productList.length"
      :select-list="productList"
      :selected-item="selectedProduct"
      :placeholder="$t('loc_dropdownlist_choose')"
      :property="entityProduct"
      :class-list="['auth-dropdown']"
      :select-all="true"
      :select-all-string="'loc_login_dashboard_remove_select'"
      :multiple-select="true"
      @onChangeSelected="onProductChange"
    />
  </div>
  <div class="text-center mt-6 lg:mt-20">
    <button
      class="btn-blue-border w-full mb-2"
      @click="confirmChanges()"
    >
      {{ $t('loc_login_button_save_changes') }}
    </button>
  </div>
</template>

<script>

import {LoginStateEnum} from "@/interfaces/login/login-state-enum";
import {mapGetters} from "vuex";
import AppInput from "@/libs/ui/lib/forms/Input/AppInput.vue"
import AppDropDown from "@/libs/ui/lib/forms/select/AppDropDown";

export default {
  name: "DashBoardForm",
  components: {
    AppDropDown,
    AppInput,
  },
  emits: {
    'close-modal': null,
    'confirm-modal': null
  },
  data() {
    return {
      displayName: '',
      eVisitorId: '',
      persona: '',
      product: '',
      LOGIN_TYPE: this.$constants.LOGIN_TYPE,
      entityEVisitorCountry: 'eVisitorCountry',
      entityPersona: 'Persona',
      entityProduct: 'Product'
    }
  },
  computed: {
    ...mapGetters({
      errorMessage: 'LoggedInUserModule/errorMessage',
      infoMessage: 'LoggedInUserModule/infoMessage'
    }),
    modalShown() {
      return this.$store.state.LoggedInUserModule.loginState === LoginStateEnum.DASHBOARD
    },
    authState() {
      return this.$store.state.LoggedInUserModule
    },
    dashboardDTO() {
      //console.log(this.displayName);
      return {
        ...(this.displayName && {DisplayName: this.displayName}),
        ...(this.eVisitorId && {UserCountry : this.eVisitorId}),
        ...(this.eVisitorId && {UserCountryName: this.eVisitorList.find(o => o.value.toString() == this.eVisitorId.toString()).name}),
        ...(this.persona && {Persona : this.persona}),
        ...(this.product && {Product : this.product})
      }
    },
    selectedEVisitor() {
      return this.authState.userCountry ? {
        value: +this.authState.userCountry,
        property: this.entityEVisitorCountry
      }: null
    },
    selectedPersona() {
      return this.authState.persona.split('#').length > 1 ? {
        value: this.authState.persona.toString(),
        property: this.entityPersona
      }
      : this.authState.persona ? {
        value: +this.authState.persona,
        property: this.entityPersona
      }  : null
    },
    selectedProduct() {
      return this.authState.product.split('#').length > 1 ? {
        value: this.authState.product.toString(),
        property: this.entityProduct
      }
      : this.authState.product ? {
        value: +this.authState.product,
        property: this.entityProduct
      } : null
    },
    eVisitorList() {
      const temp = (Object.values(this.$store.state?.entitiesStore?.[this.entityEVisitorCountry]?.entities ?? {})).map(o => ({
        value: o.EntityID,
        name: o.Title,
        property: this.entityEVisitorCountry
      }))
        return [...temp].sort((a,b)=> a.name.localeCompare(b.name)) ?? []
    },
    personaList() {
      return (Object.values(this.$store.state?.entitiesStore?.[this.entityPersona]?.entities ?? {})).map(o => ({
        value: o.EntityID,
        name: o.Title,
        property: this.entityPersona
      })) ?? []
    },
    productList() {
      return (Object.values(this.$store.state?.entitiesStore?.[this.entityProduct]?.entities ?? {})).map(o => ({
        value: o.EntityID,
        name: o.Title,
        property: this.entityProduct
      })) ?? []
    },
  },
  mounted() {
    this.dismissInfoMessage()
    this.getUserDataFromStore()
    this.getEVisitorCountry()
    this.getPersona()
    this.getProduct()
  },
  methods: {
    onEVisitorChange(val) {
      this.eVisitorId = val?.value?.toString() ?? ''
    },
    onPersonaChange(val) {
      const valueExists = this.persona.length > 0 ? this.persona.toString().split('#').some(v => v === val.value?.toString()) : false
      if(valueExists) {
        let valuePosition = this.persona.toString().split('#').findIndex(v => v === val.value?.toString())
        this.persona = valuePosition === 0 ? this.persona.toString().replace(new RegExp(val.value.toString() + '\\#?', 'g'), '') : this.persona.toString().replace('#' + val.value, '')
      } else {
        if(this.persona === '') {
          this.persona = val?.value?.toString() ?? ''
        } else {
          this.persona = this.persona + '#' + val?.value?.toString()
        }
      }
      if(val.value === null || !val.value) {
        this.persona = ''
      }
    },
    onProductChange(val) {
      // this.product = val?.value?.toString() ?? ''
      // this.product = val?.value?.toString() ?? ''
      const valueExists = this.product.length > 0 ? this.product.toString().split('#').some(v => v === val.value?.toString()) : false
      if(valueExists) {
        let valuePosition = this.product.toString().split('#').findIndex(v => v === val.value?.toString())
        this.product = valuePosition === 0 ? this.product.toString().replace(new RegExp(val.value.toString() + '\\#?', 'g'), '') : this.product.toString().replace('#' + val.value, '')
      } else {
        if(this.product === '') {
          this.product = val?.value?.toString() ?? ''
        } else {
          this.product = this.product + '#' + val?.value?.toString()
        }
      }
      if(val.value === null || !val.value) {
        this.product = ''
      }
    },
    getEVisitorCountry() {
      this.$store.dispatch('initDropDownStore', {entityName: this.entityEVisitorCountry,sort: [{Field: 'Title'}]})
    },
    getPersona() {
      this.$store.dispatch('initDropDownStore', {entityName: this.entityPersona})
    },
    getProduct() {
      this.$store.dispatch('initDropDownStore', {entityName: this.entityProduct})
    },
    getUserDataFromStore() {
      this.displayName = this.authState.displayName
      this.eVisitorId = this.authState.userCountry
      this.persona = this.authState.persona
      this.product = this.authState.product
    },
    confirmChanges() {
      this.registrationFormErrors = "";
      this.$store.dispatch('LoggedInUserModule/dashboardSave',this.dashboardDTO)
    },
    setPrivacyChecked(val) {
      this.privacyChecked = val
    },
    dismissInfoMessage() {
      this.$store.commit('LoggedInUserModule/setInfoMessage', '');
    },
    closeModal() {
      this.$store.commit('LoggedInUserModule/quitRegistration');
      this.$store.commit('LoggedInUserModule/setInfoMessage', '');
      this.$store.commit('LoggedInUserModule/setErrorMessage', '')
    },
    dismissFormErrors() {
      this.registrationFormErrors = "";
    },
    dismissErrorMessage() {
      this.$store.commit('LoggedInUserModule/setErrorMessage', '')
    },
/*    confirmModal(loginType) {
      this.registrationFormErrors = "";
      if (!this.privacyChecked) {
        this.registrationFormErrors = "loc_login_privacy_missing";
        return;
      }
      this.$store.dispatch('LoggedInUserModule/dashboardSendStart', {
        loginType: loginType,
        displayName: this.displayName,
        email: this.email,
        password: this.password
      })

    },*/
  },
};
</script>

<style lang="scss" scoped>

</style>
