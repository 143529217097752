<template>
<!--  <LoginForm />-->
<!--  <ForgotPassword />-->
<!--  <RegistrationForm />-->
  <MergeWithMailForm />
</template>

<script>
import MergeWithMailForm from "@/components/includes/login/MergeWithMailForm";

export default {
  name: "FirebaseLoginWrapper",
  components: {
    MergeWithMailForm
  },
};

</script>
