

import {Options, Vue} from "vue-class-component";
import {Emit, Prop} from "vue-property-decorator";

@Options({

  emits: ['update:modelValue']
})
export default class AppInput extends Vue {

  @Prop({default: []})
  classList: string[] = [];

  @Prop({default: 'text', required: false})
  inputType: string = ''

  @Prop({default: ''})
  placeholder: string = ''

  @Prop({default: ''})
  modelValue: string = ''

  @Prop({default: false, required: false})
  showIcon: boolean = false;

  @Emit('update:modelValue')
  onInputChange (val: string) {}

  inputChange(val: any) {
    this.onInputChange(val.target.value);
  }
}
