import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterDropDown = _resolveComponent("FilterDropDown")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_FilterDropDown, {
      class: "form-personalize-dropdown-container",
      "filter-data": _ctx.filterData,
      "select-all": true,
      "multiple-select": true,
      "dropdown-class-list": _ctx.dropdownClassList,
      onOnSelectedValues: _ctx.onDropDownSelect
    }, null, 8, ["filter-data", "dropdown-class-list", "onOnSelectedValues"]),
    (_ctx.filterOnCLick)
      ? (_openBlock(), _createBlock("a", {
          key: 0,
          class: "f-search-btn btn-darkblue",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setDataAndNavigate()))
        }, _toDisplayString(_ctx.$filters.getPropertyValue( _ctx.$t, 'ButtonName',_ctx.formaPersonaModuleProperties,_ctx.moduleData)), 1))
      : _createCommentVNode("", true)
  ]))
}