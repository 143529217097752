import firebase from 'firebase/app';
import {CrudUserFirebase, EntityDtoAPI} from '@/interfaces/entity-api/entities-response-dto';
import { EntityTypeDto } from '@/interfaces/entity-api/entity-type-dto';
import { LoggedInUser } from '@/interfaces/login/logged-in-user';
import { LoginStateEnum } from "@/interfaces/login/login-state-enum";
import {AxiosResponse} from "axios";
import axiosInstance from "@/services/http-common";

export const WEB_USER_ENTITY_TYPE_NAME = "WebUsers";
export const USER_UID_PROPERTY_NAME = "UserUid";

/**
 * Creates LoggedInUser (ui user) from firebase user.
 * @param firebaseUser firebase user
 * @return created ui user
 */
export async function buildLoggedInUserFromFirebaseUser(firebaseUser: firebase.User): Promise<LoggedInUser> {
  return {
    loginState: LoginStateEnum.NO_STATE,
    displayName: firebaseUser.displayName ?? undefined,
    email: firebaseUser.email ?? undefined,
    modalOpened: false,
    uid: firebaseUser.uid,
    token: await firebaseUser.getIdToken(),
  };
}

/**
 * compare entity in our db with firebase user
 * @param {EntityDtoAPI} crud user in our db
 * @param {firebase.User} user user in firebase
 * @returns {boolean} boolean
 */
export function compareProps(crud: EntityDtoAPI, user: firebase.User): boolean {
  return crud.UserUid === user.uid // && user.email === crud.Caption
}
export function extractUrlFromFavorite(data: any): string {
 return  data.SefUrl || data.ExternalLink ||data.Seo?.SefUrl ||data.SEO?.SefUrl || ''
}
export async function postWebUser(user: firebase.User, state?: LoggedInUser, extra?: Partial<EntityDtoAPI>, favorites?: any[]): Promise<AxiosResponse<EntityDtoAPI>> {
  return axiosInstance.post<EntityDtoAPI, AxiosResponse<EntityDtoAPI>>( "/api/webuser",
    {
      UserUid: user.uid,
      Email: user.email,
      DisplayName: extra?.DisplayName ?? user.displayName,
      Token: await user.getIdToken(),
      Product: extra?.Product,
      Persona: extra?.Persona,
      UserCountry: extra?.UserCountry,
      UserCountryName: extra?.UserCountryName,
      FavoriteEntities: favorites ? JSON.stringify(favorites): undefined,
      FavoriteCount: favorites?.length,
      FavoriteUrls: favorites? favorites.map(o => extractUrlFromFavorite(o)).join('#')  : undefined

    }
  )
}
/**
 * create body for crud api
 * @param {EntityTypeDto | undefined} webUserEntityType entity from store
 * @param {firebase.User} firebaseLoggedInUser user from firebase
 * @returns {EntityDtoCRUD} body ready for crud
 */
/*export function buildDefaultWebUserEntity(webUserEntityType: EntityTypeDto, firebaseLoggedInUser: Pick<CrudUserFirebase, 'uid' | 'Product' | 'Persona' | 'email' | 'displayName' | 'DisplayName' | 'UserCountry' | 'UserCountryName' | 'FavoriteEntities' | 'FavoriteCount' |'FavoriteUrls'>, active = true): EntityDtoCRUD {
  return buildCrudEntity(
   webUserEntityType?.EntityTypeID,
   WEB_USER_ENTITY_TYPE_NAME,
   {
     UserUid:{value:firebaseLoggedInUser.uid ?? '', language: 'svi' },
     Email: {value:firebaseLoggedInUser.email ?? '', language: 'svi' },
     DisplayName:  {value:firebaseLoggedInUser?.displayName ?? firebaseLoggedInUser?.DisplayName ?? '', language: 'svi' },
     ...(firebaseLoggedInUser.UserCountry && {UserCountry: {value: firebaseLoggedInUser.UserCountry?.toString() ?? '', language: 'svi'}}),
     ...(firebaseLoggedInUser.UserCountryName && {UserCountryName: {value: firebaseLoggedInUser.UserCountryName?.toString() ?? '', language: 'svi'}}),
     ...(firebaseLoggedInUser.Persona && {Persona: {value: firebaseLoggedInUser.Persona?.toString() ?? '', language: 'svi'}}),
     ...(firebaseLoggedInUser.Product && {Product: {value: firebaseLoggedInUser.Product?.toString() ?? '', language: 'svi'}}),
     ...(firebaseLoggedInUser.FavoriteEntities && {FavoriteEntities: {value: JSON.stringify(firebaseLoggedInUser.FavoriteEntities) ?? '', language: 'svi'}}),
     ...(firebaseLoggedInUser.FavoriteEntities && {FavoriteCount: {value: firebaseLoggedInUser.FavoriteEntities.length ?? '', language: 'svi'}}),
     ...(firebaseLoggedInUser.FavoriteEntities && {FavoriteUrls: {value: firebaseLoggedInUser.FavoriteEntities?.map(o => extractUrlFromFavorite(o)).join('#') ?? '', language: 'svi'}}),
   },
   (firebaseLoggedInUser.displayName ?? firebaseLoggedInUser?.DisplayName ?? "no displayName") + new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear()
   )
/!*  return {
    IdObjekta: 0,
    Redoslijed: 0,
    IdTipaObjekta: webUserEntityType?.EntityTypeID,
    NazivTipaObjekta: WEB_USER_ENTITY_TYPE_NAME,
    ParentId: 0,
    InternaOznaka: firebaseLoggedInUser.email ? firebaseLoggedInUser.email : "no email",
    Korisnik: "VS",
    Aktivan: active,
    Svojstva: [
      {
        //IDSvojstvaObjekta: 3554,
        NazivSvojstvaObjekta: "UserUid", // TODO - konstanta
        VrijednostSvojstvaId: 0,
        Vrijednost: firebaseLoggedInUser.uid,
        IDJezika: "svi",
        time_stamp: new Date().toISOString()
      },
      {
        //IDSvojstvaObjekta: 164,
        NazivSvojstvaObjekta: "Email", // TODO - konstanta
        VrijednostSvojstvaId: 0,
        Vrijednost: firebaseLoggedInUser.email ? firebaseLoggedInUser.email : "",
        IDJezika: "svi",
        time_stamp: new Date().toISOString()
      },
      {
        //IDSvojstvaObjekta: 3553,
        NazivSvojstvaObjekta: "DisplayName", // TODO - konstanta
        VrijednostSvojstvaId: 0,
        Vrijednost: firebaseLoggedInUser?.displayName ? firebaseLoggedInUser.displayName : "asd",
        IDJezika: "svi",
        time_stamp: new Date().toISOString()
      }
    ]
  };*!/
}*/

/**
 * Returns entity type for webUser
 * @returns Promise with EntityTypeDto or undefined (if no entity type found)
 *
 */
/*export function getWebUserEntityType():EntityTypeDto {

  return axiosInstance.get<EntityTypeDto[]>(API_CRUD_URL + "/entity/types", {
    auth: {
      username: 'Inf_Tvrtka20',
      password: 'Inf_Tvrtka20'
    }
  }).then(response => {
    const allEntityTypes = response.data;
    const webUserEntityType = _.find(allEntityTypes, entityType => {
      return _.trim(_.toLower(entityType.Naziv)) === _.trim(_.toLower(WEB_USER_ENTITY_TYPE_NAME));
    });

    if (webUserEntityType) {
      return webUserEntityType;
    } else {
      return undefined;
    }
  });
}*/
/**
 * Returns entity that has same id as firebase user or undefined if no entity found.
 * @param webUserEntityType entity type for web users
 * @param firebaseLoggedInUser firebase user
 * @returns Promise with Entity or undefined (if no entity found)
 *
 */
/*export function getEntityForFirebaseUser(
  webUserEntityType: EntityTypeDto | undefined,
  firebaseLoggedInUser: firebase.User): Promise<EntityDto | undefined> {

    // TODO - this should be GET, but GET can't have body
  return axiosInstance.post<EntitiesResponseDto, AxiosResponse<EntitiesResponseDtoCRUD>>( "https://htz-cdn-com.p4c-htz/api/entities/simple",
    {
      nazivTipaObjekta: webUserEntityType?.EntityTypeID,
      EntityTypeID: webUserEntityType?.EntityName,
      WhereStatement: "(Email = '" + firebaseLoggedInUser.email + "')"
    }
  )
  .then(response => {
    const allWebUsers = response.data.entity;
    const existingLoggedInWebUser: EntityDto | undefined = find(allWebUsers, webUser => {
      if (!webUser) {
        return false;
      }

      const propertyToCheck = find(webUser.Svojstva, property => property.NazivSvojstvaObjekta === USER_UID_PROPERTY_NAME);
      return propertyToCheck?.Vrijednost === firebaseLoggedInUser.uid;
    });

    return existingLoggedInWebUser;
  });
}*/
/**
 * Creates a new webUser entity if sent in entity is not defined. If webUserEntity is defined then it just returns that entity.
 * @param webUserEntityType entity type for web users
 * @param firebaseLoggedInUser firebase user
 * @param webUserEntity existing webUser entity or undefined if no existing user
 * @returns Promise with Entity
 */
/*export function createWebUserEntityIfNeeded(
    webUserEntityType: EntityTypeDto | undefined,
    firebaseLoggedInUser: firebase.User,
    webUserEntity: EntityDto | undefined): Promise<EntityDto> {
  if (!webUserEntity) {
    const userToCreate: EntityDto = buildDefaultWebUserEntity(webUserEntityType, firebaseLoggedInUser);
    return axiosInstance.post<EntityDto>(API_CRUD_URL + "/entity", userToCreate, {
      auth: {
        username: 'Inf_Tvrtka20',
        password: 'Inf_Tvrtka20'
      }
    }).then(response => {
      return response.data;
    });
  } else {
    return new Promise((resolve, reject) => resolve(webUserEntity));
  }
}*/
/**
 * Updates webUser entity properties. If updateExistingEntity is true - function will modify webUserEntity, otherwiser webUserEntity is unmodified.
 * @param webUserEntity existing webUser entity
 * @param firebaseLoggedInUser firebase user
 * @param updateExistingEntity if true, modifies webUserEntity
 * @returns Promise with Entity
 */
/*export function updateWebUserEntityProperties(
  webUserEntity: EntityDto,
  firebaseUser: firebase.User,
  updateExistingEntity: Boolean): Promise<EntityDto> {
    let updatedEntity: EntityDto;
    if (updateExistingEntity) {
      updatedEntity = webUserEntity;
    }
    else {
      updatedEntity = cloneDeep(webUserEntity);
    }

    let hasChanges: Boolean = false;

    // Update properties here - begin
    const providerDataWithDisplayName = find(firebaseUser.providerData, data => !!data?.displayName);
    if (!!providerDataWithDisplayName) {
      hasChanges = hasChanges || updateWebUserEntitySingleProperty(updatedEntity, "DisplayName", providerDataWithDisplayName.displayName);
    }
    // Update properties here - end

    if (hasChanges) {
      // Then make update request
      return axiosInstance.put<EntityDto>(API_CRUD_URL + "/entity/" + updatedEntity.IdObjekta, updatedEntity, {
        auth: {
          username: 'Inf_Tvrtka20',
          password: 'Inf_Tvrtka20'
        }
      }).then(response => response.data)
    } else {
      return new Promise((resolve, reject) => { resolve(webUserEntity) });
    }
}*/
/**
 * Updates LoggedInUser (ui user) from entity properties.
 * @param loggedInUser UI user
 * @param webUserEntity existing webUser entity
 * @return void
 */
/*export function updateLoggedInUserWithEntityProps(
  loggedInUser: LoggedInUser,
  webUserEntity: EntityDto) {
    const entityEmail = find(webUserEntity.Svojstva, property => property.NazivSvojstvaObjekta === "Email");
    if (entityEmail) {
      loggedInUser.email = entityEmail.Vrijednost;
    }
    const entityDisplayName = find(webUserEntity.Svojstva, property => property.NazivSvojstvaObjekta === "DisplayName");
    if (entityDisplayName) {
      loggedInUser.displayName = entityDisplayName.Vrijednost;
    }
}*/
/*function updateWebUserEntitySingleProperty(webUser: EntityDto, propertyName: string, value: any): Boolean {
  const foundProperty = find(webUser.Svojstva, property => property.NazivSvojstvaObjekta === propertyName);
  if (!!foundProperty) {
    if (foundProperty.Vrijednost !== value) {
      foundProperty.Vrijednost = value;
      return true;
    }
  }
  return false;
}*/
