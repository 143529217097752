import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {
  ComponentState,
  ModuleData,
  ModuleFilter,
  ModuleInfo,
  ModulePicturesInterface,
  ModulePropertiesInterface
} from "@/interfaces";

import {moduleExcludedLoadMore} from "@/services/load-more.settings";
import {newPrepareImage} from "@/components/utils/prepareImages";
@Options({})
export class DefaultMixin extends Vue {

  @Prop({required: true})
  moduleInfo!: ModuleInfo;

  galleryType: keyof  ModulePicturesInterface = 'default';
  hasVideoPoster = false;
  get storeModule(): ComponentState {
    return this.$store.state[this.moduleInfo.Module.EntityID.toString()] ?? {
      galleryType: 'default',
      errorMessage: '',
      moduleInfo: null,
      moduleData: [],
      filters: [],
      childData: [],
      triggerMounted: 0,
      isLoading: false,
      isError: false,
      pagination: {
        CurrentPage: 1,
        LastPage: 1,
        PerPage: 1,
        From: 1,
        To: 1,
        Total: 1,
      },
      paginationActive: false,
    }
  }
  get loaded() {
    return this.componentStore.moduleInfo ?
      (!!this.componentStore.moduleInfo.Module.Binding.SourceName ?
        !this.componentStore.isLoading && !this.componentStore.isError :
        true) :
      false
  }
  get isLoading(): boolean {
    return this.componentStore.isLoading;
  }
  get componentStore(): ComponentState {
    return this.$store.state[this.moduleInfo.Module.EntityID.toString()]
  }
  get moduleData(): ModuleData {
    return (this.storeModule.moduleData || [])
      .slice(
        0,
        !this.moduleHasSlider ? (this.loadMoreLimitParams + this.storeModule.loadMore) : (this.storeModule.moduleData || []).length
      )
      .map(o => ({
          ...o,
          MediaGallery: newPrepareImage(
            o.Media,
            this.galleryType
          )
        })
      );

  }
  get loadMoreLimitParams() {
    return this.$store.state.Params.find(o => o.Key === 'LoadMoreLimit') ? +this.$store.state.Params.find(o => o.Key === 'LoadMoreLimit')!.Value : 30
  }
  get moduleHasSlider() {
    return this.moduleInfo.Module.ModuleProperties.ModuleHasSlider === 'True' &&
      (this.storeModule.moduleData || []).length > 4 &&
      moduleExcludedLoadMore.includes(this.moduleInfo.Module?.ModuleVariation?.Title.replace(/\s/g, ''))
  }
  get firstModuleDataItem() {
    return this.moduleData[0] ? this.moduleData[0] : null
  }
  get triggerMounted(): number {
    return this.componentStore.triggerMounted
  }
  get hasModuleBinding(): boolean {
    return !!this.moduleInfo.Module.Binding.SourceName //!!this.moduleInfo.Module.Api?.RequestBody?.EntityTypeID
  }
  get moduleProperties(): ModulePropertiesInterface {
    return this.componentStore?.moduleInfo ?
      (this.componentStore.moduleInfo as ModuleInfo).Module.ModuleProperties :
      {}
  }
  get moduleFilters(): ModuleFilter[] {
    return this.componentStore?.moduleInfo ?
      (this.componentStore.moduleInfo as ModuleInfo).Module.ModuleFilter ?? [] :
      []
  }
}
