

import Filter from "@/libs/features/lib/Filter/Filter.vue";
import {Options, Vue} from "vue-class-component";
import {SelectItemEmit} from "@/libs/ui/.storybook/interfaces";
import {ComponentState, ModuleInfo, QueryParamsModule} from "@/interfaces";
import {Prop} from "vue-property-decorator";
import LoadingLayout from "@/components/layouts/LodingLayout.vue";
import {Action} from "vuex-class";
import LoadMore from '@/components/layouts/LoadMore.vue'
import {prepareAdditionalFilter, prepareWhereStatement} from "@/components/utils/query-params";
import {getModuleDataKey} from "@/store/store.utils";
@Options({
  name: 'ModuleLayout',
  components: {
    Filter,
    LoadingLayout,
    LoadMore
  },
})
export default class ModuleLayout extends Vue {

  @Prop({required: true})
  moduleInfo!: ModuleInfo

  @Prop({required: true})
  index!: number

  @Action('makeNewRequest')
  makeNewRequest!: ({moduleInfo, galleryType}: {moduleInfo: ModuleInfo, galleryType: string, merge?: boolean}) => void

  showMap = false;

  moduleInfoTemp?: ModuleInfo = undefined
  onShowMap(val: boolean) {
    this.showMap = val
  }

  mounted() {
    this.moduleInfoTemp = this.moduleInfo;
   // console.log("moduleData", this.storeModule.moduleData);

  }
  get formaPersona(): boolean {
    return this.storeModule.moduleInfo?.Module.ModuleVariation.Title === 'Forma Persona'
  }
  get storeModule(): ComponentState {

    return this.$store.state[this.moduleInfo.Module.EntityID.toString()] ?? {
      galleryType: 'default',
      errorMessage: '',
      moduleInfo: null,
      moduleData: [],
      filters: [],
      childData: [],
      triggerMounted: 0,
      isLoading: false,
      isError: false,
      pagination: {
        CurrentPage: 1,
        LastPage: 1,
        PerPage: 1,
        From: 1,
        To: 1,
        Total: 1,
      },
      paginationActive: false,
    }
  }

  modulesListFilterCanBeActive: string[] = [
    'Icons',
    'Landscape',
    'Portrait',
    'Square',
    'TableHorizontal',
    'MapInline',
    'Mosaic',
  ]
  get loaded() {
    return this.storeModule.moduleInfo ?
      (this.storeModule.moduleInfo.Module.Binding.SourceName ?
        !this.storeModule.isLoading && !this.$store.state[this.moduleInfo.Module.EntityID.toString()].isError :
        true) :
      false
  }
  get moduleHasSlider(): boolean {
    return this.moduleInfo.Module.ModuleProperties.ModuleHasSlider === 'True' && (this.storeModule.moduleData || []).length > 4
  }
  isFilterEnabled(module: ModuleInfo): boolean {
    return this.modulesListFilterCanBeActive.includes(module.Module?.ModuleVariation?.Title.replace(/\s/g, '')) && (!this.moduleHasSlider || this.moduleInfo.Module.ModuleProperties.ModuleShowAsFeed)
  }

  setQueryParams(val: SelectItemEmit[], module: ModuleInfo) {

    if (val.length) {
      let query: QueryParamsModule = {}
      val.forEach(v => {
        if (v.type === 'input') { return}
        if (v.property && v.value) {
          // query[v.property] = {value: v.value!.toString(), type: v.type!};
          query[v.property] =  v.value!.toString();
        }
      })
      this.$router.push({
        //path: this.$route.fullPath,
        params: {savePosition: 'true'},
        query //: {[module.Module.EntityID]: JSON.stringify(query)},

      })
    } else {
      this.$router.push({
        params: {savePosition: 'true'},
        query: {}
      })
    }
  }
  get notPersona(): boolean {
    return this.moduleInfo.Module.ModulLayoutVue !== 'FormaPersona'
  }
  get moduleDataLoading(): boolean {
    return this.storeModule.isLoading
    //return this.$store.state.moduleDataList[getModuleDataKey(moduleInfo)]?.loaded
  }
  get moduleDataError() {
    return this.storeModule.isError
  }
  get isBindingNotApplicable():boolean {
    return (!!this.storeModule.moduleInfo?.Module.ModuleVariation.BindingNotApplicable && !this.storeModule.moduleInfo?.Module.Binding.Source ) ||
      !this.notPersona ||
      this.storeModule.moduleInfo?.Module.ModuleVariation.RequiresChildModules === true
  }
  get isReviewModule():boolean {
    return this.storeModule.moduleInfo?.Module.ModuleVariation.Title === 'Review';
  }
  get isModuleValidBinding(): boolean {
    return !this.storeModule.moduleInfo?.Module.Binding.SourceName
  }
  get isModuleDataNotEmpty(): boolean {
    return (this.storeModule.moduleData?.length ?? 0) > 0
  }
  get hasPositionOnMap(): boolean {
    return (!!(this.storeModule?.moduleData?.some(e => e?.PositionOnMap?.length > 0)));
  }
  get filterActive(): boolean {
    return !!this.storeModule.filters.length;
  }
  get moduleIsValid(): boolean {
    if (this.isBindingNotApplicable) {return true}
    if (this.isModuleValidBinding) {return false}

    if (!this.moduleDataLoading && !this.moduleDataError && this.isModuleDataNotEmpty) {
      return true
    } else {
      return this.filterActive;
    }
  }


  get moduleReady(): boolean {
    return this.$store.state.PageData.ModuleData[this.index] && !!this.$store.state[this.$store.state.PageData.ModuleData[this.index]?.Module.EntityID.toString()]?.moduleInfo ?
      this.moduleIsValid :
      false
  }
  makeFilteredRequest(val: SelectItemEmit[], module: ModuleInfo) {
    if(!this.storeModule.isLoading){
      this.storeModule.filters = val;
      //this.storeModule.loadMore = 0;
      //TODO new pagination
      this.storeModule.pagination.Offset = 0
      /*    if (this.storeModule.moduleInfo!.Module.Api.RequestBody.PageNumber) {
            this.storeModule.moduleInfo!.Module.Api.RequestBody.PageNumber = 1;
          }*/
      this.setQueryParams(val, module)
      this.makeNewRequest({
        moduleInfo: module,
        galleryType: this.storeModule.galleryType
      })
    }

  }
}
