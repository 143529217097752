
import {AppState, PreparedImage, RelatedInterface} from '@/interfaces/store.interfaces';
import { firebaseAuthProvider } from '@/services/firebase';
import {ActionContext, Module} from "vuex";
import { LoggedInUser } from '../../interfaces/login/logged-in-user';
import { LoginStateEnum } from "../../interfaces/login/login-state-enum";
import firebase from 'firebase/app';
import { UIUserLoginData } from '@/interfaces/login/ui-user-login-data';
import CONSTANTS from "@/components/shared/constants";
import {
  buildLoggedInUserFromFirebaseUser,
  postWebUser
} from './loggedInUserModuleHelper';
import {includes} from "lodash";
import {axiosInstance} from "@/services/http-common";
import {AxiosResponse} from "axios";
import {
  DashboardDTO,
  EntitiesResponseDtoAPI,
  EntityDtoAPI
} from "@/interfaces/entity-api/entities-response-dto";
import {cloneDeep, find} from "lodash";
import {newPrepareImage, prepareImages} from "@/components/utils/prepareImages";



// **************************************************************
// FAKE TEST DATA, REMOVE WHEN API CORS IS RESOLVED / START
//var mock = new MockAdapter(axiosInstanceTest)

// Add mocked responses
// mock.onGet("https://dev-edit-crud-service.p4c-htz/entity/types").reply(200, ENTITY_TYPES_DATA);
// mock.onGet("https://dev-edit-crud-service.p4c-htz/entity").reply(200, ALL_ENTITIES_DATA);
// mock.onPost("https://dev-edit-crud-service.p4c-htz/entity").reply((config: any) => {
//  ALL_ENTITIES_DATA.entity.push(JSON.parse(config.data) as EntityDto);
//  return [200, config.data];
//});

// FAKE TEST DATA, REMOVE WHEN API CORS IS RESOLVED / END
// **************************************************************
interface LoginConflictInfo {
  existingSignInMethods: string[];
  targetLinkProverId: string;
}

export const LoggedInUserModule: Module<LoggedInUser, AppState> = {
    namespaced: true,
    state() {
      return {
        loginState: LoginStateEnum.NOT_LOGGED_IN,
        modalOpened: false,
        uid: undefined,
        email: undefined,
        displayName: undefined,
        errorMessage: undefined,
        product: undefined,
        userCountry: undefined,
        persona: undefined,
        infoMessage: undefined,
        editUserId: undefined,
        loginImages: undefined,
        dashboardImages: undefined,
        registerImages: undefined,
      }
    },
    mutations: {
      startLogin(state: LoggedInUser) {
        state.loginState = LoginStateEnum.LOGIN_STARTED;
        state.modalOpened = true;
        state.errorMessage = '';
        state.infoMessage = '';
      },
      startDashboard(state: LoggedInUser) {
        state.loginState = LoginStateEnum.DASHBOARD;
        state.modalOpened = true;
        state.errorMessage = '';
        state.infoMessage = '';
      },
      setLoginImages(state: LoggedInUser, payload: PreparedImage[]) {
        state.loginImages = payload
      },
      setDashboardImages(state: LoggedInUser, payload: PreparedImage[]) {
        state.dashboardImages = payload
      },
      setRegisterImages(state: LoggedInUser, payload: PreparedImage[]) {
        state.registerImages = payload
      },
      closeAuthModalAndClearMessages(state: LoggedInUser) {
        // state.loginState = LoginStateEnum.NOT_LOGGED_IN;
        state.modalOpened = false;
        state.errorMessage = '';
        state.infoMessage = '';
      },

      startRegistration(state: LoggedInUser) {
        state.loginState = LoginStateEnum.REGISTRATION_STARTED;
        state.modalOpened = true;
        state.errorMessage = '';
        state.infoMessage = '';
      },
      quitRegistration(state: LoggedInUser) {
        state.loginState = LoginStateEnum.NOT_LOGGED_IN;
        state.modalOpened = false;
        state.errorMessage = '';
        state.infoMessage = '';
      },

      setLoginInConflict(state: LoggedInUser, payload: LoginConflictInfo) {
        state.loginState = LoginStateEnum.LOGIN_CONFLICT;
      },
      quitConflictResolution(state: LoggedInUser) {
        state.loginState = LoginStateEnum.NOT_LOGGED_IN;
      },
      setErrorMessage(state: LoggedInUser, msg: string) {
        state.errorMessage = msg;
      },
      setInfoMessage(state: LoggedInUser, msg: string) {
        state.infoMessage = msg;
      },
      setUserError(state: LoggedInUser, msg: string) {
        state.userErrors = msg;
      },

      setLoginState(state: LoggedInUser, newState) {
        state.loginState = newState;
      },
      clearUser(state: LoggedInUser, {keepState, message}: { keepState?:boolean , message?: string}) {
        if (!keepState) {
          state.loginState = LoginStateEnum.NOT_LOGGED_IN;
        }
        state.uid = undefined;
        state.email = undefined;
        //state.displayName = undefined;
        state.persona = undefined;
        state.product = undefined;
        state.editUserId = undefined;
        state.infoMessage = message || undefined;
      },
      setUserAsLogged (state: LoggedInUser, user: LoggedInUser) {
        if (user.uid) {
          state.uid = user.uid;
        }
        if (user.email) {
          state.email = user.email;
        }
        if (user.displayName) {
          state.displayName = user.displayName;
        }
        state.errorMessage = undefined
      },
      setEditCrudUserInStore (state: LoggedInUser, user: EntityDtoAPI) {
        state.editUserId = user.EntityID.toString();
        state.displayName = user.DisplayName;
        state.userCountry = user.UserCountry;
        state.product = user.Product;
        state.persona = user.Persona;
      },
      setEditUserInStore (state: LoggedInUser, user: EntityDtoAPI) {
        state.editUserId = user.EntityID.toString();
        state.displayName = user.DisplayName;
        state.userCountry = user.UserCountry;
        state.product = user.Product;
        state.persona = user.Persona;
      },
      setModalOpened (state: LoggedInUser, val:boolean) {
        state.modalOpened = val;
      },
      setDisplayName (state: LoggedInUser, val:string) {
        state.displayName = val;
      },
    },
    actions: {

     /* syncEntity({commit, dispatch, rootState}, entity: EntityDtoCRUD) {
        syncEntityCrud(rootState).catch(console.error);
      },*/
      dashboardSave({ commit, dispatch, rootState, state }, user: DashboardDTO) {
        const firebaseUser = firebaseAuthProvider.currentUser
        if ( firebaseUser) {
          //TODO save user
          postWebUser(firebaseUser, state, user)
            .then(({data}) => {
              dispatch('setEditCrudUserInStore', data);
              commit('setInfoMessage', 'loc_login_dashboard_save_success');
              setTimeout(() => {
                commit('setInfoMessage', '');
              }, 1500)
            })
        /*  const updatedEntity = buildDefaultWebUserEntity(entityType, {...firebaseUser, ...user});
          return crudUpdateOne(
            updatedEntity,
            rootState
          )
            .then(response => {
              dispatch('setEditCrudUserInStore', response.data.entity)
              dispatch('syncEntity', response).catch(console.error);
              commit('setInfoMessage', 'loc_login_dashboard_save_success');
              setTimeout(() => {
                commit('setInfoMessage', '');
              }, 1500)
            }).catch(e => {
              console.error('createWebUserEntityIfNeeded', e)
              dispatch('onLoginSuccess', user);
            });*/
        }
      },
      favoriteSave({ commit, dispatch, rootState }) {

        const firebaseUser = firebaseAuthProvider.currentUser;
        if ( firebaseUser) {
          console.log('favoritesave');
          postWebUser(firebaseUser, undefined, undefined, rootState.favorites)
          //TODO save new favorite
         /* const updatedEntity = buildDefaultWebUserEntity(entityType,
            {
              ...firebaseUser,
              FavoriteEntities: JSON.stringify(rootState.favorites)

            });
          crudUpdateOne(
            updatedEntity,
            rootState
          )
            .then(response => {

              dispatch('syncEntity', response).catch(console.error);
            })
            .catch(e => {
            console.error('favoriteSave', e)
          });*/
        } else {
          console.log('not logged in');
        }
      },
      async onLoginSuccess({ commit, dispatch, state }, user:firebase.User ) {
        commit('setUserAsLogged', await buildLoggedInUserFromFirebaseUser(user));
        commit('setInfoMessage', 'loc_login_welcome_user');
        if (state.modalOpened) {
          location.reload();
        }
        setTimeout(() => {
          commit('setLoginState', LoginStateEnum.LOGGED_IN)
          commit('setInfoMessage', '');
          commit('setModalOpened', false);
        }, 1500)
      },
      setEditUserInStore({ commit, dispatch }, user: EntityDtoAPI ) {
        commit('setEditUserInStore', user);
      },
      setEditCrudUserInStore({ commit }, user: EntityDtoAPI ) {
        commit('setEditCrudUserInStore', user);
      },
/*      dashboardSendStart({ commit, dispatch }, user: {displayName: string} ) {
        dispatch('updateWebUserEntityProperties', user);
      },*/
      closeAuthModal({ commit } ) {
        commit('closeAuthModalAndClearMessages');
      },
      sendEmailForgotPassword({ commit } , email: string) {
        firebaseAuthProvider.sendPasswordResetEmail(email)
          .then(() => {
            commit('setInfoMessage', 'loc_login_password_reset_email_sent');
          })
      },
      async getEntityForFirebaseUser({ commit, dispatch, rootState }, {user}: { user:firebase.User }) {
        postWebUser(user).then(response => {
          console.log({response});
              dispatch('setEditUserInStore', response?.data);
                dispatch('onLoginSuccess', user);
                let favorites = []
                try {
                  //@ts-ignore
                  favorites = JSON.parse(response?.data?.FavoriteEntities)

                } catch (e) {
                  console.log('error parsing favorites', e)
                }
                rootState.favorites = favorites;
                localStorage.setItem('favorites', JSON.stringify(favorites));

                if (rootState.favorites.length !== JSON.parse(response.data.FavoriteEntities || "[]").length) {
                  dispatch('favoriteSave')
                }
                dispatch('setEditUserInStore', response?.data);

          })
          .catch(e => {
            console.error('error from api', e)
            commit('setErrorMessage', 'error')
          });
      },
/*      updateWebUserEntityProperties({ commit, dispatch, rootState }, {user, entityType, apiEntity}: { user:firebase.User, apiEntity: EntityDtoAPI, entityType: {EntityTypeID: number, EntityName: string} }) {
        const updatedEntity = buildDefaultWebUserEntity(entityType, user);
        return crudUpdateOne(
          updatedEntity,
          rootState
        ).then(response => {
          dispatch('setEditCrudUserInStore', response.data.entity)
          dispatch('syncEntity', response).catch(console.error);
          dispatch('onLoginSuccess', user);
        }).catch(e => {
          console.error('updateWebUserEntityProperties', e)
          dispatch('onLoginSuccess', user);
        });

      },*/
/*      getCrudEntity({ commit, dispatch, rootState }, {user}: { user:firebase.User, apiEntity: EntityDtoAPI, entityType: {EntityTypeID: number, EntityName: string} }) {
        return crudGetOne(
          rootState
        ).then(response => {
          dispatch('onLoginSuccess', user);
        }).catch(e => {
          console.error('updateWebUserEntityProperties', e)
          dispatch('onLoginSuccess', user);
        });

      },*/
/*      createWebUserEntityIfNeeded({ commit, dispatch, rootState, state}, {user, entityType}: { user:firebase.User, entityType: {EntityTypeID: number, EntityName: string} }) {
        const userToCreate = buildDefaultWebUserEntity(entityType, {...user, displayName: state.displayName!});
        return crudCreateNew(
          userToCreate,
          rootState
        )
          .then(response => {
            dispatch('setEditCrudUserInStore', response.data.entity)
            dispatch('syncEntity', response).catch(console.error);
            dispatch('onLoginSuccess', user);
        }).catch(e => {
          console.error('createWebUserEntityIfNeeded', e)
          dispatch('onLoginSuccess', user);
        });
      },*/
      setUserFromFirebase ({ commit, dispatch }, {user}: { user?:firebase.User}): void {
        if (user?.uid) {
            dispatch('getEntityForFirebaseUser',{user} )
          }
      },
      startConflictResolution({ commit }, payload: any) { // TODO - add type reason: any, email: string, password: string
        firebaseAuthProvider.fetchSignInMethodsForEmail(payload.reason.email).then(signInMethods => {
          if (includes(signInMethods, "password")) { // TODO - "password" to some constant
            commit('setLoginInConflict', {
              existingSignInMethods: signInMethods,
              targetLinkProverId: payload.reason.credential.providerId
            });
          }
        });
      },
      finalizeConflictResolution({commit}, payload: UIUserLoginData) { // TODO -add type
        const facebookProvider = new firebase.auth.FacebookAuthProvider();

        return this.dispatch('LoggedInUserModule/loginUser', payload).then(userCredentials => {
          firebaseAuthProvider.currentUser?.linkWithPopup(facebookProvider).then((result) => {
            // Accounts successfully linked.
            var credential = result.credential;
            var user = result.user;

            if (result.credential) {
              firebaseAuthProvider.signInWithCredential(result.credential);
            }
            // ...
          }).catch((error) => {
            // Handle Errors here.
            // ...
            console.error("LINK ERROR", error);
          });

        });
      },
      registerUser({dispatch, commit}, payload: UIUserLoginData): void {
          switch(payload.loginType) {
            case CONSTANTS.LOGIN_TYPE.EMAIL:
              firebaseAuthProvider.createUserWithEmailAndPassword(payload.email, payload.password)
                .then((user) => {
                  if(!user.user?.emailVerified) {
                    user.user?.sendEmailVerification()
                    commit('setInfoMessage', 'loc_login_error_verify_email')
                  }
                  commit('setInfoMessage', 'loc_login_email_sent_for_verification')
                  commit('setErrorMessage', '')
                })
                .catch(error => dispatch('onRegisterError', {error, msg: 'loc_login_failed'}));
          }
      },
      onLoginError({ commit, dispatch }, {error, msg}) {
        commit('setErrorMessage', 'loc_login_failed')
        if (error.code === "auth/account-exists-with-different-credential") {
          console.error("CREDENTIALS CONFLICT", error);
          dispatch('startConflictResolution',  error );
        }
        console.error('login error is', error);
      },
      onRegisterError({ commit }, {error, msg}) {
        commit('setErrorMessage', 'loc_register_failed')
        console.error('Registration error is', error);
      },
      loginWithEmail({dispatch, commit}, payload: UIUserLoginData) {
        firebaseAuthProvider.signInWithEmailAndPassword(payload.email, payload.password)
          .then((user) => {
            if(!user.user?.emailVerified) {
              user.user?.sendEmailVerification()
              commit('setInfoMessage', 'loc_login_error_verify_email')
            }
            commit('setErrorMessage', '')
          })
          .catch(error => {
            commit('setInfoMessage', '')
            dispatch('onLoginError', {error, msg: 'loc_login_failed'})
          });
      },
      loginWithFacebook({dispatch, commit}) {
        const fbProvider = new firebase.auth.FacebookAuthProvider();
        fbProvider.setCustomParameters({
          display: "popup",
        });
        firebaseAuthProvider.signInWithPopup(fbProvider)
          .then(() => commit('setErrorMessage', ''))
          .catch(error => {
            commit('setInfoMessage', '')
            dispatch('onLoginError', {error, msg: 'loc_login_failed'})
          });
      },
      loginWithGoogle({dispatch, commit}) {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        googleProvider.setCustomParameters({
          display: "popup",
        });
        firebaseAuthProvider.signInWithPopup(googleProvider)
          .then(() => commit('setErrorMessage', ''))
          .catch(error => {
            commit('setInfoMessage', '')
            dispatch('onLoginError', {error, msg: 'loc_login_failed'})
          });
      },
      loginUser({dispatch, commit}, payload: UIUserLoginData): void {
        commit('setInfoMessage', 'loginstarted')
        switch(payload.loginType) {
          case CONSTANTS.LOGIN_TYPE.EMAIL:
            dispatch('loginWithEmail', payload)
            break;
          case CONSTANTS.LOGIN_TYPE.FACEBOOK:
            dispatch('loginWithFacebook')
            break;
          case CONSTANTS.LOGIN_TYPE.GOOGLE:
            dispatch('loginWithGoogle')
            break;
        }
      },
      getAuthImages({commit}, {menuItem, commitName}: {menuItem: any, commitName: string}) {
        //@ts-ignore
        commit(commitName, newPrepareImage(menuItem.Media, 'menuLoginImage'), false)
      },
      startRegistration({commit, dispatch, state}, menuItem: any) {
        commit('startRegistration')
        if (!state.registerImages) {
          dispatch('getAuthImages', {menuItem, commitName: 'setRegisterImages'})
        }
      },
      startLogin({commit, dispatch, state}, menuItem: any) {
        commit('startLogin')
        if (!state.dashboardImages) {
          dispatch('getAuthImages', {menuItem, commitName: 'setLoginImages'})
        }
      },
      dashboard({commit, dispatch, state}, menuItem: any) {
        commit('startDashboard');
        if (!state.loginImages) {
          dispatch('getAuthImages', {menuItem, commitName: 'setDashboardImages'})
        }
      },
      logout({commit}, {keepState, message} = {keepState: false, message: ''} ): Promise<void> {
        return firebaseAuthProvider.signOut().then(() => {
          commit('clearUser', {keepState, message})
        }, () => {
          // TODO - An error happened.
          commit('clearUser')
        });
      }
    },
    getters: {
      loggedIn(state) {
        return !!state.uid
      },
      loggedInEmail(state) {
        return state.email
      },
      errorMessage(state) {
        return state.errorMessage
      },
      uid(state) {
        return state.uid
      },
      infoMessage(state) {
        return state.infoMessage
      },
      modalOpened(state) {
        return state.modalOpened
      },
      loginImages(state) {
        return state.loginImages
      },
      registerImages(state) {
        return state.registerImages
      },
      dashboardImages(state) {
        return state.dashboardImages
      }
    }
}
