<template>
  <div :class="moduleInfo.Module.CssClass">
    <div
      class="instagram container max-w-screen-xl mx-auto py-8"
    >
      <h2
        v-if="
          hasPropertyValue(
            'ModuleTitle',
            moduleInfo.Module.ModuleProperties,
            moduleData
          )
        "
        class="headline-3a text-center md:text-left"
      >
        {{ getPropertyValue( $t,"ModuleTitle",moduleInfo.Module.ModuleProperties,moduleData) }}
      </h2>
      <div v-if="!gettingInstagramPosts">
        <div class="loader" />
      </div>
      <div v-if="gotAllData">
        <div
          class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4 xl:gap-5"
        >
          <template
            v-if="gotInstagramData"
          >
            <div
              v-for="post in instagramPosts"
              :key="post.Code"
              class="w-full h-fixed-160 sm:h-fixed-200 lg:h-fixed-265"
            >
              <a
                class="instagram-link"
                :href="'https://www.instagram.com/p/' + post.Code + '/'"
                target="_blank"
              >
                <img
                  :src="post.IsMultiPost ? post.Carousel[0].Images[0].Uri.replace(/https:\//i, '') : post.Images[0].Uri.replace(/https:\//i, '')"
                  class="w-full h-full object-cover"
                >
                <div class="instagram-hover">
                  <i
                    class="icon icon-instagram text-white text-68 absolute vh-center"
                  />
                  <div class="flex items-center absolute bottom-0 left-0 p-2">
                    <span class="flex items-center mr-4">
                      <i
                        class="icon icon-heart-ico text-white text-36"
                      ><span class="path1 text-white" /><span class="path2 text-white" /></i>
                      <span
                        class="text-white text-base"
                      >{{ post.LikesCount }}
                      </span>
                    </span>
                    <span class="flex items-center text-white text-base">
                      <span class="comment" />
                      <span>{{ post.CommentsCount }}</span>

                    </span>
                  </div>
                </div>
              </a>
            </div>
          </template>
          <template
            v-else
          >
            {{ $t("loc_no_instagram_posts") }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/services/http-common";
import {selfDataRequestMixin} from "@/mixins";

export default {
  name: "Instagram",
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      instagramPosts: [],
      gettingInstagramPosts: false,
    };
  },
  computed: {
    gotInstagramData: function () {
      if (this.instagramPosts.length > 0) {
        return true;
      }
      return false;
    },
    gotAllData: function () {
      if (this.moduleInfo) {
        this.getInstagramData();
      }
      return this.gettingInstagramPosts;
    },
    instagramEntityTypeId() {
      return +this.$store.state.Params.find(o => o.Key === 'InstagramEntityTypeId')?.Value || 0
    },
  },
  methods: {
    getInstagramData() {
      if (
        ((this.moduleInfo.Module.ModuleProperties.ModuleShowProfilePosts === undefined || this.moduleInfo.Module.ModuleProperties.ModuleShowProfilePosts ===
          "False" || this.moduleInfo.Module.ModuleProperties.ModuleShowProfilePosts === "") &&
          this.moduleInfo.Module.ModuleProperties.ModuleHashTag !== "" &&
          !this.gettingInstagramPosts) ||
        (this.moduleInfo.Module.ModuleProperties.ModuleShowProfilePosts ===
          "True" &&
          !this.gettingInstagramPosts)
      ) {
        if(this.instagramEntityTypeId > 0 && ((this.moduleInfo.Module.Source != undefined && this.moduleInfo.Module.Source != '' && this.moduleData.length > 0) || this.moduleInfo.Module.Source == undefined || this.moduleInfo.Module.Source == '')){
          axiosInstance
            .post("/api/entities/services", {
              Settings: this.$store.state.MainCompanyCdnApiKey != '' ? this.$store.state.MainCompanyCdnApiKey : this.$store.state.CdnApiKey,
              HashTag: this.getPropertyValue(  this.$t, "ModuleHashTag", this.moduleInfo.Module.ModuleProperties, this.moduleData),
              NumberOfResults: this.moduleInfo.Module.ModuleProperties
                .ModuleNumberOfResults,
              ServiceType: "Instagram",
              OrderBy: this.moduleInfo.Module.ModuleProperties.ModuleOrderBy,
              ShowProfilePosts: this.moduleInfo.Module.ModuleProperties
                .ModuleShowProfilePosts,
              NumberOfPagesToCheck: this.moduleInfo.Module.ModuleProperties.ModuleNumberOfPagesToCheck,
              InstagramTypeId: this.instagramEntityTypeId,
            })
            .then((response) => {
              this.instagramPosts = response.data;
              this.gettingInstagramPosts = true;
            })
            .catch(() => {
              this.gettingInstagramPosts = true;
            });
        }
      } else {
        this.gettingInstagramPosts = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.instagram-link {
  @apply relative inline-block w-full h-full overflow-hidden;
  .icon-heart-ico .path2:before {
    @apply text-white;
  }
  img {
    @apply transition-all duration-300 ease-in-out;
  }
  &:after {
    @apply block absolute z-10 left-0 top-0 opacity-0 w-full h-full transition-all duration-300 ease-in-out;
    content: "";
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, #ff0000, #ff0000);
  }
  .instagram-hover {
    @apply block absolute z-20 left-0 top-0 opacity-0 w-full h-full transition-all duration-300 ease-in-out;
  }
  &:hover {
    &:after {
      opacity: 0.66;
    }
    .instagram-hover {
      opacity: 1;
    }
    img {
      transform: scale(1.1);
    }
  }
}
</style>
