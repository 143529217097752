import { ModulePictures } from '../shared/shared';
import {
  GalleryLayoutInterface, GalleryLayoutTypeInterface, GalleryResponseInterface,
  ImageLayoutsInterface, ImageResponseInterface, LayoutLGSM, ModulePicturesInterface, PreparedImage
} from "@/interfaces";
import {Appstore} from "@/store/main.store";
interface MediaLayout {
  LayoutPath: string,
  LayoutSuffix: string,
  //canvasData: string,
  //cropboxData: string,
}
interface NewMedia {
  MediaDomain?: string;
  IDObjekta: number;
  Layouts: {LayoutSuffix: string, LayoutPath: string}[], //"[\r\n  {\r\n    \"LayoutSuffix\": \"sm\",\r\n    \"LayoutPath\": \"/cmsmedia/Aktivnost/Poslovni_turizam/mosaicImage_dubrovnik-from-above-ivo-biocina_1_sm.jpg\",\r\n    \"canvasData\": \"{\\\"left\\\":-1054.115060925341,\\\"top\\\":-567.3613658268486,\\\"width\\\":2928.230121850682,\\\"height\\\":1561.722731653697,\\\"naturalWidth\\\":3000,\\\"naturalHeight\\\":1600}\",\r\n    \"cropboxData\": \"{\\\"left\\\":114,\\\"top\\\":0,\\\"width\\\":591,\\\"height\\\":427}\"\r\n  },\r\n  {\r\n    \"LayoutSuffix\": \"lg\",\r\n    \"LayoutPath\": \"/cmsmedia/Aktivnost/Poslovni_turizam/mosaicImage_dubrovnik-from-above-ivo-biocina_1_lg.jpg\",\r\n    \"canvasData\": \"{\\\"left\\\":-1054.115060925341,\\\"top\\\":-567.3613658268486,\\\"width\\\":2928.230121850682,\\\"height\\\":1561.722731653697,\\\"naturalWidth\\\":3000,\\\"naturalHeight\\\":1600}\",\r\n    \"cropboxData\": \"{\\\"left\\\":114,\\\"top\\\":0,\\\"width\\\":591,\\\"height\\\":427}\"\r\n  }\r\n]"
  MediaCategory: string,
  MediaExtension: string,
  MediaPath: string,
  IsDefault: boolean;
  ExternalMediaPath: string,
  MediaSource: string,
  MediaType: string,
  MediaTypeValue: GalleryLayoutTypeInterface | 'videoPoster' | 'youtube'| 'vimeo';
  MediaLocation: string,
  MediaDescription: string,
  MediaAltText: string,
  MediaSize: string,
  Author: string,
  AuthorName: string,
  AuthorSurname: string,
}
export function oldPrepareImage (
  gallery: GalleryResponseInterface[] = [],
  galleryType = "default",
  hasVideoPoster = false
): PreparedImage[] {
  const allImages = gallery.reduce((o: ImageResponseInterface[], n: GalleryResponseInterface ) => ([...o, ...n.Images]), [])
  return allImages?.length > 0 ?
    getByCategory(allImages, galleryType, hasVideoPoster) :
    []
}
//TODO sort by type - default last
export function newPrepareImage (
  media: NewMedia[] = [],
  galleryType: keyof  ModulePicturesInterface,
  useThumbnail = true
): PreparedImage[] {
  return media
    .sort((a,b) => {
      if (a.IsDefault === b.IsDefault) {
        return 0
      }
      return a.IsDefault ? 1 : -1
    })
    // TODO check if IsDefault
    //.sort(o => o.Layouts.length > 0 ? -1 : 1)
    .filter(o => o.Layouts || o.MediaPath)
    .map(o => {
      if (o.MediaCategory === 'video' && o.ExternalMediaPath?.length) {
        const regExp = o.MediaTypeValue === 'youtube' ? /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/ : /^.*((vimeo.com\/)|(video\/))\??([^#&?]*).*/;
        let match = o.ExternalMediaPath.match(regExp);
        // imageToUse.ExternalMediaPath = imageToUse.MediaTypeValue === 'youtube' ? ((match && match[7].length==11)? match[7] : false) : ((match && match[4].length > 0 && !isNaN(match[4]))? match[4] : false);
        o.ExternalMediaPath = o.MediaTypeValue === 'youtube' ? ((match && match[7].length==11)? match[7] : '') : ((match && match[4].length > 0 && !isNaN(+match[4]))? match[4] : '');
      }
    const lg = o.Layouts.find(o => o.LayoutSuffix === 'lg') ?? {LayoutPath: ''}
    const sm = o.Layouts.find(o => o.LayoutSuffix === 'sm') ?? {LayoutPath: ''};
    const path = getUrlWithDomainMedia(o.MediaDomain, o.MediaPath)
    const meta: ImageResponseInterface = {
      ...o,
      Layouts: '',
      MediaPath: path,
    }
    try {
      //const temp = JSON.parse(lg?.canvasData ?? '{}')
    } catch (e) {
    }
    //TODO medialayouts in page data
    return {
      meta,
      lg: {
        height: 200,
        path: getUrlWithDomainMedia(o.MediaDomain, !o.IsDefault && lg?.LayoutPath ? lg?.LayoutPath : getDefaultImagePath(o,galleryType, useThumbnail)),
        width:  200,
        style: !o.IsDefault && sm?.LayoutPath ? '' : getAspectRatio(o, galleryType, useThumbnail)
      },
      sm: {
        height:  200,
        path: getUrlWithDomainMedia(o.MediaDomain, !o.IsDefault && sm?.LayoutPath ? sm?.LayoutPath : getDefaultImagePath(o,galleryType, useThumbnail)),
        width:  200,
        style: !o.IsDefault && sm?.LayoutPath ? '' : getAspectRatio(o, galleryType, useThumbnail)
      }
  }})
}

function getUrlWithDomainMedia(mediaDomain?: string, val?: string): string {
  const domain = mediaDomain?.endsWith('/') ? (mediaDomain || '').slice(0,-1) : (mediaDomain || '');
  const propVal =  val?.startsWith('/') ? (val || '').slice(1) : (val || '');
  const urlWithDomain = domain && propVal ? domain + '/' + propVal : '';
  if(propVal?.startsWith('http')) {
    return propVal;
  }
  return domain && propVal ? domain + '/' + propVal : '';
}

/**
 * if some image has category video then return video format else return image
 * @param {ImageResponseInterface[]} images Complete List of images to process
 * @param {string} galleryType module galleryType
 * @param hasVideoPoster module value to get poster image with video
 * @returns {PreparedImage[]} only required images, ready for module
 */
function getByCategory(images: ImageResponseInterface[], galleryType: string, hasVideoPoster: boolean): PreparedImage[] {
  switch (galleryType) {
    case "video": {
      if (images.some(galleryItem => galleryItem.MediaCategory === "video" && checkLanguage(galleryItem))) {
        return prepareVideos(images.filter(galleryItem => checkLanguage(galleryItem)), hasVideoPoster, galleryType)
      } else if (images.some(galleryItem => galleryItem.MediaCategory === "video")) {
        return prepareVideos(images, hasVideoPoster, galleryType)
      } else {
        console.error('no video type. This module require at least one video')
        return []
      }
    }
    case "mediaGalleryImgs": {
      return prepareGallery(images.filter(galleryItem => checkLanguage(galleryItem)), galleryType)
    }
    case "paragraphImageLeftRight": {
      let preparedImages = prepareGallery(images.filter(galleryItem => checkLanguage(galleryItem)), galleryType);
      let defaultImage = prepareImages(images.filter(one => one.MediaCategory === 'photo'), "default");
      //if type exists return images
      if(preparedImages.length > 0){
        return preparedImages;
      } else if(defaultImage.length > 0){
        return defaultImage;
      } else {
        return []
      }
    }
    default: {
      return prepareImages(images.filter(one => one.MediaCategory === 'photo'), galleryType)
    }
  }
}
function checkValidTypeAndLanguage(image: ImageResponseInterface, galleryType: string): boolean {
  return image.MediaTypeValue === galleryType && checkLanguage(image)
}
function checkLanguage(image: ImageResponseInterface): boolean {
  return image.AvailableForLanguage ?
          image.AvailableForLanguage.includes(Appstore.state.LanguageId.toUpperCase()) :
          true
}
/**
 *  if same gallery type return correct one else
 *  if not and some has default return default one else
 *  if neither correct galleyType nor default return first one
 * @param {ImageResponseInterface[]} images Complete List of images to process
 * @param {string} galleryType module galleryType
 * @returns {PreparedImage[]} only required images, ready for module
 */
export function prepareImages(images: ImageResponseInterface[], galleryType: string,): PreparedImage[] {

  if (images.some(image => checkValidTypeAndLanguage(image, galleryType))) {
    return images.filter(image => checkValidTypeAndLanguage(image, galleryType)
    ).map(image => prepareImage(image, galleryType))
  } else if (images.some(image => image.MediaTypeValue === "default" && checkLanguage(image))) {
    return images.filter(image => image.MediaTypeValue === "default")?.map(image => prepareImage(image, galleryType,false, false))
  } else if(images.some(image => checkLanguage(image))) {
    return images.filter(image => checkLanguage(image))?.map(image => prepareImage(image, galleryType,false, false))
  // return default
  } else {
    return images[0] ? [images[0]].map(image => prepareImage(image, galleryType,false, false)) : []
  }
}
/**
 * setup format for use in module
 * @param {ImageResponseInterface} image image from response
 * @param galleryType
 * @param video
 * @param correctType
 * @returns {PreparedImage} ready format for module
 */
function prepareImage (
  image: ImageResponseInterface,
  galleryType: string,
  video = false,
  correctType = true
): PreparedImage {
  const imageToUse = JSON.parse(JSON.stringify(image));
  if (video && imageToUse.ExternalMediaPath?.length) {
    const regExp = imageToUse.MediaTypeValue === 'youtube' ? /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/ : /^.*((vimeo.com\/)|(video\/))\??([^#&?]*).*/;
    let match = imageToUse.ExternalMediaPath.match(regExp);
    // imageToUse.ExternalMediaPath = imageToUse.MediaTypeValue === 'youtube' ? ((match && match[7].length==11)? match[7] : false) : ((match && match[4].length > 0 && !isNaN(match[4]))? match[4] : false);
    imageToUse.ExternalMediaPath = imageToUse.MediaTypeValue === 'youtube' ? ((match && match[7].length==11)? match[7] : '') : ((match && match[4].length > 0 && !isNaN(+match[4]))? match[4] : '');
  }
  const temp = {
    meta: {
      ...imageToUse,
      MediaAltText: imageToUse.MediaAltText || imageToUse.MediaDescription,
      AvailableForLanguageArr: imageToUse.AvailableForLanguage ? imageToUse.AvailableForLanguage.split("#") : [],
    },
    ...prepareLayouts(
      imageToUse,
      ModulePictures[(galleryType as GalleryLayoutTypeInterface)] ||
      ModulePictures['default'],
      correctType
    )
  }

  return temp;
}

function prepareVideos(images: ImageResponseInterface[], hasVideoPoster: boolean, galleryType: string): PreparedImage[] {
  const videoList = images.filter(one => one.MediaCategory === "video");
  return [
    ...videoList.map(image => prepareImage(image, galleryType, true)),
    ...(hasVideoPoster ? prepareImages(images, 'videoPoster' ) : [] )
  ]
}
function prepareGallery(images: ImageResponseInterface[], galleryType: string): PreparedImage[] {
  return [
    ...images.filter(one => one.MediaTypeValue === galleryType).map(image => prepareImage(image, galleryType)),
    ...images.filter(one => one.MediaCategory === "video").map(image => prepareImage(image, galleryType,true))
  ]
}

/**
 * Prepare layouts from image data given the correct layout
 * @param {ImageResponseInterface} image
 * @param {LayoutLGSM} layoutToUse
 * @param correctType
 * @returns {ImageLayoutsInterface}
 */
function prepareLayouts (
  image: ImageResponseInterface,
  layoutToUse: LayoutLGSM,
  correctType = true
): ImageLayoutsInterface {
  // u buduce pitati da ne dobijemo array i stringu
  let parsedList: GalleryLayoutInterface[];
  try {
    parsedList = JSON.parse(image.Layouts);
  } catch (e) {
    parsedList = []
  }
  return correctType ? {
    sm: {
      ...layoutToUse.sm,
      path:  parsedList?.find(one => one.LayoutSuffix === 'sm')?.LayoutPath || ''//  getDefaultImagePath(image,layoutToUse)
    },
    lg: {
      ...layoutToUse.lg,
      path: parsedList?.find(one => one.LayoutSuffix === 'lg')?.LayoutPath ||  ''// getDefaultImagePath(image,layoutToUse)
    },
  } : {
    sm: {
      ...layoutToUse.sm,
      path: ''// getDefaultImagePath(image,layoutToUse)
    },
    lg: {
      ...layoutToUse.lg,
      path: '' // getDefaultImagePath(image,layoutToUse)
    }
  }
}

/**
 *
 * fallback for images without Layouts
 * @param {ImageResponseInterface} galleryImage image to use
 * @param galleryType
 * @returns {string} path for make request thumbnail, or complete unchanged path if it is svg
 */
function getDefaultImagePath(galleryImage: NewMedia, galleryType: keyof  ModulePicturesInterface, useThumbnail: boolean): string {
  //console.log({galleryImage});
  // const layoutToUse = ModulePictures[galleryType] || ModulePictures['default']
  // if(useThumbnail && galleryImage && galleryImage.MediaExtension != 'svg'){
  //   return `/thumbnail.aspx?filename=${galleryImage.MediaPath}&width=${layoutToUse["lg"].width}&height=${layoutToUse["lg"].height}&trim=1&save=1&quality=80`
  // } else {
  //   return galleryImage.MediaPath;
  // }

  return galleryImage.MediaPath;
}

function getAspectRatio(galleryImage: NewMedia, galleryType: keyof  ModulePicturesInterface, useThumbnail: boolean): string {
  const layoutToUse = ModulePictures[galleryType] || ModulePictures['default']
  if(useThumbnail && galleryImage && galleryImage.MediaExtension != 'svg'){
    //Koristi se samo lg layout za aspect ratio jer je to jedini koji se koristi za prikaz slika kroz thumbnail.aspx
    //Moguce je postaviti i dodatni uvjet da se na mobile uzima sm layout za aspect-ratio, ali je moguce da prikaz nece biti isti kao do sada,
    //a klijent ne očekuje promjene u prikazu
    return `aspect-ratio: ${layoutToUse["lg"].width} / ${layoutToUse["lg"].height};`
  } else {
    return ''
  }
}
