import {newPrepareImage} from "@/components/utils/prepareImages";
import {ComponentState, ModuleDataForModule, ModulePicturesInterface, RelatedInterface} from "@/interfaces";
import {Appstore} from "@/store/main.store";


export function moduleDataForModule(
  storeModule: ComponentState,
  moduleHasSlider: boolean,
  loadMoreLimitParams: number,
  galleryType: keyof  ModulePicturesInterface,
  hasVideoPoster: boolean,
  favorites = false
): ModuleDataForModule[] {
  const dataToUse = favorites ? Appstore.state.favorites : storeModule.moduleData
  return dataToUse ? dataToUse
/*    .slice(
      0,
      !moduleHasSlider ? ((loadMoreLimitParams || 0) + (storeModule.loadMore || 0)) : dataToUse.length
    )*/
    .map(o => ({
        ...o,
      MediaGallery: [...newPrepareImage(o.Media, galleryType)]
      })
    ) :
    []
}
