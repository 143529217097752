

import {Options, Vue} from "vue-class-component";
import {Emit, Prop} from "vue-property-decorator";

@Options({

  emits: ['on-change-event']
})
export default class AppInput extends Vue {

  @Prop({default: []})
  classList: string[] = [];

  @Prop({default: ''})
  label: string = ''

  @Prop({default: false})
  checked: boolean = false

  @Emit()
  onChangeEvent (val: boolean) {}

  changeEvent(val: boolean) {
    this.onChangeEvent(val)
  }
  /* TODO: changeEvent(event) {
      this.$emit("update:checked", event);
      this.$emit("change", event);
  } */
}
