export function lazyLoad() {
    //return
    let lazyImages = [].slice.call(document.querySelectorAll("img.lazy-img:not(.lazy-loaded)"));
    let lazySources = [].slice.call(document.querySelectorAll("source.lazy-source:not(.lazy-loaded)"));
    let activeImg = false;
    let activeSource = false;
    if (!activeSource) {
        activeSource = true;
        setTimeout(() => {
            lazySources.forEach((lazySource) => {
                if ((lazySource.getBoundingClientRect().top <= window.innerHeight + 1500 && lazySource.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazySource).display !== "none") {

                lazySource.srcset = lazySource.dataset.srcset;
                lazySource.classList.add("lazy-loaded");
                lazySources = lazySources.filter(function (image) {
                    return image !== lazySource;
                });

                if (lazySources.length === 0) {
                    /* window.removeEventListener("load", this.lazyLoad);
                    window.removeEventListener("scroll", this.lazyLoad); */
                }
                }
            });
            activeSource = false;
        }, 200)

    }
    if (!activeImg) {
        activeImg = true;
        setTimeout(() => {
        lazyImages.forEach((lazyImage) => {
            if ((lazyImage.getBoundingClientRect().top <= window.innerHeight + 1500 && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.classList.add("lazy-loaded");
                lazyImages = lazyImages.filter(function (image) {
                    return image !== lazyImage;
            });

            if (lazyImages.length === 0) {
                /*  window.removeEventListener("load", this.lazyLoad);
                window.removeEventListener("scroll", this.lazyLoad); */
            }
        }
        });
        activeImg = false;
        }, 200)

    }


}


