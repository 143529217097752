<template>
  <div
    class="currency-calculator mx-auto my-8 bg-white"
    :class="{container: !isSidebar, 'max-w-screen-xl': !isSidebar}"
  >
    <template v-if="isSidebar">
      <h5
        v-if="hasModuleTitle"
        class="overtitle-1 text-htz-steel text-left text-16 mb-5"
      >
        {{ moduleTitle }}
      </h5>
    </template>
    <template v-else>
      <h4
        v-if="hasModuleTitle"
        class="headline-4 text-htz-red mb-5 text-40 md:text-48"
      >
        {{ moduleTitle }}
      </h4>
    </template>
    <div
      class="flex flex-wrap"
      :class="{'flex-wrap': isSidebar, 'md:flex-no-wrap': !isSidebar}">
      <div
        class="flex w-full md:w-unset md:min-w-360"
        :class="{'w-full': isSidebar}"
      >
        <div class="flex w-full items-center">
          <AppDropDown
            v-if="firstSelected"
            class="md:w-155"
            :select-list="hnbData"
            :select-all="false"
            :option-class="'md:w-155'"
            :selected-item="firstSelected"
            :drop-down-class="'currency-dropdown w-full py-4 px-3 flex items-center justify-between border-2 border-htz-grey text-htz-darkgrey font-normal text-16 font-livory'"
            @on-change-selected="onChangeFirstHnb"
          >
            <span class="arrow-down" />
          </AppDropDown>
          <!-- <span class="flex-none text-center my-auto w-59 md:w-50" >O</span> -->
          <img
            src="/dist/img/icon-currency-change.png"
            class="w-5 h-auto mx-3 cursor-pointer"
            @click="changeCurrency"
          >
          <AppDropDown
            v-if="secondSelected"
            class="md:w-155"
            :select-list="hnbData"
            :select-all="false"
            :option-class="'md:w-155'"
            :selected-item="secondSelected"
            :drop-down-class="'currency-dropdown w-full py-4 px-3 flex items-center justify-between border-2 border-htz-grey text-htz-darkgrey font-normal text-16 font-livory'"
            @on-change-selected="onChangeSecondHnb"
          >
            <span class="arrow-down" />
          </AppDropDown>
        </div>
      </div>
      <div
        class="flex flex-wrap w-full"
        :class="{'flex-wrap': isSidebar, 'md:flex-no-wrap': !isSidebar}">
        <div class="flex h-59 w-full my-5" :class="{'md:my-0': !isSidebar}">
          <span
            class="hidden text-center my-auto w-8"
            :class="{'md:block': !isSidebar}"
          />
          <div class="flex w-full md:max-w-340">
            <AppInput
              v-model="inputVal"
              :placeholder="$t('loc_currency_placeholder')"
              :class-list="['border-b-2 text-2xl text-right border-htz-red text-htz-steel pr-20 text-htz-blue w-full h-12 py-4 px-1 relative z-1 bg-transparent']"
              :show-icon="false"
              :value="inputVal"
            >
              <span class="-ml-16 text-htz-steel text-2xl"> {{ firstName }} </span>
            </AppInput>
          </div>
        </div>
        <div class="flex h-59 w-full overflow-auto">
          <span
            class="hidden text-center my-auto w-8"
            :class="{'md:block': !isSidebar}"
          />
          <div class="flex w-full currency-result">
            <div class="w-full my-auto text-right text-21">
              <span>{{ result }}</span>
              <span class="px-3">{{ secondName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-full pl-4 pt-3"
      :class="{'md:text-right': !isSidebar, 'md:pt-0': isSidebar}"
    >
      <p class="text-htz-grey text-sm"> {{ $t('loc_currency_on_date') }} {{ today }} </p>
      <p class="text-htz-grey text-sm">1 {{ secondName }} = {{ inverseResult }} {{ firstName }}</p>
    </div>
  </div>
</template>
<script>
import {selfDataRequestMixin} from "@/mixins";
import AppDropDown from "@/libs/ui/lib/forms/select/AppDropDown";
import AppInput from "@/libs/ui/lib/forms/Input/AppInput.vue"
import axiosInstance from "@/services/http-common";

export default {
  components: {AppDropDown, AppInput},
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataLoaded: false,
      firstSelected: null,
      secondSelected: null,
      hnbData: [],
      allHnb: [],
      inputVal: '100',
      intOption: {minimumFractionDigits: 2, maximumFractionDigits: 2}
    };
  },
  mounted() {
    this.getHNBData()
  },
  watch: {
    initialValue() {
      this.inputVal = this.initialValue
    }
  },
  methods: {
    changeCurrency() {
      let temp = this.firstSelected
      this.firstSelected = this.secondSelected
      this.secondSelected = temp
    },
    convertNumber(num, locale) {
      const { format } = new Intl.NumberFormat(locale);
      const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
      return +num
        .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
        .replace(decimalSign, '.');
    },
    getHNBData() {
      axiosInstance.post('/api/exchangerate')
        .then(response => {
          this.allHnb = response.data;
          this.hnbData = this.moduleData.reduce((acc, curr) => {
            const found = response.data.find(item => item.valuta === curr.Currency)
            if (found) {
              acc.push({
                value: found['sifra_valute'],
                name: found.valuta,
                property: 'currency'
              })
            }
            if (curr.Currency === 'EUR') {
              acc.push({
                value: '1',
                name: 'EUR',
                property: 'currency',
              })
            }
            if (curr.Currency === 'HRK') {
              acc.push({
                value: '00',
                name: 'HRK',
                property: 'currency',
              })
              this.allHnb.push({
                broj_tecajnice: "99",
                datum_primjene: "2023-01-01",
                drzava: "Hrvatska",
                drzava_iso: "HRK",
                'sifra_valute': "00",
                valuta: "HRK",
                kupovni_tecaj: "0.13272280841",
                srednji_tecaj: "0.13272280841",
                prodajni_tecaj: "0.13272280841"
              })
            }
            return acc;
          }, []);
          this.firstSelected = (this.hasModuleFirstCurrency ? this.hnbData.find(o => o.name ===(this.moduleFirstCurrency)) : this.hnbData.find(o => o.name ===('EUR')) )|| this.hnbData[0];
          this.secondSelected = (this.hasModuleSecondCurrency ? this.hnbData.find(o => o.name ===(this.moduleSecondCurrency)) : this.hnbData.find(o => o.name ===('USD')) )|| this.hnbData[0];
        })
        .catch(error => {
          console.log(error);
        });
    },
    onChangeFirstHnb(item) {
      this.firstSelected = item;
    },
    onChangeSecondHnb(item) {
      this.secondSelected = item;
    },
    getCurrencyByValue(val) {
      return this.allHnb.find(o => o['sifra_valute'] === val)?.['srednji_tecaj'].replace(',', '.')  || 1;
    },
    getCurrencyNameByValue(val) {
      return this.allHnb.find(o => o['sifra_valute'] === val)?.valuta || 'EUR';
    },
    getResult(num) {
      if (this.isFirstEuro) {
        num = num * +this.getCurrencyByValue(this.secondSelected.value);
        return num
      }
      if (this.isSecondEuro) {
        num = num / +this.getCurrencyByValue(this.firstSelected.value);
        return num
      }
      if (this.isFirstEuro && this.isSecondEuro) {
        return num
      }
      num = num * +this.getCurrencyByValue(this.secondSelected?.value);
      num = num / +this.getCurrencyByValue(this.firstSelected?.value);
      return num
    }
  },
  computed: {
    initialValue() {
      return this.$store.state.Params.find(o => o.Key === 'CurrencyCalculatorInitialValue')?.Value ? this.$store.state.Params.find(o => o.Key === 'CurrencyCalculatorInitialValue').Value : '100'
    },
    today() {
      return new Date().toLocaleDateString(this.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });
    },
    firstName() {
      return this.getCurrencyNameByValue(this.firstSelected?.value) || '';
    },
    secondName() {
      return this.getCurrencyNameByValue(this.secondSelected?.value) || '';
    },
    isFirstEuro() {
      return this.firstSelected?.value === '1' || false;
    },
    isSecondEuro() {
      return this.secondSelected?.value === '1' || false;
    },
    locale() {
      return this.$store.state.LanguageId + '-' + this.$store.state.LanguageId.toUpperCase();
    },
    inverseResult() {
      return (1 / this.getResult(1)).toLocaleString(this.locale, this.intOption)
    },
    result() {
      let num = this.convertNumber(this.inputVal, this.locale);
      return this.getResult(num).toLocaleString(this.locale, this.intOption)
    },
    hasModuleTitle() {
      return this.$filters.hasPropertyValue(
        "ModuleTitle",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleData
      );
    },
    moduleTitle() {
      return this.$filters.getPropertyValue(
        this.$t,
        "ModuleTitle",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleData
      );
    },
    isSidebar() {
      return this.$filters.getPropertyValue(
        this.$t,
        "ModuleShowAsSidebar",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleData
      ) === 'True';
    },
    moduleFirstCurrency() {
      return this.$filters.getPropertyValue(
        this.$t,
        "ModuleFirstCurrency",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleData
      );
    },
    hasModuleFirstCurrency() {
      return this.$filters.hasPropertyValue(
        "ModuleFirstCurrency",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleData
      );
    },
    hasModuleSecondCurrency() {
      return this.$filters.hasPropertyValue(
        "ModuleSecondCurrency",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleData
      );
    },
    moduleSecondCurrency() {
      return this.$filters.getPropertyValue(
        this.$t,
        "ModuleSecondCurrency",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleData
      );
    },
  }

}
</script>
<style>
.currency-result {
  background-color: #f2f2f4;
}
.currency-calculator .arrow-down:after{
  border-left-color:#6f7486;
}
</style>
