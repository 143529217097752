<template>
  <div
    v-if="showCovidTrack === true"
    class="nav-covid-track max-w-screen-3xl w-full h-16 sm:h-10 fixed left-0 right-0 mx-auto z-99 top-0 bg-htz-blue text-white flex items-center justify-center text-center"
  >
    <VirtualLink
      :href="menuItem.CustomUrl?.length > 0 ? menuItem.CustomUrl : menuItem.Page"
      class-list="text-white px-12"
    >
      {{ menuItem.Name }}
    </VirtualLink>

    <span
      class="close-track absolute top-0 right-0 bottom-0 m-auto flex sm:block items-center pt-0 z-99 px-2 text-60 sm:-mt-8 sm:pt-1 cursor-pointer"
      @click="hideCovidTrack"
    >×</span>
  </div>
</template>

<script>
import { menuMixin } from "@/mixins";
//import {selfDataRequestMixin} from "@/mixins";

export default {
  mixins: [menuMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    }
  },
  emits: ["showCovid"],
  data() {
      return {
        showCovidTrack: false
      }
  },
  mounted() {
   if(sessionStorage.getItem('showCovidTrack') === true || sessionStorage.getItem('showCovidTrack') === null) {
    this.showCovidTrack = true;
   }
    this.$emit("showCovid", this.showCovidTrack)
  },
  methods: {
    hideCovidTrack(){
      this.$emit("showCovid", false);
      this.showCovidTrack = false;
      sessionStorage.setItem('showCovidTrack', false);
      /* this.setCookie('showCovidTrack', false) */
    }
  },
};
</script>
