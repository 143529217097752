
export function dateFromString(dateString: string): Date {
  const dateArray= dateString.split(' ')
  const daysArray= dateArray[0]?.split('.')
  const timeArray= dateArray?.[1]?.split(':')
  if (!daysArray[2] || !daysArray[1] || !daysArray[0]) {
    console.error('not valid date', dateString)
    return new Date()
  }

  return new Date(
    +daysArray[2],
    +daysArray[1] -1,
    +daysArray[0],
    timeArray?.[0] ? +timeArray?.[0] : 0,
    timeArray?.[1] ? +timeArray?.[1] : 0,
    timeArray?.[2] ? +timeArray?.[2] : 0
  )
}
