<template>
  <PageHeader v-if="showOnlyContent" />
  <div>
    <slot />
  </div>
  <PageFooter v-if="showOnlyContent" />
</template>
<script>
import PageHeader from "@/components/core/PageHeader";
import PageFooter from "@/components/core/PageFooter";
export default {
  name: "Master",
  components: {
    PageHeader,
    PageFooter,
  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
    moduleData: {
      type: Array,
      default: () => [],
    },
    showOnlyContent: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {};
  },
};
</script>
