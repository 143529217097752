<template>
  <div>
    <Navigation />
  </div>
</template>
<script>
import Navigation from "@/components/includes/Navigation";
export default {
  name: "PageHeader",
  components: {
    Navigation,
  },
  props: {},
  data() {
    return {};
  },
};
</script>
