

import {AppMixin} from "@/mixins/app.mixin";
import {ModuleInfo, PageDataInterface,} from "@/interfaces/page-data.interface";
import {setStoreModule} from "@/store/store.utils";
import {Options} from "vue-class-component";
import Filter from "@/libs/features/lib/Filter/Filter.vue";
import ModuleLayout from "@/components/layouts/ModuleLayout.vue"
import { Watch} from "vue-property-decorator";
import CardItem from "@/components/includes/CardItem.vue";
import {appNavigate} from "@/router/router.service";
import {AxiosResponse} from "axios";
import {Appstore} from "@/store/main.store";

@Options({
  components: {
    CardItem,
    Filter,
    ModuleLayout,
  },

})
export default class AppRoute extends AppMixin {

  initialLoader = true;
  virtualPageLoading = false;
  @Watch('$store.state.navigateUrl')

  onRouteChange(to: string) {
    if (!this.$route.hash) {
      const oldLang = this.$store.state.LanguageId;
      Appstore.state.PageData.ModuleData = [];
      this.deleteOldModuleData()
      this.enableCrowdriffReload()
      this.virtualPageLoading = true;
      this.$store.state.PageData.ModuleData = [];
      appNavigate(to).then((res: AxiosResponse<PageDataInterface>) => {
        this.setPageOnNavigation(res, oldLang)
      })
        .catch(e => {
          if (e?.response?.status === 404) {
            this.setPageOnNavigation(e.response, oldLang)
          }
        });
    }

  }
  setPageOnNavigation(res: AxiosResponse<PageDataInterface>, oldLang: string) {
    this.setupPageData(res.data);
    this.storeModuleData();
    this.getModuleData()
    this.setNewTitle(res);
    this.loadCurrentPageLanguages();
    this.slice = res.data.ModuleData.length;
    if (res.data.LanguageId !== oldLang ) {
      this.changeLocale(res.data.LanguageId);
      this.setMenuData();
      this.$store.state.resetLanguage ++;
    }
    this.virtualPageLoading = false;
  }
  @Watch('$store.state.reload', {immediate: false})
  onReload() {
    this.getModuleData()
  }

  @Watch('loadedInitialComponents', {immediate: true})
  onLoadedModules(val: boolean) {

    setTimeout(() => {
      this.lazyLoad()
    }, 500);
  }
  @Watch('virtualPageLoading', {immediate: false})
  onVirtualPageLoading() {
    this.scrollToModule()
    setTimeout(() => {
      this.lazyLoad()
    }, 500);
  }
  @Watch('this.$route.hash', {immediate: false})
  onHashChange() {
    this.scrollToModule()
  }

  scrollToModule(){
    const hash = this.$route.hash.replace('#', '');
    if (hash && [1,2,3,4].some(i => this.appState.PageData.ModuleData[i]?.Module?.EntityID === +hash)) {
      setTimeout(() => {
        const getMeTo = document.getElementById(hash);
        if (getMeTo) {
          getMeTo.scrollIntoView({behavior: 'smooth',inline: 'start'});
        }
      }, 300);
    }
    const calcLength = (this.appState.PageData.ModuleData || []).length - 4 < 1 ? 1 : (this.appState.PageData.ModuleData || []).length - 4;
    if (hash && Array(calcLength).fill(0).some((_, i) => this.appState.PageData.ModuleData[i + 4]?.Module?.EntityID === +hash)) {

      setTimeout(() => {
        window.scrollTo({top: 10})
      }, 100)
      setTimeout(() => {
        const getMeTo = document.getElementById(hash);
        if (getMeTo) {
          getMeTo.scrollIntoView({behavior: 'smooth'});
        }
      }, 1500);
    }
  }

  enableCrowdriffReload(){
    if(document.body.classList.contains('cr-gallery')) {
      document.body.classList.remove('cr-gallery');
    }
  }

  mounted() {

    setTimeout(() => this.initialLoader = false, 1000)
    window.addEventListener('popstate', () => {
      Appstore.state.navigateUrl = location.pathname + (location.pathname.includes('?') ? '&json=true' : '?json=true');
    })

    this.changeLocale();
    this.checkPersonaQuery();
    this.storeModuleData();
    this.getA11y();
    this.getFavorites();
    this.getModuleData()
    this.setupParams();
    this.setMenuData();
    this.setupEntityId();
    this.loadCurrentPageLanguages();
    //setTimeout(() => this.setupHyperlinks(), 1000);
    this.scrollToModule()
    setTimeout(() => this.setBreadcrumbs(), 1000);
  }
}
