import firebase from 'firebase/app';
import 'firebase/auth';

var fireBaseAppName = "infosit-lean";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*var firebaseConfig = {
    apiKey: "AIzaSyBNI46d9nIbBcXMryIyc0E9z3vXg7rZHE8",
    authDomain: "infosit-login.firebaseapp.com",
    projectId: "infosit-login",
    storageBucket: "infosit-login.appspot.com",
    messagingSenderId: "725817562551",
    appId: "1:725817562551:web:b71bedab0cc66b25370432",
    //measurementId: "G-EPB3PTR6ZM",
};*/

var firebaseConfig = {
  apiKey: "AIzaSyB70vi8s9pJhOlO9iOvGIbma_GhhvTomh8",
  authDomain: "htz-login.firebaseapp.com",
  projectId: "htz-login",
  storageBucket: "htz-login.appspot.com",
  messagingSenderId: "635599509650",
  appId: "1:635599509650:web:562711d521a018b3536e4b"
};
var firebaseApp = firebase.initializeApp(firebaseConfig, fireBaseAppName);
var firebaseAuthProvider = firebase.auth(firebaseApp);

export {
    firebaseApp,
    firebaseAuthProvider
}
