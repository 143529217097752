

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue'
import {LoginStateEnum} from "@/interfaces/login/login-state-enum";
import LoginForm from "@/components/includes/login/LoginForm.vue";
import RegistrationForm from "@/components/includes/login/RegistrationForm.vue";
import ForgotPassword from "@/components/includes/login/ForgotPasswordForm.vue";
import DashBoardForm from "@/components/includes/login/AuthDashboard.vue";
import {mapActions} from "vuex";
import {Options, Vue} from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {PreparedImage} from "@/interfaces";

@Options({
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    LoginForm,
    RegistrationForm,
    ForgotPassword,
    DashBoardForm
  },
  props: {
    hasCovidTrack: {
      type: Boolean,
      default: false,
    },
   },
  methods: mapActions({
    closeAuthModal: 'LoggedInUserModule/closeAuthModal',
  })
})
export default class AuthModal extends Vue{
  closeAuthModal!: () => void
  get showLogin() {
    return this.$store.state.LoggedInUserModule?.loginState === LoginStateEnum.LOGIN_STARTED
  }
  get showRegister() {
    return this.$store.state.LoggedInUserModule?.loginState === LoginStateEnum.REGISTRATION_STARTED
  }
  get showForgotPassword() {
    return this.$store.state.LoggedInUserModule?.loginState === LoginStateEnum.FORGOT_PASSWORD
  }
  get showDashBoardForm() {
    return this.$store.state.LoggedInUserModule?.loginState === LoginStateEnum.DASHBOARD
  }

  get modalOpened() {
    return this.$store.state.LoggedInUserModule?.modalOpened || false
  }
  get loginImages() : PreparedImage[] | undefined {
    return this.$store.state.LoggedInUserModule?.loginImages
  }
  get dashboardImages() : PreparedImage[] | undefined {
    return this.$store.state.LoggedInUserModule?.dashboardImages
  }
  get registerImages() : PreparedImage[] | undefined {
    return this.$store.state.LoggedInUserModule?.registerImages
  }

  @Watch('modalOpened')
    onModalOpened(val: boolean) {
      if (val) {
        let navHeight = (document.querySelector(".nav") as Element & {offsetHeight: number})?.offsetHeight;
        if(navHeight < 66){
          setTimeout(() => {
            let authModal = document.querySelector(".dialog-auth-container");
            if(authModal){authModal.classList.add("dialog-auth-container-fixed");}
          }, 50);
        }
      } else {
        let authModal = document.querySelector(".dialog-auth-container");
        if(authModal){authModal.classList.remove("dialog-auth-container-fixed");}

      }
    }


  closeModal() {
    this.closeAuthModal()
  }

}
