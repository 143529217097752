<template>
  <div
    v-for="infoFooterItem in moduleInfo"
    :key="infoFooterItem.EntityID"
    class="flex flex-col items-center lg:items-start text-center lg:text-left w-full lg:w-3/12 py-5 lg:pt-0"
  >
    <FooterMenuItem
      :module-info="infoFooterItem"
      :module-class="'leading-10 lg:leading-8 text-htz-steel'"
    />
  </div>
</template>

<script>
import FooterMenuItem from "./FooterMenuItem";

export default {
  components: {
    FooterMenuItem
  },
  props: {
    moduleInfo: {
      type: Array,
      default: () => ([]),
    },
  }
};
</script>
