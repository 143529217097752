export default Object.freeze({

    // Call like this in component
    // this.$constants.LOGIN_TYPE.EMAIL
    // this.$constants.LOGIN_TYPE.FACEBOOK
    // this.$constants.LOGIN_TYPE.GOOGLE
    LOGIN_TYPE: {
        EMAIL: "Email",
        FACEBOOK: "Facebook",
        GOOGLE: "Google"
    },

    //ovo je radi primjera, u komponenti se poziva kao this.$constants.PARAMETRI.Domena
    PARAMETRI :{
        Domena : "Domena",
        GoogleMapsAPIKey: "GoogleMapsAPIKey"
    },

    //ovo je radi primjera, u komponenti se poziva kao this.$constants.GRUPA1.Domena
    GRUPA1 :{
        Domena : "Domena"
    },

    //ovo je radi primjera, u komponenti se poziva kao this.$constants.GRUPA2.Domena
    GRUPA2 :{
        Domena : "Domena"
    },
    DATES :{
      Format : "dd.MM.yyyy",
      DatepickerFormat : "DD.MM.YYYY"
    },
    COOKIES: {
      LoginCookie: "LogedIn"
    }
})
