
import {Options, Vue} from "vue-class-component";
import {Prop, Emit, Watch} from "vue-property-decorator";
import {DateRange, FilterType, SelectItemEmit} from "@/libs/ui/.storybook/interfaces.ts";
import CONSTANTS from '@/components/shared/constants';
import {FilterDatePickerData} from "@/libs/features/.storybook/interfaces";
// @ts-ignore: Module '"v-calendar"' has no exported member 'DatePicker'.
import { DatePicker } from "v-calendar";
import {dateFromString} from "@/services/date-utilis";
import {Appstore} from "@/store/main.store";

@Options({
  components: {
    DatePicker
  },
  emits: ['on-change-selected']
})
export default class AppDatepicker extends Vue {

  @Prop({default: []})
  classList: string[] = [];
  @Prop({required: true})
  filterData!: FilterDatePickerData;
  @Prop({default: 'date', required: true})
  type: FilterType = 'date';

  @Emit()
  onChangeSelected (val: SelectItemEmit[]) {}

  @Watch('picked', {deep: true, immediate: true})
  onPickedChange(val: DateRange | null) {
    if(val){
      this.onChangeSelected([{value: val.end, property: "EndDate", type: this.type},{value: val.start, property: "StartDate", type: this.type}]);
      if(document.getElementsByClassName("datepicker-clear-btn")[0]){
        document.getElementsByClassName("datepicker-clear-btn")[0].classList.remove("hidden");
        let datepickerID = document.getElementById("datepicker");
        if(datepickerID){datepickerID.classList.add("date-is-selected");}
      }
    } else {
      this.onChangeSelected([{value: null, property: "EndDate", type: this.type},{value: null, property: "StartDate", type: this.type}]);
    }
  }

  @Watch('filterData', {deep: true, immediate: true})
  onFilterDataGet(filterData: FilterDatePickerData){
    if(filterData && filterData.selectedValueEnd?.value && filterData.selectedValueStart?.value){
      if(document.getElementsByClassName("datepicker-clear-btn")[0]){
          document.getElementsByClassName("datepicker-clear-btn")[0].classList.remove("hidden");
          let datepickerID = document.getElementById("datepicker");
          if(datepickerID){datepickerID.classList.remove("date-is-selected");}

        }
      this.picked = {
        start: filterData.selectedValueStart.value as Date,
        end: filterData.selectedValueEnd.value as Date
      };
    }
  }

  dateFrom: Date = new Date;
  picked: Date | DateRange | null = null;
  masks = {
    input: CONSTANTS.DATES.DatepickerFormat,
  }

  localeDate(inputValue: DateRange): string {
    const dateStartDate: string = typeof inputValue.start === 'string' ? dateFromString(inputValue.start).toLocaleDateString(Appstore.state.LanguageId) : inputValue.start.toLocaleDateString(Appstore.state.LanguageId);
    const dateEndDate: string = typeof inputValue.end === 'string' ? dateFromString(inputValue.end).toLocaleDateString(Appstore.state.LanguageId) : inputValue.end.toLocaleDateString(Appstore.state.LanguageId);

    return dateStartDate + ' - ' + dateEndDate
  }

  returnDateValue = (inputValue: DateRange) => {
    if(inputValue.start && inputValue.end){
      return this.localeDate(inputValue);
    } else {
      if(document.getElementsByClassName("datepicker-clear-btn")[0]){
        document.getElementsByClassName("datepicker-clear-btn")[0].classList.add("hidden");
        let datepickerID = document.getElementById("datepicker");
        if(datepickerID){datepickerID.classList.remove("date-is-selected");}
      }
      if(this.picked && (this.picked as DateRange).start && (this.picked as DateRange).end){
        return  this.localeDate(inputValue);
      }
      return null;
    }
  }

  resetDates = () => {
    this.picked = null;
  }
}

