<template>
  <div
    :moduleInfo="moduleInfo"
  >
    <div
      class="max-w-full mx-auto my-8 mg:my-12"
    >   
      <div class="w-full min-h-250 lg:bg-htz-blue flex flex-wrap items-center justify-center py-4">
        <div v-if="moduleInfo.Module.ModuleProperties.ModuleFacebookLink"> 
          <VirtualLink
            :href="checkLink(moduleInfo.Module.ModuleProperties.ModuleFacebookLink)"
            target="_blank"
          >
            <img
              src="/dist/img/facebook.png"
              class="w-36 md:w-40 lg:w-48 h-auto"
            >
          </VirtualLink>    
        </div>

        <div v-if="moduleInfo.Module.ModuleProperties.ModuleTwitterLink" 
            style="display: flex; align-items: center; justify-content: center; width: 192px; height: 192px;"
        > 
          <VirtualLink
            :href="checkLink(moduleInfo.Module.ModuleProperties.ModuleTwitterLink)"
            target="_blank"
          >
            <img
              src="/dist/img/X_footer-red-ic.svg"
              class="w-36 md:w-40 lg:w-48 h-auto x-icon"
            >
          </VirtualLink>    
        </div>

        <div v-if="moduleInfo.Module.ModuleProperties.ModuleInstagramLink"> 
          <VirtualLink
            :href="checkLink(moduleInfo.Module.ModuleProperties.ModuleInstagramLink)"
            target="_blank"
          >
            <img
              src="/dist/img/instagram.png"
              class="w-36 md:w-40 lg:w-48 h-auto"
            >
          </VirtualLink>    
        </div>

        <div v-if="moduleInfo.Module.ModuleProperties.ModulePinterestLink"> 
          <VirtualLink
            :href="checkLink(moduleInfo.Module.ModuleProperties.ModulePinterestLink)"
            target="_blank"
          >
            <img
              src="/dist/img/pinterest.png"
              class="w-36 md:w-40 lg:w-48 h-auto"
            >
          </VirtualLink>    
        </div>

        <div v-if="moduleInfo.Module.ModuleProperties.ModuleYoutubeLink"> 
          <VirtualLink
            :href="checkLink(moduleInfo.Module.ModuleProperties.ModuleYoutubeLink)"
            target="_blank"
          >
            <img
              src="/dist/img/youtube.png"
              class="w-36 md:w-40 lg:w-48 h-auto"
            >
          </VirtualLink>    
        </div>

        <div v-if="moduleInfo.Module.ModuleProperties.ModuleLinkedinLink"> 
          <VirtualLink
            :href="checkLink(moduleInfo.Module.ModuleProperties.ModuleLinkedinLink)"
            target="_blank"
          >
            <img
              src="/dist/img/linkedin.png"
              class="w-36 md:w-40 lg:w-48 h-auto"
            >
          </VirtualLink>    
        </div>
        <!--
          <div
          v-html="facebook"
        />
        <div v-html="messenger" />
        <div v-html="whatsapp" />
        <div v-html="viber" />
        <div v-html="twitter" />
        <div v-html="linkedin" />
        <div v-html="email" />
        <div v-html="telegram" />
        <div v-html="pinterest" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialIcons",
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
   /*  return {
        facebook: "",
        messenger: "", 
        whatsapp: "", 
        viber: "", 
        twitter: "", 
        linkedin: "", 
        email: "", 
        telegram: "", 
        pinterest: "",
    }; */
  },
  computed: {
    /* getParams(){
        return this.$store.state.Params;
    }, */
  },
  watch:{
    /* getParams() {
      this.getSocialServices()
    } */
  },
  mounted(){

  },
  methods: {
    /* getSocialServices(){
        let socialShareServices = this.$store.state.Params.find(o => o.Key === 'SocialShareServices')?.Value.replace(/\s/g, '');
        var socialShareServicesArray = socialShareServices?.split(',');
        this.facebook = socialShareServicesArray.includes("facebook") ? `<a class="social"><iframe src="https://www.facebook.com/plugins/share_button.php?href=` + encodeURIComponent("https://www.google.com") + `%2Fdocs%2Fplugins%2F&layout=button&size=large&width=40&height=40&appId" width="40" height="40" style="border:none;overflow:hidden; position:absolute; opacity: 1; z-index: 10; top: 16px; opacity: 0;" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay"; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe><img src="/dist/img/facebook.png" class="w-36 md:w-40 lg:w-48 h-auto"/></a>` : "";      
        this.messenger = socialShareServicesArray.includes("messenger") ? `<a class="social" target="_blank" href="https://m.me/croatia.hr"><img src="/dist/img/messenger.png" class="w-36 md:w-40 lg:w-48 h-auto"/></a>` : "";
        this.whatsapp = socialShareServicesArray.includes("whatsapp") ? `<a class="social" target="_blank" href="https://api.whatsapp.com/send?text=%20` + encodeURIComponent(window.location.href) +`"><img src="/dist/img/whatsapp.png" class="w-36 md:w-40 lg:w-48 h-auto"/></a>` : "";
        this.viber = socialShareServicesArray.includes("viber") ? `<a class="social" target="_blank" href="viber://forward?text=&quot;` + encodeURIComponent(window.location.href) + `"><img src="/dist/img/viber.png" class="w-36 md:w-40 lg:w-48 h-auto"/></a>` : "";
        this.twitter = socialShareServicesArray.includes("twitter") ? `<a class="social" target="_blank" href="https://twitter.com/intent/tweet?url=` + window.location.href + `&text=Share%20button'"><img src="/dist/img/twitter.png" class="w-36 md:w-40 lg:w-48 h-auto"/></a>` : "";
        this.linkedin = socialShareServicesArray.includes("linkedin") ? `<a class="social" target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=` + window.location.href + `"><img src="/dist/img/linkedin.png" class="w-36 md:w-40 lg:w-48 h-auto"/></a>` : "";
        this.email = socialShareServicesArray.includes("email") ? `<a class="social" target="_blank" href="mailto:?subject=Google&amp;body=` + encodeURIComponent(window.location.href) + `"><img src="/dist/img/email.png" class="w-36 md:w-40 lg:w-48 h-auto"/></a>` : "";
        this.telegram = socialShareServicesArray.includes("telegram") ? `<a class="social" target="_blank" href="https://telegram.me/share/url?url=` + encodeURIComponent(window.location.href) + `"><img src="/dist/img/telegram.png" class="w-36 md:w-40 lg:w-48 h-auto"/></a>` : "";
        this.pinterest = socialShareServicesArray.includes("pinterest") ? `<a class="social" target="_blank" href="https://pinterest.com/pin/create/button/?url=` + this.getImagetoShare() + `&media=&description=Share%20button"><img src="/dist/img/pinterest.png" class="w-36 md:w-40 lg:w-48 h-auto"/></a>` : "";
    }, */
    checkLink(link){
      if(link.startsWith("https") || link.startsWith("http")){
        return link;
      }
      else if(link.startsWith("www")){
        return "https://" + link;
      }
    }
   
  }
};
</script>

<style lang="scss">

  .x-icon {
    max-width: 54px;
    @screen lg {
      max-width: 72px;
    }
  }
</style>
