<template>
  <div>
    <Listbox
      v-slot="{ open }"
      v-model="selectedItem"
      as="div"
      class="relative"
    >
      <span class="inline-block w-full rounded-md shadow-sm">
        <ListboxButton class="dropdown-title">
          <span class="block truncate">
            {{ selectedItem?.Title }}
          </span>

          <span class="arrow-down absolute right-0 flex items-center pr-2 pointer-events-none" />
        </ListboxButton>
      </span>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="open"
          class="dropdown-list-container"
        >
          <ListboxOptions
            static
            class="dropdown-list"
          >
            <ListboxOption
              v-for="item in dropdownData"
              :key="item.EntityID"
              v-slot="{ selected, active }"
              class="focus:outline-none"
              :value="item"
            >
              <div :class="`dropdown-list-item ${active ?'text-htz-darkgrey' : 'text-htz-steel'}`">
                <span :class="`${selected ? 'font-semibold' : 'font-normal'} block truncate`">
                  {{ item.Title }}
                </span>
              </div>
            </ListboxOption>
          </ListboxOptions>
        </div>
      </transition>
    </Listbox>
  </div>
</template>

<script>
import { ref } from "vue";
import {Listbox,ListboxButton,ListboxOptions,ListboxOption} from "@headlessui/vue";

export default {
  name: "DropdownItem",
  components: {
    Listbox,
    //ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  emits: ['selected'],
  watch: {
    selectedItem(val) {
      if (val) {
        this.$emit('selected', val)
      }
    },
    selectItem(val) {
      this.selectedItem = val
    }
  },
  props: {
    dropdownData: {
        type: Array,
        default: () => []
    },
    selectItem: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      selectedItem: this.selectItem ?? this.dropdownData[0]
    }
  },

};
</script>

<style lang="scss" scoped>
.dropdown-title{
    @apply font-origo border-b-2 border-htz-teal flex items-center justify-between cursor-pointer relative w-full text-left transition ease-in-out duration-150;
    &:focus, &:active{@apply outline-none;}
    span{
        @apply relative font-origo text-48 text-htz-red;
        @screen md{
            @apply text-68;
        }
    }
}
.dropdown-list-container{
    @apply absolute z-10 w-full rounded-md bg-white shadow-lg mt-0;
}
.dropdown-list{
    @apply max-h-500 py-1 shadow-xs overflow-auto;
    &:focus, &:active{@apply outline-none;}
}
.dropdown-list-item{
    @apply text-base font-livory cursor-pointer select-none relative py-3 px-3;
    &:focus, &:active{@apply outline-none;}
    span{
        @apply text-base font-livory;
    }
}
</style>
