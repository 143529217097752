import {ComponentState} from "@/interfaces";


export const state: () => ComponentState = () => ({
  galleryType: 'default',
  errorMessage: '',
  editFilter: {},
  moduleInfo: null,
  moduleData: [],
  filters: [],
  loadMore: 0,
  childData: [],
  hasVideoPoster: false,
  triggerMounted: 0,
  isLoading: true,
  loadMoreLoading: false,
  isError: false,
  pagination: {
    CurrentPage: 1,
    LastPage: 1,
    Limit: 1,
    Offset: 1,
    Total: 1,
  },
  paginationActive: false,
  DatePickerDate: null,
})
