<template>
  <div
    class="title-big content relative z-10 pb-12 lg:pb-16"
    :class="moduleInfo.Module.CssClass"
  >
    <div class="max-w-screen-3xl mx-auto relative">
      <div class="page-title-container-v1">
        <div class="white-block" />
        <div class="max-w-screen-xl mx-auto">
          <div
            class="page-title"
            :class="{'noHeroHeader' :moduleInfo.Module.ModuleProperties.ModuleHasNoHeroHeader === 'True'}"
          >
            <div class="page-title-inner">
              <h1
                v-if="hasPropertyValue('Title',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
                :class="moduleInfo.Module.ModuleProperties.ModuleHasSmallerFont === 'True' ? 'title-2a' : 'title-1'"
              >
                {{ getPropertyValue($t,"Title",moduleInfo.Module.ModuleProperties,firstModuleDataItem) }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="max-w-screen-xl mx-auto">
      <div class="container mx-0 max-w-835 mt-4 md:mt-6">
        <Breadcrumbs
          :module-info="moduleInfo"
        />

        <!-- share i wishlist buttons (kada je odabran page title big) -->
        <div
          v-if="(moduleInfo.Module.ModuleProperties.ModuleHideShareButton !== 'True' && moduleInfo.Module.ModuleProperties.ModuleHideShareButton != undefined) || 
            (moduleInfo.Module.ModuleProperties.ModuleHideWishlistButton !== 'True' && moduleInfo.Module.ModuleProperties.ModuleHideWishlistButton != undefined)"

          class="action-buttons lg:space-x-6"
        >
          <template
            v-if="moduleInfo.Module.ModuleProperties.ModuleHideShareButton !== 'True'"
          >
            <ShareBtn
              v-if="$store.state.Params.find(o => o.Key === 'SocialShareServices')"
              :module-info="moduleInfo"
              :module-class="moduleClass"
              :module-location="'loc-title'"
            />
          </template>
          
          <template v-if="moduleInfo.Module.ModuleProperties.ModuleHideWishlistButton !== 'True'">
            <Favorite
              :entity-type="moduleInfo.Module.Binding.SourceName"
              :entity="firstModuleDataItem"
              :inline="true"
            />
          </template>
        </div>
      </div>
    </div>

    <JsonLdManager
      :entity-id="moduleInfo.Module.Binding.SourceEntityTypeID"
      :entity="firstModuleDataItem"
    />
  </div>
</template>
<script>
import Breadcrumbs from "@/libs/features/lib/Breadcrumbs/Breadcrumbs";
import ShareBtn from "@/components/includes/ShareBtn";
import Favorite from "@/components/includes/Favorite";
import JsonLdManager from "@/components/utils/json-ld-manager";
import {selfDataRequestMixin} from "@/mixins";
import "@/css/modules/_tooltip.scss";


export default {
  name: "TitleBig",
  components: {
    Breadcrumbs,
    ShareBtn,
    Favorite,
    JsonLdManager
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  }
};
</script>
