export const moduleExcludedLoadMore: string[] = [
  'TitleSmall',
  'TableVertical',
  'TableHorizontal',
  'TableSimple',
  'TableSimpleSlider',
  'LayoutsRow',
  'LayoutsCol',
  'FormaPersona',
  'FormaPersonaChild',
  'FormaPersonaHeader',
  'IconsCTA',
  'IconsCTAV2',
  'Instagram',
  'Timeline',
  'Weather',
  'Distance',
  'MapRegion',
  'GalleryText',
  'Gallery',
  'ReviewsSlider',
  'Logos',
  'Image'
]
