import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JsonLd = _resolveComponent("JsonLd")

  return (_ctx.jsonToPass)
    ? (_openBlock(), _createBlock(_component_JsonLd, {
        key: 0,
        json: _ctx.jsonToPass
      }, null, 8, ["json"]))
    : _createCommentVNode("", true)
}