<template>
  <div
    v-for="row in footerList"
    :key="row.index"
    class="footer-menu-main flex flex-col md:flex-row items-top justify-center md:justify-start pt-5 pb-0 md:py-5 md:gap-x-5"
  >
    <div
      v-for="infoFooterItem in row.row"
      :key="infoFooterItem.EntityID"
      class="lg:w-3/12 pb-5"
    >
      <FooterMenuItem
        :module-info="infoFooterItem"
        :module-class="'footer-list-items leading-8'"
      />
    </div>
  </div>
</template>

<script>
import FooterMenuItem from "./FooterMenuItem";

export default {
  components: {
    FooterMenuItem
  },
  props: {
    moduleInfo: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
      return {
          footerList: [],
      }
  },
  watch: {
    moduleInfo(val) {
      this.createFooterListArray();
    }
  },
  mounted() {
      this.createFooterListArray();
  },
  methods: {
      createFooterListArray(){
          if(this.moduleInfo.length > 0){
            this.footerList = []
              const rowsNumber = this.moduleInfo.length / 4;
              for(let i = 0; i <= rowsNumber; i++){
                  const posEndSlice = (i + 1) * 4;
                  const posStartSlice = i * 4 == 0 ? 0 : i * 4;
                  const rowObj = {
                      row: this.moduleInfo.slice(posStartSlice, posEndSlice),
                      index: i
                  };
                  this.footerList.push(rowObj);
              }
          }
      }
  }
};
</script>
