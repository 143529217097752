<template>
  <div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/includes/Footer";
export default {
  name: "PageFooter",
  components: {
    Footer,
  },
  props: {},
  data() {
    return {};
  },
};
</script>
