<template>
    <div
    v-if="loaded"
    class="container max-w-screen-xl my-12"
    :class="moduleInfo.Module.CssClass"
    > 
      <h3
        v-if="hasPropertyValue( 'ModuleTitle', moduleInfo.Module.ModuleProperties, moduleData)"
        class="subtitle-2 mb-6 md:mb-8"
      >
        {{ getPropertyValue($t, 'ModuleTitle', moduleInfo.Module.ModuleProperties, moduleData) }}
      </h3>
      <div class="document-container grid grid-cols-1 md:grid-cols-2">
        <template
            class="flex flex-col"
            v-for="(document, index) of firstModuleDataItemWithoutDefault?.MediaGallery"
            :key="index"
        >
            <div v-if="document" class="flex flex-row items-center pb-8">
                <a v-if="document.meta.MediaExtension === 'pdf'" class="flex flex-row items-center" target="_blank" :href="document.meta.MediaPath">
                    <i class="icon icon-file-ico text-yellow mx-1 text-24 pr-1"/>
                    <div id="mediaExtension" class="media-extension font-livory-bold text-htz-red text-xs uppercase">{{ document.meta.MediaExtension }}</div>
                </a>
                <a v-if="document.meta.MediaExtension !== 'pdf'" class="flex flex-row items-center" target="_blank" :href="document.meta.MediaPath" download>
                    <i class="icon icon-file-ico text-yellow mx-1 text-24 pr-1"/>
                    <div id="mediaExtension" class="media-extension font-livory-bold text-htz-red text-xs uppercase">{{ document.meta.MediaExtension }}</div>
                </a>
                <div class="vertical-line"></div>
                <div class="media-desc font-livory-bold text-htz-darkgrey break-all">
                    <span v-if="!document.meta.MediaAltText">
                        {{$filters.extractDocumentTitle(document.meta.MediaPath)}}
                    </span>
                    <span v-else>
                        {{document.meta.MediaAltText }}
                    </span>
                    <span class="media-size font-livory-bold text-xs pl-2">{{$filters.convertSize(document.meta.MediaSize)}}</span>
                </div>
            </div>
        </template>
      </div>
        <!-- todo dodati svojstvo u mosaic -->
    </div>
</template>
<script>
import {selfDataRequestMixin} from "@/mixins";

export default {
    name: "DocumentsList",
    mixins: [selfDataRequestMixin],
    props: {
        moduleInfo: {
        type: Object,
        default: () => ({}),
        },
    },
    data() {
        return {
            galleryType: 'other'
        };
    },
    methods: {
        responseData(res) {
        this.moduleData = res;
        console.log(this.moduleData)
        },
        // checkExtensionWidth() {
        //     let documentContainer = document.getElementsByClassName('document-container');
        //     for (let j = 0; j < documentContainer.length; j++) {
        //         var extensionCell = documentContainer[j].querySelectorAll('#mediaExtension');
        //         var boxWidth;
        //         var widths = [];
        //         for (let i=0; i < extensionCell.length; i++) {
        //             boxWidth = extensionCell[i].offsetWidth
        //             widths.push(boxWidth)
        //         }
        //         extensionCell.forEach(element => {
        //             element.style.width = Math.max(...widths)+'px'
        //         });
        //     }
        // }
    },
    mounted() {
        // this.checkExtensionWidth()
    }
};
</script>

<style lang="scss">
.document-container {
    .vertical-line {
        height: 23px;  
        margin-left: 17px;
        margin-right: 11px;  
        border-left: 1.2px solid #cecece;
    }
    .media-size {
        color: #cecece;
    }
    .media-desc {
        font-size: 15px;
    }
    .media-extension {
        min-width: 33px;
    }
}
</style>
  