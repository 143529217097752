

import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Entity from './Entity.vue'
import VirtualLink from "@/components/includes/router/VirtualLink.vue";
@Options({
  components: {
    Entity,
    VirtualLink
  }
})
export default class Property extends Vue{
  @Prop({required: false})
  property!: {PropertyID:number,PropertyName:string,RelatedTypeID: number,GroupName :string};

  @Prop({required: false})
  propertyId!: number;

  @Prop({required: false})
  propertyName!: string;

  @Prop({required: false})
  charaterDelimited!: string;

  @Prop({required: false})
  propertyToShow!: string;

  @Prop({required: false})
  classList!: string[];

  @Prop({required: false})
  value!: any;

  @Prop({required: false})
  hasHtml: boolean = false

  @Prop({required: false})
  link: boolean = false

  @Prop({required: false})
  linkProperty?: string = undefined

  //svojstvo od entiteta Place
  @Prop({required: false})
  externalLink: boolean = false

  get propertyIdFromStore(): number {
    return this.$store.state.entityTypes.some(o => o.EntityName === this.propertyName) ? this.$store.state.entityTypes.find(o => o.EntityName === this.propertyName)!.EntityTypeID : 0
  }
  get propertyToUse():{PropertyID:number,PropertyName:string,RelatedTypeID: number,GroupName :string} {
    return {
      PropertyID: (this.property?.PropertyID || 0),
      PropertyName: (this.property?.PropertyName || this.propertyName),
      RelatedTypeID: (this.property?.RelatedTypeID || this.propertyId || this.propertyIdFromStore),
      GroupName: (this.property?.GroupName || ''),
    }
  }

  get isEntityStringHash(): boolean {
    return typeof this.value === "string" && this.value?.split('#')?.every((o: string) => this.isNumeric(o)) && (this.property?.RelatedTypeID !== -1 || !!this.propertyName);
  }
  isNumeric(str: any): boolean {
    if (typeof str !== "string") {
      return false;
    }
    return !isNaN(+str) && !isNaN(parseFloat(str))
  }
}
