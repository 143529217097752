

import {Prop, Watch} from "vue-property-decorator";
import {EntityStore, RelatedInterface, Tag} from "@/interfaces";
import axiosInstance from "@/services/http-common";
import {RxEntityMixin} from "@/mixins/rx-entity.mixin";

export default class Entity extends RxEntityMixin {
  @Prop({required: true})
  property!: {PropertyID:number,PropertyName:string,RelatedTypeID: number,GroupName :string};

  @Prop({required: true})
  entityId!: string;

  @Prop({required: false})
  classList!: string[];

  @Prop({required: false})
  link: boolean = false

  @Prop({required: false})
  showComma: boolean = true

  @Prop({required: false})
  externalLink: boolean = false

  @Prop({required: false})
  propertyToShow!: string;

/*  get href(): string | undefined {
    return this.$store.state.seoLinks.find(o => o.IDObjekta === +this.entityId)?.SefUrl
  }*/

  //result = '';
/*  @Watch('$store.state.entitiesStoreUpdated', {immediate: true})
  onEntityStoreChange(val: {[key: string]: EntityStore<RelatedInterface> }) {
    if (this.$store.state.entitiesStore[this.property?.PropertyName]?.loaded) {
      getById('entities', +this.entityId).then(val => this.result = val)
    }
  }*/
 get result() {
   return this.$store.state.entitiesStore[this.property?.PropertyName]?.entities?.[this.entityId]
 }
  @Watch('property.RelatedTypeID', {immediate: true})
  onRelatedTypeIDChange(val?: number) {
    if(val) {
      this.initData()
    }
  }
  initData() {
    if (this.needLoadData(this.property, this.propertyByEntity)) {
      this.loadAll(this.property, this.propertyByEntity + (this.externalLink ? ',ExternalLink' : ''), this.propertyByEntity)
    }
  }
  get propertyByEntity(): string {
    return ((this.propertyToShow || 'Title') )
  }
}
