<template>
  <div v-if="selectedItem">
    <Listbox
      v-slot="{ open }"
      v-model="selectedItem"
      as="div"
      class=""
    >
      <VirtualLink
        v-if="selectedItem.name"
        id="btnSelectedRegionLink"
        :href="selectedItem.link"
        :close-menu-on-click="true"
        class-list="btn-small absolute z-10 top-0 mt-4 border-2 border-htz-red bg-white text-htz-darkgrey text-14 font-livory-bold uppercase p-1 pl-2 pr-5"
      >
        {{ selectedItem.name }}
      </VirtualLink>

      <span class="inline-block w-full rounded-md shadow-sm">
        <ListboxButton class="btn-red-down w-full">
          <span
            v-if="selectedItem.name"
            class="block truncate"
          >
            {{ selectedItem.name }}
          </span>
          <span
            v-else
            class="block truncate"
          >
            {{ $t("loc_map_region_choose") }}
          </span>

          <!--  <span class="arrow-down absolute right-0 flex items-center pr-2 pointer-events-none"></span> -->
        </ListboxButton>
      </span>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="open"
          class="select-list-container"
        >
          <ListboxOptions
            static
            class="select-list"
          >
            <ListboxOption
              v-for="item in selectList"
              :key="item"
              v-slot="{ selected, active }"
              class="focus:outline-none"
              :value="item"
            >
              <div :class="`select-list-item ${active ?'text-htz-darkgrey' : 'text-htz-steel'}`">
                <!-- ako je mapa unutar introTextImg modula, omogući link na promjenu u dropdownu, inače ne -->
                <VirtualLink
                  v-if="mapPosition === 'IntroTextImg'"
                  :href="item.link"
                  :data-region="item.mapName"
                  :close-menu-on-click="false"
                  :class="`${selected ? 'font-semibold' : 'font-normal'} block truncate`"
                  @click="selectedRegion"
                >
                  {{ item.name }}
                </VirtualLink>
                <a
                  v-else
                  :data-region="item.mapName"
                  :class="`${selected ? 'font-semibold' : 'font-normal'} block truncate`"
                  @click="selectedRegion"
                >
                  {{ item.name }}
                </a>
              </div>
            </ListboxOption>
          </ListboxOptions>
        </div>
      </transition>
    </Listbox>
  </div>
</template>

<script>
import { ref } from "vue";
import {Listbox,ListboxButton,ListboxOptions,ListboxOption} from "@headlessui/vue";

export default {
  name: "SelectItem",
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption
  },
  props: {
    selectData: {
      type: Object,
      default: () => ({})
    },
    mapPosition: {
      type: String,
      default: ""
    },
    selectedPropRegion: {
      type: String,
      default: ''
    }
  },
  setup(props) {

    let selectList = [];
    let selectedItem;
    let obj;
    for (const value of props.selectData) {
      obj = Object.assign({}, {name: value.Title, link: value.SefUrl, mapName: "region-"+value.NameMap });
      //console.log(obj)
      selectList.push(obj);
    }
    //add one empty object to array (it holds an expression $t("loc_map_region_choose"))
    /* obj = Object.assign({}, {name: "", link: "#", mapName: "" });
    selectList.unshift(obj); */

    if(props.mapPosition === "IntroTextImg"){
      let elem = props.selectData.find(element => element.EntityID.toString() ===  props.selectedPropRegion);
      let selectedObj = Object.assign({}, {name: elem.Title, link: elem.SefUrl, mapName: "region-"+elem.NameMap });
      selectedItem = ref(selectedObj);
      //selectedItem = ref(selectList[0]);
    }
    else{
      selectedItem = ref(selectList[0].name).value;
    }

    return {
      selectedItem,
      selectList,
    };
  },
  methods: {
    selectedRegion(e) {
      var that = e.target;
      if(this.mapPosition !== "IntroTextImg"){
        e.preventDefault();
      }
      const paths = that.closest(".maps-region").querySelector("#regionsMap").children; //get all paths
      for (const path of paths) { 
        //for map in navigation
        if(document.body.classList.contains('body-lock-scroll')){
          path.querySelector("path").classList.remove("active");
        }
        //for other maps
        else{ path.classList.remove("active");}
         
      } //remove active for each path
      that.closest(".maps-region").querySelector("path[data-region='"+e.target.dataset.region+"']").classList.add("active"); //remove active to selected path
      let btnClickedRegionLink = that.closest(".maps-region").querySelector("#btnClickedRegionLink");
      let btnSelectedRegionLink = that.closest(".maps-region").querySelector("#btnSelectedRegionLink");
      if(btnSelectedRegionLink) {btnSelectedRegionLink.classList.remove("hidden");}
      if(btnClickedRegionLink) {btnClickedRegionLink.classList.add("hidden");}
    }
  }
};
</script>

<style lang="scss" scoped>
.select-list-container{
    @apply relative z-10 w-full rounded-md bg-white shadow-lg mt-0 top-0;
}
.select-list{
    @apply max-h-500 py-1 shadow-xs overflow-auto;
    &:focus, &:active{@apply outline-none;}
    li:first-child{@apply block;}
}
.select-list-item{
    @apply text-base font-livory cursor-pointer select-none relative;
    &:focus, &:active{@apply outline-none;}
    a{ @apply text-base font-livory py-3 px-3; }
}
.btn-small:after{right: 8px;}
</style>
