<template :class="moduleInfo.Module.CssClasss">
  <div
    class="title-small content relative z-10 lg:pb-16"
    :class="{'lg:pb-32' : moduleInfo.Module.ModuleProperties.ModuleHideShareButton !== 'True' || moduleInfo.Module.ModuleProperties.ModuleHideWishlistButton !== 'True'}"
  >
    <div
      v-if="loaded && firstModuleDataItem !== null"
      class="container max-w-screen-md lg:max-w-screen-2xl relative"
      :class="{'noHeroHeader' :moduleInfo.Module.ModuleProperties.ModuleHasNoHeroHeader === 'True'}"
    >
      <div
        class="page-title-container-v2"
        :class="{
          'mobile-absolute':
            moduleInfo.Module.ModuleProperties
              .ModuleHasTitleAboveOrUnderHeader === 'aboveHeader',
        }"
      >
        <div
          class="page-title"
        >
          <span
            v-if="moduleInfo.Module.ModuleProperties.ModuleHasRating === 'True'"
            class="stars flex"
          >
            <template
              v-if="hasPropertyValue(
                'eVisitor_Subcategory',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              ) && hasPropertyValue(
                'eVisitor_MarketingLabel',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              ) && eVisitorTypes.includes(
                getPropertyValue($t,
                                 'eVisitor_MarketingLabel',
                                 moduleInfo.Module.ModuleProperties,
                                 firstModuleDataItem
                )?.[0].EntityID?.toString())
                &&
                (getPropertyValue($t,
                                  'eVisitor_Subcategory',
                                  moduleInfo.Module.ModuleProperties,
                                  firstModuleDataItem
                )?.[0].EntityID === eVisitorOPGCategoryID)"
            >
              <i
                v-for="index in getRating()"
                :key="index"
                class="icon icon-sun-ico text-yellow text-20 mx-1 md:text-24"
              />
            </template>
            <!--ikone zvjezdica -->
            <template v-else>
              <i
                v-for="index in getRating()"
                :key="index"
                class="icon icon-stars-ico text-yellow text-32 w-6"
              />
            </template>
          </span>
          <h1
            v-if="
              hasPropertyValue(
                'Title',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
            class="title-2a pb-2"
          >
            {{
              getPropertyValue($t,
                               "Title",
                               moduleInfo.Module.ModuleProperties,
                               firstModuleDataItem
              )
            }}
          </h1>
          <!-- start - end dates -->
          <div v-if="moduleInfo.Module.ModuleProperties.ModuleHasDates === 'True'">
            <div
              v-if="hasPropertyValue('StartDate', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              class="title-dates pb-3"
            >
              <AppDate :date="getPropertyValue($t, 'StartDate', moduleProperties, moduleData)" />

              <span v-if="hasPropertyValue('EndDate', moduleInfo.Module.ModuleProperties, firstModuleDataItem)">
                {{" " + $t("loc_to") }}
                <AppDate :date="getPropertyValue($t, 'EndDate', moduleProperties, moduleData)" />
              </span>
            </div>
          </div>
          <!-- link -->
          <div
            v-if="
              moduleInfo.Module.ModuleProperties.ModuleHasHyperlink === 'True' && hasPropertyValue('Link', moduleInfo.Module.ModuleProperties, firstModuleDataItem)
            "
            class="title-link space-x-4 pb-1 md:pb-2"
          >
            <i class="icon icon-globe-ico text-32" />
            <VirtualLink
              :href="getPropertyValue($t, 'Link', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              class-list="title-link space-x-4 pb-1 md:pb-2"
            >
              <span class="link">
                {{ getPropertyValue($t, 'Link', moduleInfo.Module.ModuleProperties, firstModuleDataItem) }}
              </span>
            </VirtualLink>
          </div>

          <!-- lokacija -->
          <div v-if="moduleInfo.Module.ModuleProperties.ModuleHasLocation === 'True'">
            <div class="title-link pb-2">
              <i class="icon icon-pin-ico text-32 mr-2 md:mr-4" />
              <span class="flex text-htz-steel">
                <Property
                  :value="firstModuleDataItem?.Place"
                  :property-name="'Place'"
                  :external-link="true"
                  :link="true"
                  :link-property="'ExternalLink'"
                  class="text-htz-steel font-livory-bold"
                  :class-list="['text-htz-steel']"
                >
                  {{ getPropertyValue($t, Place, firstModuleDataItem) }}
                </Property>
                <!-- <span class="mr-1 text-htz-steel">, </span> -->
              </span>
              <span
                v-if="hasPropertyValue('Address', moduleProperties, firstModuleDataItem)"
                class="title-poi"
              ><span
                v-if="firstModuleDataItem.Place?.length > 0"
                class="comma mr-1 text-htz-steel"
              >,</span>{{ getPropertyValue($t, 'Address', moduleProperties, firstModuleDataItem) }}
              </span>
            </div>
          </div>
          <!-- TODO: hendlati ikonice -->
          <!-- <div
            v-if="moduleInfo.Module.ModuleProperties.ModuleHasIcons === 'True'"
            class="title-icons flex mb-2 space-x-2"
          >
            <i class="icon icon-camera-ico text-htz-darkgrey text-32 w-6" />
            <i class="icon icon-camera-ico text-htz-darkgrey text-32 w-6" />
            <i class="icon icon-camera-ico text-htz-darkgrey text-32 w-6" />
          </div> -->
          <div
            v-if="
              hasPropertyValue(
                'Subtitle',
                moduleInfo.Module.ModuleProperties,
                firstModuleDataItem
              )
            "
            class="p-sm text-htz-darkgrey pb-3"
            v-html="
              getPropertyValue($t,
                               'Subtitle',
                               moduleInfo.Module.ModuleProperties,
                               firstModuleDataItem
              )
            "
          />

          <Breadcrumbs
            :module-info="moduleInfo"
          />
        </div>
        <!--TODO: zamjeniti share + wishlist sa componentom (FooterActionButtons.vue - preimenovati i includeati di treba)-->
        <div
          v-if="moduleInfo.Module.ModuleProperties.ModuleHideShareButton !== 'True' || moduleInfo.Module.ModuleProperties.ModuleHideWishlistButton !== 'True'"
          class="action-buttons lg:space-x-6"
        >
          <template v-if="moduleInfo.Module.ModuleProperties.ModuleHideShareButton !== 'True'">
            <ShareBtn
              v-if="$store.state.Params.find(o => o.Key === 'SocialShareServices')"
              :module-info="moduleInfo"
              :module-class="moduleClass"
              :module-location="'loc-title'"
            />
          </template>
          <template v-if="moduleInfo.Module.ModuleProperties.ModuleHideWishlistButton !== 'True'">
            <Favorite
              :entity-type="moduleInfo.Module.Binding.SourceName"
              :entity="firstModuleDataItem"
              :inline="true"
            />
          </template>
        </div>
      </div>
      <JsonLdManager
        :entity-id="moduleInfo.Module.Binding.SourceEntityTypeID"
        :entity="firstModuleDataItem"
      />
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/libs/features/lib/Breadcrumbs/Breadcrumbs";
import {selfDataRequestMixin, tableMixin} from "@/mixins";
import Property from "@/components/includes/entity/Property";
import ShareBtn from "@/components/includes/ShareBtn";
import JsonLdManager from "@/components/utils/json-ld-manager";
import Favorite from "@/components/includes/Favorite";
import AppDate from "@/components/includes/Date";

export default {
  name: "TitleSmall",
  components: {
    Favorite,
    Breadcrumbs,
    Property,
    ShareBtn,
    JsonLdManager,
    AppDate
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      eVisitorTypes: ["1468888", "1468920", "1468882", "1468880"], //Apartmani: 1468888 //Sobe: 1468920 //Kuće za odmor: 1468882 //Kampovi: 1468880
      eVisitorOPGCategoryID: 1345022, //Objekti na OPG-u
    }
  },
  methods: {
    getRating(){
      if(this.firstModuleDataItem?.Rating > 0){
        return parseInt(this.firstModuleDataItem.Rating);
      }
      else if(this.firstModuleDataItem?.FacilityStars > 0){
        return parseInt(this.firstModuleDataItem.FacilityStars);
      }
      else return 0;
    }
  }
};
</script>
