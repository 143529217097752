import {AppState, RelatedInterface} from "@/interfaces";
import {ActionContext} from "vuex";
import axiosInstance from "@/services/http-common";

export const regionMutations = {
  setEmptyRegionStore(state: AppState) {
    state.entitiesStore['Region'] = {
      loaded: false,
      loading: true,
      timeLoaded: new Date(),
      propertyNames: ['all'],
      entities: {},
      ids: []
    }
  },
  setRegionDataInStore(state: AppState, data: RelatedInterface[]) {
    state.entitiesStore['Region'] = {
      loaded: true,
      loading: false,
      timeLoaded: new Date(),
      propertyNames: ['all'],
      entities: {...data.reduce((o, n) => ({...o,[n.EntityID]: n}), {})},
      ids: [...data.map(o => o.EntityID)]
    }
  }
}
export const regionActions = {
  setEmptyRegionStore(store: ActionContext<AppState, AppState>) {
    if (!store.state.entitiesStore['Region']) {
      store.commit('setEmptyRegionStore')
    }
  },
  initRegionStore(store:  ActionContext<AppState, AppState>) {
    store.dispatch('getRegions').catch((e) => console.error(e));
  },
  makeRegionRequest(store:  ActionContext<AppState, AppState>) {
    axiosInstance.post(
      '/api/v1/Region',
      {
        "Fields": [
          "SefUrl", "Title", "NameMap"
        ],
      }
    )
      .then(res => store.dispatch('setRegionDataInStore', res.data.Data).catch((e) => console.error(e)))
      .catch((e) => console.error(e));
  },
  setRegionDataInStore(store:  ActionContext<AppState, AppState>, data: RelatedInterface[]) {
    if(data.length) {
      store.commit('setRegionDataInStore', data)
    }
  },
  getRegions(store:  ActionContext<AppState, AppState>) {
    store.dispatch('setEmptyRegionStore').catch((e) => console.error(e));
    store.dispatch('makeRegionRequest')

  }

}
