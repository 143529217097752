import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container max-w-screen-xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.renderLoadMore)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("button", {
          class: "btn-red-border-down block mx-auto",
          role: "button",
          "data-testid": "loadMoreBtn",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.loadMore()), ["prevent"]))
        }, _toDisplayString(_ctx.$t("loc_loadmore")), 1)
      ]))
    : _createCommentVNode("", true)
}